import { Modal, Box, Button, Typography, IconButton } from "@material-ui/core";
import { InputGroup } from "components";
import { useStyles } from "./styles";
import React from "react";

interface IProps {
  open: boolean;
  handleClose: () => void;
  fields: any;
  handleCreate: () => void;
  title: string;
  isLoading?: boolean;
}

const PopupForm = ({
  open,
  handleClose,
  fields,
  handleCreate,
  isLoading,
  title,
}: IProps) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modal}
    >
      <Box className={classes.main}>
        <Box className={classes.header}>
          <Typography className={classes.title}>{title}</Typography>
          <IconButton className={classes.iconButton} onClick={handleClose}>
            <img src="/images/buttonIcons/close-button.png" alt="" />
          </IconButton>
        </Box>
        <hr className={classes.hr} />
        <InputGroup className={classes.textField} fields={fields} />
        <Box className={classes.buttonsWrapper}>
          <Button className={classes.cancel} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            className={classes.confirm}
            onClick={handleCreate}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PopupForm;
