export const TYPES = {
  UPDATE: "PREFILL_UPDATE",
} as const;

export type PreFillProps = {
  key: string;
  value: any;
}

export function update(props: PreFillProps) {
  return {
    type: TYPES.UPDATE,
    props
  }
}