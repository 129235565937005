import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 30,
    backgroundColor: "#fff",
    [theme.breakpoints.down("xs")]: {
      padding: 16
    },
  },
  title: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "29px",
    color: "#333333",
    textAlign: "center"
  },
  layoutSection: {

  },
  layoutElement: {
    width: '100%',
    padding: '10px 0',
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: '4px'
  },
  layoutTitle: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: "12px",
    lineHeight: "24px",
    padding: 10,
    color: "#A5A9B9"
  },
  layoutImage: {
    maxWidth: '100%',
    height: 'auto'
  }
}));