import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "store";
import { DataDraftKeys } from "store/draftdata/actions";
import { FilterKeys } from "store/filter/actions";
import { OptionsStoreKeys } from "store/optionsstore/actions";
import { RootState } from "store/reducers";
import { useStyles } from "./styles";
import { useFilter } from "utils/filter";

interface IProps {
  //label key of select option
  labelKey: string;
  //key of options in optionsStore
  optionsStoreKey: OptionsStoreKeys;
  //to find options to show by key: id, name, ... (unique value)
  findOptionByKey: string;
  //value to compare and exactly options you want
  findOptionByKeyValue: any;
  //draft key to update data
  draftKey: DataDraftKeys;
  hardOptions?: any[];
  optionsFilterKey?: FilterKeys;
  searchBy: string;
  propKey: string;
  loading?: boolean;
}

const MultipleSelect = ({
  labelKey,
  optionsStoreKey,
  findOptionByKey,
  findOptionByKeyValue,
  draftKey,
  hardOptions,
  optionsFilterKey,
  searchBy,
  propKey,
  loading,
}: IProps) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useFilter(optionsFilterKey ?? "default");
  const storeOptions =
    useSelector(
      (state: RootState) => state.optionsStoreReducer[optionsStoreKey]
    )?.filter((item: any) => item[findOptionByKey] === findOptionByKeyValue)[0]
      ?.items ?? [];
  const options = hardOptions ?? storeOptions;
  const drafts = useSelector(
    (state: RootState) => state.dataDraftReducer[draftKey]
  );
  const classes = useStyles();
  const handleOnChangeOption = (value: any) => {
    const newDrafts = drafts.map((item: any) => {
      if (item[findOptionByKey] === findOptionByKeyValue) {
        return {
          ...item,
          [propKey]: value,
        };
      }
      return item;
    });
    dispatch(Actions.DataDraftList.update({ key: draftKey, value: newDrafts }));
  };

  return (
    <Autocomplete
      className={classes.toMultipleSelect}
      multiple
      getOptionLabel={(option: any) => `${option[labelKey] ?? ""}`}
      options={options}
      loading={loading}
      onChange={(e, value) => {
        handleOnChangeOption(value);
      }}
      onFocus={() => {
        if (options?.length === 0 || !options) {
          setFilter({
            ...filter,
            [searchBy]: undefined,
            [findOptionByKey]: findOptionByKeyValue,
          });
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

export default MultipleSelect;
