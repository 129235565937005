import { Button, Grid, List, makeStyles, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { DesktopImage, MobileImage, SquareImage, TextForm } from "./components";

const Carousel = (props) => {
  const classes = useStyles();
  const {
    elementName,
    elementDetail,
    values,
    handleDeleteField,
    setElementDetail,
    handleSaveImage,
    errors,
    setErrors,
    setDirtys,
    dirtys,
    errorSubmit,
    setErrorSubmit,
    initialValue,
    setInitialValue,
    formStructure,
    desktopImage,
    mobileImage,
    squareImage
  } = props;
  const getName = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1, name.length - 1);
  };
  const showContent = useCallback(
    (elementName, elementDetail) => {
      return elementName.map((name, index) => {
        return (
          name !== "" && (
            <Grid key={index} container className={classes.root}>
              <Grid className={classes.titleBox} item xs={2}>
                <Typography variant="h6">
                  {getName(name)}&nbsp;{index + 1}
                </Typography>
              </Grid>
              <Grid className={classes.primarySection} item xs={8}>
                <Grid container className={classes.ImageUpload}>
                  {desktopImage && (
                    <DesktopImage
                      name={name}
                      imgFile={elementDetail[name]}
                      handleSaveImage={handleSaveImage}
                      values={values}
                    />
                  )}
                  {mobileImage && (
                    <MobileImage
                      name={name}
                      imgFile={elementDetail[name]}
                      handleSaveImage={handleSaveImage}
                      values={values}
                    />
                  )}
                  {squareImage && (
                    <SquareImage
                      name={name}
                      imgFile={elementDetail[name]}
                      handleSaveImage={handleSaveImage}
                      values={values}
                    />
                  )}
                </Grid>
                <Grid container className={classes.textField}>
                  <TextForm
                    name={name}
                    setCommonDirtys={setDirtys}
                    elementDetail={elementDetail}
                    commonErrors={errors}
                    commonDirtys={dirtys}
                    setCommonErrors={setErrors}
                    setElementDetail={setElementDetail}
                    errorSubmit={errorSubmit}
                    setErrorSubmit={setErrorSubmit}
                    initialValue={initialValue}
                    setInitialValue={setInitialValue}
                    formStructure={formStructure}
                  />
                </Grid>
              </Grid>
              <Button
                className={classes.deleteButton}
                onClick={() => handleDeleteField(name)}
              >
                <CloseIcon />
              </Button>
            </Grid>
          )
        );
      });
    },
    [classes, elementName, elementDetail, errors, dirtys, errorSubmit] // eslint-disable-line
  );
  return (
    <React.Fragment>
      <List className={classes.listBox} subheader={<li />}>
        {showContent(elementName, elementDetail)}
      </List>
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "16px",
    backgroundColor: "#c5c8cb29",
    borderRadius: "4px",
    width: "auto",
    boxShadow: "3px 3px 11px #7c79799c",
    position: "relative",
    paddingRight: 16,
  },
  listBox: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    marginTop: 8,
    maxHeight: "63vh",
    "&::-webkit-scrollbar": {
      width: "7px",
      backgroundColor: "#ffffff",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#a5a5a5",
      borderRadius: "10px",
      "-webkit-box-shadow": "inset 0px 7px 6px rgb(0 0 0 / 30%)",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0px 0px 6px rgb(0 0 0 / 30%)",
      backgroundColor: "#F5F5F5",
    },
  },
  titleBox: {
    textAlign: "center",
    padding: "16px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  primarySection: {},
  ImageUpload: {
    marginTop: 16,
  },
  textField: {
    margin: 16,
    borderRadius: "4px",
    width: "auto",
  },
  deleteButton: {
    right: -4,
    top: 0,
    position: "absolute",
    minWidth: "auto",
  },
}));
export default Carousel;
