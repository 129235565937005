import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: 500,
    padding: 20,
    border: 'none',
    borderRadius: 8
  }
}))