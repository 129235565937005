import { makeStyles, Box } from "@material-ui/core";
import clsx from "clsx";
import { ErrorMessage, LoadButton, PopUpDialog, InputGroup } from "components";
import { useSnackbar } from "notistack";
import React, { Fragment } from "react";
import useApi from "utils/api/useApi";
import { DialogStyles, DropzoneStyles } from "utils/constants/styles";
import useFormHandler from "utils/forms/useFormHandler";
import { useAsyncTask } from "utils/tools";
import { formStructure, messages } from "./createReviewConfig";

const useStyles = makeStyles((theme) => ({
  root: {},
  addContent: {
    maxHeight: 480,
  },
  ...DialogStyles,
  ...DropzoneStyles,
  back: {
    backgroundColor: "#F4F6F8",
  },
  textField: {
    margin: "2px 0px",
    width: "100%",
  },
  submitButton: {
    width: "100%",
    marginTop: 16,
  },
  accordion: {
    "&.MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "&.MuiAccordion-root:before": {
      height: 0,
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "8px 0",
    },
  },
  sumRoot: {
    "&.Mui-expanded": {
      minHeight: 52,
    },
  },
  summary: {
    flexGrow: 0,
    margin: "16px 0",
    "&.Mui-expanded": {
      margin: "10px 0px",
    },
  },
  iconBtn: {
    padding: "14px 12px",
    "&.Mui-expanded": {
      padding: 12,
    },
  },
  details: {
    padding: "8px 0px 16px",
  },
  productImage: {
    width: 75,
  },
}));

const CreateReview = (props) => {
  const { open, handleClose } = props;
  const api = useApi();
  const classes = useStyles();
  const [runCreate, load, error, setError] = useAsyncTask("product-create");
  const [fields, values, errors, setValues, setErrors, setDirtys] = // eslint-disable-line
    useFormHandler({
      product_id: {
        initialValue: "",
        placeholder: "Product Review",
        type: "autocomplete",
        label: "Product Review",
        pathname: "product/list",
        filterName: "product",
        validate: {
          presence: { allowEmpty: false, message: "is required" },
        },
        getOptionLabel: (option) => {
          return option.name || "";
        },
        getOptionSelected: (option, value) => {
          return option.name === value.name;
        },
        onChange: (event, value, reason, field) => {
          if (reason === "select-option") {
            field.setValue(value.id);
            setDirtys((prevDirtys) => ({
              ...prevDirtys,
              [field.key]: true,
            }));
          }
        },
        renderOption: (option, state) => {
          return (
            <Box display="flex">
              <Box mr={2}>
                <img
                  className={classes.productImage}
                  src={option.PreviewImage.uri}
                  alt={option.PreviewImage.filename}
                />
              </Box>
              <Box>{option.name}</Box>
            </Box>
          );
        },
      },
      account_id: {
        initialValue: "",
        placeholder: "User Name",
        type: "autocomplete",
        label: "User Name",
        pathname: "account/list",
        filterName: "customers",
        validate: {
          presence: { allowEmpty: false, message: "is required" },
        },
        getOptionLabel: (option) => {
          return option?.firstname || option?.lastname
            ? option?.firstname + " " + (option?.lastname || "")
            : "";
        },
        getOptionSelected: (option, value) => {
          return (
            option.firstname + " " + (option?.lastname || "") ===
            value.firstname + " " + (value?.lastname || "")
          );
        },
        onChange: (event, value, reason, field) => {
          if (reason === "select-option") {
            field.setValue(value.id);
            setDirtys((prevDirtys) => ({
              ...prevDirtys,
              [field.key]: true,
            }));
          }
        },
        renderOption: (option, state) => {
          return (
            <Box display="flex">
              <Box>{option.firstname + " " + (option?.lastname || "")}</Box>
            </Box>
          );
        },
      },
      ...formStructure,
    }); // eslint-disable-line
  const { enqueueSnackbar } = useSnackbar();

  const handleCreate = (ev) => {
    ev.preventDefault();
    if (errors) {
      setDirtys(errors);
      enqueueSnackbar("Please fill in all required fields!", {
        variant: "error",
      });
    } else {
      runCreate(async () => {
        try {
          const response = await api
            .path("review/create")
            .post({ body: values });
          if (response?.data?.status === 0) {
            handleClose();
            enqueueSnackbar("Review has been created successfully!", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(response?.data?.error?.message, {
              variant: "error",
            });
          }
        } catch (error) {
          enqueueSnackbar(error?.message || error, {
            variant: "error",
          });
        }
      });
    }
  };

  return (
    <Fragment>
      <PopUpDialog
        title={<div className={classes.titleText}>Create Review</div>}
        content={
          <Fragment>
            <form onSubmit={handleCreate}>
              <ErrorMessage message={error?.message} map={messages} />
              <InputGroup className={classes.textField} fields={fields} />
              <LoadButton
                variant="contained"
                padding={4}
                load={load}
                size="large"
                disabled={load}
                className={classes.submitButton}
                type="submit"
                color="primary"
              >
                Create Review
              </LoadButton>
            </form>
          </Fragment>
        }
        open={open}
        handleClose={() => {
          handleClose();
          setError(null);
          setErrors(null);
          setDirtys({});
        }}
        titleClass={classes.title}
        contentClass={clsx(classes.content, classes.addContent)}
        className={classes.root}
      />
    </Fragment>
  );
};

export default CreateReview;
