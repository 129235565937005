import { Box, makeStyles } from "@material-ui/core";
import Loader from "components/Loader/Loader";
import React from "react";

interface Props {
  children: React.ReactNode;
  loading?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  loadingScreen: {
    position: "absolute",
    zIndex: 10000000,
    width: "100%",
    height: "100%",
    top: "0",
    display: "flex",
    backgroundColor: "#ffffffc7",
    alignItems: "center",
  },
}));

const LoadingScreenLayout: React.FC<Props> = ({
  children,
  loading = false,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {children}
      {loading && (
        <Box className={classes.loadingScreen}>
          <Loader loading={true} />
        </Box>
      )}
    </Box>
  );
};

export default LoadingScreenLayout;
