export const TYPES = {
  UPDATE_OLD_ORDER: "UPDATE_OLD_ORDER",
  UPDATE_OLD_ORDER_LEFT:"UPDATE_OLD_ORDER_LEFT",
  UPDATE_NEW_ORDER: "UPDATE_NEW_ORDER",
  UPDATE_QUANTITY: "UPDATE_QUANTITY",
  CLEAR_QUANTITY:"CLEAR_QUANTITY",
  CLEAR_ITEM_SELECTED: "CLEAR_ITEM_SELECTED",
  UPDATE_RELOAD: "UPDATE_RELOAD",
  OLD_ITEM_SELECTED: "OLD_ITEM_SELECTED",
  OLD_ITEM_SELECTED_LEFT: "OLD_ITEM_SELECTED_LEFT",
  NEW_ITEM_SELECTED: "NEW_ITEM_SELECTED",
  CLEAR_NEW_ITEM_SELECTED:"CLEAR_NEW_ITEM_SELECTED",
  CLEAR_ITEM_EXCHANGE: "CLEAR_ITEM_EXCHANGE"
} as const;


export function updateOldOrder(props: any) {
  return {
    type: TYPES.UPDATE_OLD_ORDER,
    props,
  };
}

export function updateNewOrder(props: any) {
  return {
    type: TYPES.UPDATE_NEW_ORDER,
    props,
  };
}

export function updateOldItemLeft(props: any) {
  return {
    type: TYPES.UPDATE_OLD_ORDER_LEFT,
    props,
  };
}

export function updateQuantity(props: any) {
  return {
    type: TYPES.UPDATE_QUANTITY,
    props,
  };
}
export function clearQuantity() {
  return {
    type: TYPES.CLEAR_QUANTITY,
  };
}

export function updateOldItemSelected(props: any){
  return {
    type: TYPES.OLD_ITEM_SELECTED,
    props
  }
}
export function updateOldItemSelectedLeft(props: any){
  return {
    type: TYPES.OLD_ITEM_SELECTED_LEFT,
    props
  }
}

export function updateNewItemSelected(props: any){
  return {
    type: TYPES.NEW_ITEM_SELECTED,
    props
  }
}

export function clearNewItemSelected(props: any){
  return {
    type: TYPES.CLEAR_NEW_ITEM_SELECTED,
    props
  }
}

export function clearItemExchange(){
  return {
    type: TYPES.CLEAR_ITEM_EXCHANGE,
  }
}