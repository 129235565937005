import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";

interface Props {
  reload?: Function;
  handleClose?: () => void;
}

export const usePostCreateAsset = ({ reload, handleClose }: Props) => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const postCreateAsset = async (formData: any) => {
    try {
      const res = await api.path("create/asset").multipost({ body: formData });
      if (res?.data?.status === 0) {
        enqueueSnackbar("Image has been successfully uploaded!", {
          variant: "success",
        });
        reload && reload();
        handleClose && handleClose();
      } else {
        enqueueSnackbar("Upload Image failed!", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("Upload Image failed!", {
        variant: "error",
      });
    }
  };

  const { data, isLoading, error, mutate } = useMutation((formData: any) => postCreateAsset(formData));

  return {
    postCreateAssetData: data,
    postCreateAssetError: error,
    postCreateAssetLoading: isLoading,
    postCreateAssetMutate: mutate,
  };
};
