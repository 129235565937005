import { useEffect, useState, useRef } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "store";
import { DataListKeys } from "store/datalist/actions";
import { RootState } from "store/reducers";
import useApi from "utils/api/useApi";
import { useFilter } from "utils/filter";

//if want to get exact id detail, pass id into useGetListing hook
export const useGetListing = (storeListKey: DataListKeys ,id?: number) => {
  const firstFetch = useRef(true);
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state?.dataListReducer[storeListKey]);
  const [filter] = useFilter("listing");
  const [loading, setLoading] = useState(false);
  const api = useApi();
  const getListing = async () => {
    setLoading(true);
    const res = await api
      .path("listing", {},{...filter, ...(id ? { id, offset: 0 } : {})}) //only when you pass id into useGetListing hook then the response only contain exact id detail
      .get();
      setLoading(false)
    if(res?.data?.status === 0) {
      if (filter.offset === 0) {
        dispatch(Actions.DataList.update({key: storeListKey, value: {
          items: res?.data?.data?.data,
          hasMore: res?.data?.data?.data.length < res?.data?.data?.total
        }}))
      } else {
        dispatch(Actions.DataList.update({key: storeListKey, value: {
          items: [...data.items, ...res.data.data.data],
          hasMore: res?.data?.data?.data.length + data.items.length < res?.data?.data?.total
        }}))
      }
    }
  };

  const { error, mutate } = useMutation(() => getListing());

  useEffect(() => {
    if (data.items.length > 0 && firstFetch.current) {
      firstFetch.current = false;
      return;
    }
    firstFetch.current = false;
    getListing();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, id]);

  const resetListing = () => {
    dispatch(Actions.DataList.update({key: storeListKey, value: {
      items: [],
      hasMore: false
    }}))
  }

  return {
    getListingData: data,
    getListingError: error,
    getListingLoading: loading,
    getListingMutate: mutate,
    resetData: resetListing,
  };
};
