import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { HTTP, PathParams } from "./http";
import Endpoints from "./endpoints";
import { useRouter } from "utils/tools";
import { Actions } from "store";

const API_HOST = process.env.API_HOST || process.env.REACT_APP_API_HOST;
export const VM_AI_URI = process.env.REACT_APP_VM_AI_URI;
export const VM_AI_API_KEY = process.env.REACT_APP_VM_AI_API_KEY;

export type RequestResult = {
  status: number;
  statusText: string;
  url: string;
  headers: Headers;
  data?: any;
};

export class RequestError extends Error {
  result: RequestResult;
  constructor(result: RequestResult, message?: string) {
    super(message);
    this.result = result;
    this.message = message || result.statusText;
  }
}

const parseResponse = async (route: any, response: Response, dispatch: any) => {
  const { status, statusText, headers, url } = response;
  const result: RequestResult = { status, statusText, headers, url };
  try {
    const contentType = response.headers.get("content-type");
    let responseJson;
    if (contentType && contentType.indexOf("application/json") !== -1) {
      responseJson = await response.json();
    } else if (
      contentType &&
      contentType.indexOf("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") !== -1
    ) {
      responseJson = await response.arrayBuffer();
    } else {
      responseJson = await response.text();
    }
    result.data = responseJson;
  } catch (e) {}
  if (response.status === 401) {
    route.history.push("/auth/login");
    dispatch(Actions.Session.logout());
  }
  if (response.status >= 400 && response.status < 600) {
    throw new RequestError(result, result.data?.error?.message || result.data?.message);
  }

  return result;
};

export default (apiName?: string) => {
  const apiPrefix = apiName || API_HOST;
  if (!apiPrefix) throw Error("No API host provided");
  const http = new HTTP(apiPrefix, Endpoints);
  const route = useRouter();
  const dispatch = useDispatch();
  const token = useSelector<RootState, string>(state => state?.Session?.token);

  const defaultOptions = apiName
    ? {
        headers: {
          "X-API-Key": VM_AI_API_KEY,
        },
      }
    : token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};
  const executor = (url: string) => ({
    get: async (options: any = {}) =>
      await http.get({ url, ...defaultOptions, ...options }).then(response => parseResponse(route, response, dispatch)),
    post: async (options: any = {}) =>
      await http
        .post({ url, ...defaultOptions, ...options })
        .then(response => parseResponse(route, response, dispatch)),
    patch: async (options: any = {}) =>
      await http
        .patch({ url, ...defaultOptions, ...options })
        .then(response => parseResponse(route, response, dispatch)),
    del: async (options: any = {}) =>
      await http.del({ url, ...defaultOptions, ...options }).then(response => parseResponse(route, response, dispatch)),
    put: async (options: any = {}) =>
      await http.put({ url, ...defaultOptions, ...options }).then(response => parseResponse(route, response, dispatch)),
    postformdata: async (options: any = {}) =>
      await http
        .postFormData({ url, ...defaultOptions, ...options })
        .then(response => parseResponse(route, response, dispatch)),
    patchformdata: async (options: any = {}) =>
      await http
        .patchFormData({ url, ...defaultOptions, ...options })
        .then(response => parseResponse(route, response, dispatch)),
    multipost: async (options: any = {}) =>
      await http
        .multi_post({ url, ...defaultOptions, ...options })
        .then(response => parseResponse(route, response, dispatch)),
  });
  return {
    path: (path: keyof typeof Endpoints, routeParams?: PathParams, queryParams?: PathParams) => {
      const url = http.path(path, routeParams, queryParams);
      return executor(url);
    },
  };
};
