import React, { useEffect, useState } from "react";
import { Box, Button, Switch, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { Page, CustomTable, PopupForm } from "components";
import { tableConfig, formStructure } from "./configs";
import { useGetListing, usePostCreateNewListing } from "./apis";
import { useFilter } from "utils/filter";
import useFormHandler from "utils/forms/useFormHandler";
import { Search } from "components/SearchBar/components";

const ListingPage = () => {
  const classes = useStyles();
  const [filter, setFilter] = useFilter("listing");
  const [open, setOpen] = useState(false);
  const { getListingData, getListingLoading, resetData } = useGetListing(
    "listing",
    undefined
  );
  const { createNewListingMutate } = usePostCreateNewListing(
    resetData,
    setOpen
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fields, inputs, errors, setInputs, setDirtys] =
    useFormHandler(formStructure);

  const handleCreateNewListing = () => {
    if (errors) {
      setDirtys(errors);
      return;
    }
    createNewListingMutate({
      title: inputs?.title ?? "",
    });
  };

  useEffect(() => {
    // reset filter when component unmount
    return () => {
      setFilter({
        limit: 20,
        offset: 0,
        sort: "id:ASC",
        published: undefined,
        search: "",
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      title="Listing"
      dashboard
      pagesNav={null}
      buttonTop={
        <Button className={classes.headerButton} onClick={() => setOpen(true)}>
          <img
            style={{ marginRight: 30 }}
            src="/images/Complete_icon.png"
            alt=""
          />
          New Listing
        </Button>
      }
    >
      <Box className={classes.wrapContent}>
        <Box className={classes.syncSection} pb={4} paddingTop={4}>
          <Box className={classes.searchWrapper}>
            <Search
              name="listing"
              placeholder="Search listing by name or keywords…"
              fullWidth
            />
          </Box>
          <Box
            className={classes.wrapViewUnpublishedGrp}
            display="flex"
            alignItems="center"
          >
            <Typography className={classes.viewUnpublished}>
              View Unpublished
            </Typography>
            <Switch
              checked={filter?.published === 0}
              onChange={() => {
                resetData();
                setFilter({
                  ...filter,
                  offset: 0,
                  published: filter.published === undefined ? 0 : undefined,
                });
              }}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Box>
        </Box>
        <CustomTable
          config={tableConfig}
          items={getListingData.items}
          loading={getListingLoading}
          hasMore={getListingData.hasMore}
          height={600}
          filterKey={"listing"}
          resetData={resetData}
        />
      </Box>
      <PopupForm
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        fields={fields}
        handleCreate={handleCreateNewListing}
        title="NEW LISTING"
      />
    </Page>
  );
};

export default ListingPage;
