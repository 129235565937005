import React, { useCallback, useState } from "react";
import {
  makeStyles,
  Typography,
  Box,
  Button,
  Drawer,
  TextField,
  Grid,
} from "@material-ui/core";
import dateFormat from "utils/formatting/dateFormat";
import { useAsyncTask } from "utils/tools";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";
import { ReactComponent as KidztimeAvatar } from "assets/images/logo/kidztime-circle.svg";
import { IconComponent } from "components";

const ConversationDrawer = (props) => {
  const { open, onClose, onSubmit, model } = props;
  const classes = useStyles();
  const api = useApi();
  const [runApi, load, error, setError] = useAsyncTask("qa-edit"); // eslint-disable-line
  const [replyContent, setReplyContent] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const setNameCustomer = useCallback(() => {
    if (model?.account) {
      let firstName = model?.account?.firstname
        ? model?.account?.firstname
        : "-";
      let lastName = model?.account?.lastname ? model?.account?.lastname : "";
      firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
      return firstName + " " + lastName;
    } else {
      return model?.first_name || "-"
    }
  }, [model?.account?.firstname, model?.account?.lastname]); // eslint-disable-line

  const handleSubmitReply = useCallback(() => {
    runApi(async () => {
      const body = {
        id: model?.id,
        answer: replyContent,
      };
      try {
        const res = await api.path("question_answer/update").post({ body });
        if (res?.data?.data) {
          enqueueSnackbar(`Message has been updated!.`, {
            variant: "success",
          });
          onSubmit();
        } else {
          throw new Error(res?.data?.message);
        }
      } catch (error) {
        enqueueSnackbar(error.message || error, {
          variant: "error",
        });
      }
    });
  }, [open, replyContent, model]); //eslint-disable-line

  const handleDeleteReply = useCallback(() => {
    runApi(async () => {
      const body = {
        id: model?.id,
        answer: null,
      };
      try {
        const res = await api.path("question_answer/update").post({ body });
        if (res?.data?.data) {
          enqueueSnackbar(`Message was deleted.`, {
            variant: "success",
          });
          onSubmit();
        } else {
          throw new Error(res?.data?.message);
        }
      } catch (error) {
        enqueueSnackbar(error.message || error, {
          variant: "error",
        });
      }
    });
  }, [open, replyContent, model]); //eslint-disable-line

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.conversationDrawer }}
    >
      <Box>
        <Typography className={classes.conversationTitle}>
          RE: Inquiry about item
        </Typography>
        <Box className={classes.customerMessage}>
          <Typography className={classes.customerMessageTitle}>
            Q: Inquiry about item
          </Typography>
          <Typography className={classes.dateBox}>
            {dateFormat(model?.created_at)}
          </Typography>
          <></>
          <Typography className={classes.customerMessageContent}>
            {model?.question}
          </Typography>
          <Typography className={classes.customerMessageFromLabel}>
            From
          </Typography>
          <Typography className={classes.customerMessageAuthorName}>
            {setNameCustomer() || "-"}
          </Typography>
          <Typography className={classes.customerMessageAuthorEmail}>
            {model?.account?.email_address || model?.email}
          </Typography>
        </Box>

        {model?.answer && (
          <Box className={classes.kidztimeMessageContainer}>
            <Grid container spacing={3}>
              <Grid item xs={1} className={classes.kidztimeAvatarContainer}>
                <KidztimeAvatar className={classes.kidztimeAvatar} />
              </Grid>
              <Grid item xs={11} className={classes.kidztimeMessage}>
                <Typography className={classes.kidztimeMessageTitle}>
                  A: Inquiry about item
                </Typography>
                <Typography className={classes.dateBox}>
                  {dateFormat(model?.updated_at)}
                </Typography>
                <Typography className={classes.kidztimeMessageContent}>
                  {model?.answer}
                </Typography>
              </Grid>
            </Grid>
            <Button
              className={classes.removeButton}
              onClick={handleDeleteReply}
            >
              <IconComponent name="bin" className={classes.removeButtonIcon} />
              Remove
            </Button>
          </Box>
        )}

        <hr className={classes.hrline} />
        {!model?.answer && (
          <>
            <TextField
              className={classes.postTextField}
              fullWidth
              placeholder="Type something..."
              multiline
              onChange={(e) => {
                setReplyContent(e.currentTarget.value);
              }}
              onBlur={(e) => {}}
              error={false}
              helperText={""}
              required
              variant="outlined"
              type={"textarea"}
              InputLabelProps={{ shrink: false }}
            />
            <Button className={classes.postBtn} onClick={handleSubmitReply}>
              Post
            </Button>
          </>
        )}
      </Box>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  dateBox: {
    display: "inline",
    width: "80px",
    color: "#0081FF",
    fontSize: "12px",
    fontWeight: 500,
    background: "#F0F2F4",
    borderRadius: "2px",
    textAlign: "center",
    float: "right",
  },
  conversationDrawer: {
    width: "80%",
    padding: "80px 20px",
  },
  hrline: {
    borderTop: "1px solid",
    borderColor: "#DBDBDB",
    marginTop: "50px",
    marginBottom: "50px",
  },
  postTextField: {
    "& textarea": {
      minHeight: "60px!important",
    },
  },
  conversationTitle: {
    fontWeight: "700",
    fontSize: "32px",
  },
  customerMessage: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(4),
    padding: "8px 20px",
    borderLeft: "3px solid #5897ED",
  },
  customerMessageTitle: {
    display: "inline",
    fontWeight: "600",
    fontSize: "16px",
  },
  customerMessageContent: {
    fontWeight: "500",
    fontSize: "16px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  customerMessageFromLabel: {
    fontWeight: "500",
    fontSize: "16px",
    marginBottom: theme.spacing(1),
  },
  customerMessageAuthorName: {
    display: "inline",
    fontWeight: "500",
    fontSize: "16px",
    color: "#0D5AE5",
  },
  customerMessageAuthorEmail: {
    display: "inline",
    fontWeight: "500",
    fontSize: "16px",
    color: "#0D5AE5",
    marginLeft: theme.spacing(5),
  },
  postBtn: {
    textTransform: "none",
    padding: "8px 56px",
    fontSize: "16px",
    fontWeight: "700",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    marginTop: theme.spacing(3),
    float: "right",
  },
  kidztimeMessage: {
    borderLeft: "3px solid #5897ED",
    padding: "8px 20px !important",
  },
  kidztimeMessageContainer: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(8),
  },
  kidztimeMessageTitle: {
    display: "inline",
    fontWeight: "600",
    fontSize: "16px",
  },
  kidztimeMessageContent: {
    fontWeight: "500",
    fontSize: "16px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  kidztimeAvatarContainer: {
    paddingTop: "0px !important",
  },
  kidztimeAvatar: {
    float: "right",
  },
  removeButton: {
    float: "right",
    display: "flex",
    borderRadius: "5px",
    padding: "5px 16px",
    fontSize: "12px",
    fontWeight: "700",
    textTransform: "none",
  },
  removeButtonIcon: {
    marginRight: "10px",
  },
}));
export default ConversationDrawer;
