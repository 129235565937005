const paths = {
  "account/login": "/public/session/login", // post
  "account/profile": "/account/profile", // get
  "account/password": "/account/password", // post
  "account/list": "/account/search", // get
  "account/create": "/admin/account/create", // post
  "account/detail": "/admin/account/:account_id/detail", // get
  "account/update": "/admin/account/:account_id/update", // post
  "account/delete": "/admin/account/:account_id/delete", // delete
  "account/privilege": "/admin/account/:account_id/privilege", // post
  "account/redemptions": "/admin/account/:account_id/redemptions", // get
  "account/vouchers": "/admin/account/:account_id/vouchers", // get
  "account/statistics": "/admin/account/:account_id/statistics", // get
  "asset/create": "/asset/create", // post
  "asset/update": "/asset/update", // post
  "asset/delete": "/asset/delete", // post
  "order/picking-list": "/order/picking-list", // post
  "order/order-ship": "/admin/order/:order_id/order-ship", // put
  "order/order-ship-dispatched": "/admin/order/order-ship", // post
  "order/order-complete": "/admin/order/order-complete", // post
  "order/payment/exchange_items": "/admin/order/:order_id/payment/exchange_items", //post
  "order/payment/refund_items": "/admin/order/:order_id/payment/refund_items", //post
  "order/payment/cancel": "/admin/order/:order_id/payment/cancel ",
  "order/force-status": "/admin/order/:order_id/force-status", //post
  "order/update": "/order/:order_id", // post
  "order/refund": "/order/refund/:order_id", // post

  "note/create": "/admin/note/create", // post
  "note/update": "/admin/note/:order_id/update", // post

  "password/request": "/public/password/request", // post
  "password/query": "/public/password/query", // post
  "password/reset": "/public/password/reset", // post

  "privilege/list": "/admin/privilege/list", // get

  "product/list": "/product", // get
  "product/sync_to_google_catalog": "/product/sync_to_google_catalog", // get
  "product/sync_to_facebook_catalog": "/product/sync_to_facebook_catalog", // get
  "product/sync_to_catalog": "/product/sync_to_catalog", // get
  "product/patch": "/product/:product_id", // patch
  "product/detail": "/product/:product_id", // get
  "product/create": "/product/create", // post
  "product/update": "/product/:product_id", // post
  "product/group/get": "/admin/product/:product_id/group/list", //get
  "product/group/set": "/admin/product/:product_id/group/set", // post
  "product/delete": "/product/:product_id", // delete
  "product/preview/add": "/admin/product/:product_id/images/preview", // multipost
  "product/gallery/add": "/admin/product/:product_id/images/gallery/create", // multipost
  "product/gallery/delete": "/admin/product/:product_id/images/gallery/:asset_id/delete", // delete

  item_type: "/item_type", // get
  "post/item_type": "/item_type", // post
  "get/item_type/detail": "/item_type/:item_type_id", // get
  "patch/item_type/detail": "/item_type/:item_type_id", // patch
  "delete/item_type/detail": "/item_type/:item_type_id", // delete
  "category/create": "/admin/category", // post
  "category/detail": "/admin/category/:category_id/detail", // get
  "category/update": "/admin/category/:category_id/update", // post
  "category/saveall": "/admin/category/:category_id/save", // put
  "category/link/create": "/admin/category/set_links", //post
  "category/link/unlink": "/admin/category/unlink", //post
  "category/delete": "/admin/category/:category_id/delete", // delete
  "classification/icon": "/admin/asset/create", // multipost
  "category/product/list": "/admin/category/category/:category_id/product/list", // get

  "category/product_type/list": "/admin/category/product_type", // get

  item_group: "/item_group", // get
  "character/create": "/admin/character/create", // get
  "character/update": "/admin/character/:character_id/update", // post
  "character/delete": "/admin/character/:character_id/delete", // delete
  "character/detail": "/admin/character/:character_id/detail", // get
  "character/saveall": "/admin/character/:character_id/save", // put

  item_class: "/item_class", // get
  "create/item_class": "/item_class", // get
  "update/item_class": "/item_class/:item_class_id", // patch
  "delete/item_class": "/item_class/:item_class_id", // delete
  item_sub_class: "/item_sub_class", // get
  "create/item_sub_class": "/item_sub_class", // get
  "get/item_sub_class": "/item_sub_class/:item_sub_class_id", // get
  "update/item_sub_class": "/item_sub_class/:item_sub_class_id", // patch
  "delete/item_sub_class": "/item_sub_class/:item_sub_class_id", // delete
  "product_type/create": "/admin/product_type/create", // get
  "product_type/update": "/product_type/update", // post
  "product_type/delete": "/admin/product_type/:product_type_id/delete", // delete
  "item_class/detail": "/item_class/:item_class_id", // get
  "item_class/multitab_table/detail": "/item_class/:item_class_id/product_info", // get
  "item_class/carousel/detail": "/item_class/product_details/:item_class_id", // get
  "item_class/carousel/create": "/asset/product_detail/detail/:item_class_id", // get
  "item_class/product_info/create": "/item_class/:item_class_id/product_info", // get
  "asset/carousel/delete": "/asset/:id", // get
  "item_class/product_info/patch": "/item_class/product_info/:product_info_id", // patch
  "item_class/update-detail": "/item_class/:item_class_id", // patch
  "item_class/product_info/delete": "/item_class/product_info/:product_info_id", // delete
  "producttype/link/unlink": "/admin/product_type/unlink", //post
  "product_type/saveall": "/admin/product_type/:product_type_id/save", // put

  "inventory/platform/list": "/admin/inventory/list", // get
  "inventory/platform/create": "/admin/inventory/create", // post
  "inventory/platform/update": "/admin/inventory/:inventory_id/update", // post
  "inventory/platform/delete": "/admin/inventory/:inventory_id/delete", // delete
  "inventory/platform/product/update": "/admin/inventory/:inventory_id/:product_id/update", // post
  "inventory/product/update": "/admin/inventory/:inventory_id/product/:product_id/update", // post

  "inventory/inventory_item/list": "/admin/inventory/:inventory_id/inventory_item/list", // get
  "inventory/inventory_item/create": "/admin/inventory/:inventory_id/inventory_item/create", // post
  "inventory/inventory_item/update": "/admin/inventory/:inventory_id/inventory_item/:inventory_item_id/update", // post
  "inventory/inventory_item/delete": "/admin/inventory/:inventory_id/inventory_item/:inventory_item_id/delete", // delete

  "settings/list": "/admin/objectmeta/settings/list", //get
  "settings/create": "/admin/objectmeta/settings/create", //post
  "settings/media": "/admin/objectmeta/settings/media", //update
  "settings/delete": "/admin/objectmeta/settings/:objectmeta_id/delete", //delete
  "settings/images/upload": "/admin/objectmeta/settings/:objectmeta_id/images/upload", //

  "frontend/webpage/list": "/admin/frontend/webpage/list", // get
  "frontend/webpage/create": "/admin/frontend/webpage/create", // post
  "frontend/webpage/detail": "/admin/frontend/webpage/:webpage_id/detail", // get
  "frontend/webpage/update": "/admin/frontend/webpage/:webpage_id/update", // post
  "frontend/webpage/delete": "/admin/frontend/webpage/:webpage_id/delete", // delete
  "frontend/webpage/element": "/admin/frontend/webpage/:webpage_id/element", // post
  "frontend/webpage/element/list": "/admin/frontend/webpage/element/list", // get
  "frontend/webpage/element/detail": "/admin/frontend/webpage/element/:element_id/list", // get
  "frontend/webpage/element/image": "/admin/frontend/webpage/element/:element_id/image", // post
  "frontend/webpage/element/update": "/admin/frontend/webpage/element/:element_id/update", // post
  "frontend/webpage/element/delete": "/admin/frontend/webpage/element/:element_id/delete", // delete
  "frontend/webpage_item/detail": "/admin/frontend/webpage/element/:webpage_item_id/detail", // delete

  "objectmeta/banner/list": "/admin/objectmeta/banner/list", // get
  "objectmeta/banner/create": "/admin/objectmeta/banner/create", // post
  "objectmeta/banner/update": "/admin/objectmeta/banner/:objectmeta_id/update", // post
  "objectmeta/banner/delete": "/admin/objectmeta/banner/:objectmeta_id/delete", // delete

  "group/promotion_group/list": "/group", // get
  "group/group_id/detail": "/group/:id/detail", // get
  "group/promotion_group/create": "/group/create", // post
  "group/promotion_group/detail": "/group/:group_id/detail", // get
  "group/promotion_group/update": "/group/:group_id/update", //multipost
  "group/promotion_group/patch": "/group/:id", //patch
  "group/promotion_group/delete": "/admin/group/:group_id/delete", //delete
  "group/promotion_group/product/update": "/admin/group/:group_id/product/update", //post

  "promotion/list": "/promotion", //get
  "promotion/create": "/promotion", // post
  "promotion/detail": "/promotion/:id", // get
  "promotionTier/detail": "/promotion-tier/:id", // get
  "promotionCondition/detail": "/promotion-condition/:id", // get
  "promotion/patch": "/promotion/:id", //patch
  "promotion/delete": "/promotion/:id", //delete
  "promotionTier/patch": "/promotion-tier/:id", //patch
  "promotionTier/create": "/promotion-tier", // post
  "promotionTier/delete": "/promotion-tier/:id", // del
  "promotionCondition/patch": "/promotion-condition/:id", //patch
  "promotionCondition/create": "/promotion-condition", // post
  "promotionCondition/delete": "/promotion-condition/:id", // del
  "promotion_product/list": "/promotion-product", //get
  "promotion_product/delete": "/promotion-product/:promotionId/:productId", //delete
  "promotion_product_all/list": "/product/promotion", //get
  "promotion_product/create": "/promotion-product", //post
  "bundle_product/list": "/bundle-association", //get
  "bundle_product_all/list": "/product/promotion", //get
  "bundle_product/delete": "/bundle-association/:promotionId/:productId", //delete
  "bundle_product_all/create": "/bundle-association", //post
  "bundle_product_all/update": "/bundle-association", //patch

  "voucher/list": "/vouchers", // get
  "voucher/create": "/vouchers", // post
  "voucher/detail": "/vouchers/:voucher_id", // get
  "voucher/update": "/vouchers/:voucher_id", //patch
  "voucher/delete": "/vouchers/:voucher_id", // delete

  "vouchers_condition/create": "/vouchers_condition", //post
  "vouchers_condition/update": "/vouchers_condition/:vouchers_condition_id", //patch
  "vouchers_condition/delete": "/vouchers_condition/:vouchers_condition_id", //delete

  "vouchers_issued/list": "/vouchers_issued", //get
  "vouchers_issued/create": "/vouchers_issued", //post
  "vouchers_issued/update": "/vouchers_issued/:vouchers_issued_id", //patch
  "vouchers_issued/delete": "/vouchers_issued/:vouchers_issued_id", //delete

  "question_answer/create": "/question_answer/admin/create", // post
  "question_answer/update": "/question_answer/admin/update", //patch
  "question_answer/delete": "/question_answer/admin/delete", //delete
  "question_answer/list": "/question_answer/:product_type_id", //get
  "question_answer/batch_delete": "/admin/question_answer/batch_delete", //batch delete

  "review/update": "/review/admin/update", //put
  "review/create": "/review/admin/create", //post
  "review/list": "/review/:item_class_id", //get
  "review/delete": "/review/admin/delete", //batch delete

  "qa/list": "/admin/product_type/:product_type_id/list_qa", //get
  // "account/stripe": "/admin/account/:account_id/stripe", // get
  // "account/card/delete": "/admin/account/stripe/source/:objectmeta_id/delete", // delete
  // "account/password/admin": "/admin/account/:account_id/password", // post

  //new api
  "auth/login": "/auth/login",
  "account/me": "/account/me",
  "order/list": "/order", // get,
  vouchers_issued: "/vouchers_issued", // get,
  "order/detail": "/order/:order_id", // get,
  "order/item/list": "/order-item?sort=DESC&limit=0&order_id=:order_id", // get
  "order/update-by-id": "/order/:id", //patch order
  "asset/banner_carousel": "/asset/banner_carousel", // get
  "asset/banner": "/asset/banner",
  "group/group": "/group",
  "campaign/campaign": "/campaign",
  "campaign/campaign/update": "/campaign/:campaign_id",
  "campaign/create": "/campaign",
  "campaign-dtl/promotion/campaign/campaign-id": "/campaign-dtl/promotion/campaign/:campaign_id",
  "campaign-dtl/promotion/campaign/delete": "/campaign-dtl/promotion/:id",
  "campaign-dtl/update": "/campaign-dtl/promotion/:campaign_dtl_id",
  "campaign-dtl/promotion": "/campaign-dtl/promotion",
  "vouchers_issued/patch": "/vouchers_issued/:id",
  "vouchers/list": "/vouchers", //get
  "vouchers/create": "/vouchers_issued", //post
  listing: "/listing", //get
  "create/listing": "/listing", //post
  "delete/listing": "/listing/:listing_id", //post
  "update/listing": "/listing/:listing_id", //patch
  "get/product/listing_id/description": "/product/:listing_id/description", //get
  item_category: "/item_category", //get
  "get/item_category": "/item_category/:item_category_id", //get
  "update/item_category": "/item_category/:item_category_id", //get
  "delete/item_category": "/item_category/:item_category_id", //delete
  "create/item_category": "/item_category", //post
  color: "/color", //get
  group: "/group", //get
  gift_grp: "/gift_grp", //get
  accessory_grp: "/accessory_grp",
  "update/product": "/product/:product_id",
  "sync-inventory": "/vmos/product-inventory-refresh",
  "landing-page": "/landing-page",
  "landing-page/detail": "/landing-page/:id",
  "landing-page/update": "/landing-page",
  "landing-page/create": "/landing-page",
  // asset
  asset: "/asset", //get
  "create/asset": "/asset/create", //create
  "delete/asset": "/asset/:id", //delete
  "patch/asset": "/asset", //patch
  "get/asset": "/asset/:id", //get
  // review
  review: "/review", //get
  "patch/review": "/review/admin/:review_id", //patch,
  // question_answer
  question_answer: "/question_answer", //get
  "patch/question_answer": "/question_answer/admin/:question_answer_id", //patch
  "product-options": "/product-options", //get, post
  "del/product-options": "/product-options/:id", //delete
  "patch/product-options": "/product-options/:id", //delete
  "cart/checkout_preview": "/cart/checkout_preview", //post
  // accessory_grp
  "post/accessory_grp": "/accessory_grp", //post
  // vmos
  "vmos/item": "/vmos/item", //get
  "vmos/catalogue": "/vmos/catalogue", //get
  webpage: "/webpage", //get
  "webpage/detail": "/webpage/:id", //get
  "patch/webpage/detail": "/webpage/:id", //patch
  "post/meili_search/product": "/meili_search/product", //post
  "get/landing-page-v2": "/landing-page-v2", //get
  "path/landing-page-v2": "/landing-page-v2", //path
  "post/landing-page-v2": "/landing-page-v2", //post
  "get/collection": "/collection/list", // get
  "create/collection": "/collection", //post
  "get/collection/detail": "/collection/:collection_id/detail", //get
  "patch/collection/detail": "/collection/:collection_id", //patch
  "get/item_brand": "/item_brand", //get
  "post/item_brand": "/item_brand", //get
  "get/item_brand/item_brand_id": "/item_brand/:item_brand_id", //get
  "patch/item_brand/item_brand_id": "/item_brand/:item_brand_id", //patch
  "delete/item_brand/item_brand_id": "/item_brand/:item_brand_id", //delete
  "post/webpage": "/webpage", //post
  "get/item_group": "/item_group", //get
  "post/item_group": "/item_group", //post
  "get/item_group/item_group_id": "/item_group/:item_group_id", //get
  "patch/item_group/item_group_id": "/item_group/:item_group_id", //patch
  "delete/item_group/item_group_id": "/item_group/:item_group_id", //delete

  "kidztime/image/remove_background": "/kidztime/kidztime/image-processing?asset_id=:asset_id", // post
  "kidztime/product/generate_product_writeups": "/kidztime/kidztime/generate-product-writeups?product_id=:product_id", // post
  "kidztime/product/generate_product_review_writeups":
    "/kidztime/kidztime/generate-product-review-writeups?product_id=:product_id", // post
  "kidztime/listing/generate_Q&A": "/kidztime/kidztime/generate-listing-faq-writeups?listing_id=:listing_id", // post
  "kidztime/listing/marketplace-onboarding": "/kidztime/kidztime/marketplace-onboarding", // post
} as const;
export default paths;
