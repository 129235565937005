import { Box } from "@material-ui/core";
import React from "react";
import { Published } from "utils/constants";

export const formConfig = ({
  havePromo,
  havePublishedAndAllowNullDesAndTitle,
}: any) => {
  return {
    promotion: {
      placeholder: "Promotion",
      type: "autocomplete",
      label: "Promotion",
      pathname: "promotion/list",
      filterName: "getPromotion",
      width: 12,
      getOptionLabel: (option: any) => option?.name ?? "",
      getOptionSelected: (option: any, value: any) => option,
      onChange: (event: any, value: any, reason: any, field: any) => {
        field.onChange(null,value);
      },
      renderOption: (option: any, state: any) => {
        return <Box>{option.name}</Box>;
      },
    },
    title: {
      initialValue: "",
      label: "Banner Title",
      type: "text",
      ...(!havePublishedAndAllowNullDesAndTitle && {validate: {
        presence: {
          allowEmpty: false,
          message: "is required",
        },
      }})
    },
    description: {
      initialValue: "",
      label: "Banner Description",
      type: "textarea",
      ...(!havePublishedAndAllowNullDesAndTitle && {validate: {
        presence: {
          allowEmpty: false,
          message: "is required",
        },
      }})
    },
    cta_text: {
      initialValue: "",
      label: "Banner Button Text",
      type: "text",
      validate: {
        presence: { allowEmpty: false, message: "is required" },
      },
    },
    cta_link: {
      initialValue: "",
      label: "Button Link",
      type: "text",
      disabled: havePromo,
    },
    published: {
      initialValue: "",
      placeholder: "Published",
      label: "Published",
      type: "select",
      validate: {
        ...(havePublishedAndAllowNullDesAndTitle && {
          presence: { allowEmpty: false, message: "is required" },
        }),
        inclusion: Object.values(Published),
      },
      options: Published,
    },
  };
};
