import { TYPES } from "./actions";
import { PropAction } from "../props";

export type FormExchangeItemState = {
  OldItemSelectedLeft: any;
  OldItemSelected: Array<{}>;
  NewItemSelected: Array<{}>;
  OldOrderItemsLeft: Array<{}>;
  OldOrderItems: Array<{}>;
  NewOrderItems: Array<{}>;
  Quantity: any;
};

const initial_state: FormExchangeItemState = {
  OldItemSelectedLeft: null,
  OldItemSelected: [],
  NewItemSelected: [],
  OldOrderItemsLeft: [],
  OldOrderItems: [],
  NewOrderItems: [],
  Quantity: [],
};

const checkExistOrder = (items: any, actionItems: any) => {
  let index = null;
  for (let i = 0; i < items.length; i++) {
    if (actionItems.id === items[i].id) {
      index = i;
      break;
    }
  }
  return index;
};

export default (
  state: FormExchangeItemState = initial_state,
  actions: PropAction
) => {
  switch (actions.type) {
    case TYPES.OLD_ITEM_SELECTED:
      return {
        ...state,
        [actions.props.key]: actions.props.value,
      };
    case TYPES.OLD_ITEM_SELECTED_LEFT:
      return {
        ...state,
        [actions.props.key]: actions.props.value,
      };
    case TYPES.NEW_ITEM_SELECTED:
      let newItem = [...state.NewItemSelected];
      const indexNew = checkExistOrder(
        state.NewItemSelected,
        actions.props.value
      );
      if (
        state.OldItemSelected.length > state.NewItemSelected.length &&
        indexNew === null
      ) {
        newItem.push(actions.props.value);
      }

      return {
        ...state,
        [actions.props.key]: newItem,
      };
    case TYPES.CLEAR_NEW_ITEM_SELECTED:
      let clearNewItemSelected = [...state.NewItemSelected];
      let QuantityOfNewItemSelected = [...state.Quantity];
      if (actions.props === undefined) {
        clearNewItemSelected = [];
        QuantityOfNewItemSelected = [];
      } else {
        QuantityOfNewItemSelected.splice(
          state.NewItemSelected.indexOf(actions.props),
          1
        );
        clearNewItemSelected = clearNewItemSelected.filter(
          (element: any) => element.id !== actions.props.id
        );
      }
      return {
        ...state,
        NewItemSelected: clearNewItemSelected,
        Quantity: QuantityOfNewItemSelected,
      };
    case TYPES.UPDATE_QUANTITY:
      let quantity = [...state.Quantity];
      const indexQty = checkExistOrder(
        state.NewItemSelected,
        actions.props.model
      );
      if (indexQty === null) {
        quantity.push(actions.props.quantity);
      } else {
        quantity[indexQty] = actions.props.quantity;
      }
      return {
        ...state,
        Quantity: quantity,
      };
    case TYPES.CLEAR_QUANTITY:
      return {
        ...state,
        Quantity: [],
      };
    case TYPES.CLEAR_ITEM_EXCHANGE:
      return {
        ...state,
        OldOrderItemsLeft: [],
        OldOrderItems: [],
        NewOrderItems: [],
      };
    case TYPES.UPDATE_OLD_ORDER_LEFT:
      return {
        ...state,
        OldOrderItemsLeft: actions.props,
      };
    case TYPES.UPDATE_OLD_ORDER:
      let dataOldOrderItems = actions.props.map((element: any) => {
        let discount_value =
              element?.discount_type === "percent"
                ? (element?.unit_price * element?.discount_value) / 100
                : element?.discount_value;
        return {
          product_id: element.id,
          price: (element.unit_price - discount_value)*element.quantity,
          quantity: element.quantity,
        };
      });
      return {
        ...state,
        OldOrderItems: dataOldOrderItems,
      };
    case TYPES.UPDATE_NEW_ORDER:
      let newOrderItems = actions.props.map((element: any, index: number) => {
        const discount_value =
          element?.group?.discount_type === "percent"
            ? (element?.price * element?.group?.discount_value) / 100
            : element?.group?.discount_value;
        return {
          product_id: element.id,
          price: element.price- ( discount_value || 0 ),
          quantity: state.Quantity[index],
        };
      });
      return {
        ...state,
        NewOrderItems: newOrderItems,
      };
    default:
      return state;
  }
};
