// import { Button, Grid } from "@material-ui/core";
// import FilterListIcon from "@material-ui/icons/FilterList";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonGroup } from "components";
// import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Search } from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      display: "block",
    },
    marginRight: 30,
  },
  btnClass: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      width: "100%",
    },
  },
}));

const SearchBar = props => {
  const { name, placeholder, buttons } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Search
        name={name}
        placeholder={placeholder}
      />
      <ButtonGroup
        buttons={buttons}
        btnClass={classes.btnClass}
      />
    </div>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func
};

SearchBar.defaultProps = {
  buttons: {},
  placeholder: "Button Function"
};

export default SearchBar;
