import React, { useState, useMemo, useCallback, useEffect } from "react";
import { SearchBar, IconComponent, ActionDialog, LoadButton } from "components";
import { DateRangePicker } from "materialui-daterange-picker";
import { useFilter } from "utils/filter";
import { useDispatch, useSelector } from "react-redux";
import { useAsyncTask } from "utils/tools";
import { useSnackbar } from "notistack";
import moment from "moment";
import { makeStyles, Box, TextField } from "@material-ui/core";
import useApi from "utils/api/useApi";
import { Actions } from "store";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  orderCreateDate: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    padding: theme.spacing(2),
    "& > div": {
      fontSize: "medium",
    },
  },
  textField: {
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    marginLeft: theme.spacing(1),
    minWidth: 207,
  },
  dateRangePicker: {
    position: "absolute",
    right: "16px",
    top: "70px",
    "& div > ul": {
      display: "none",
    },
  },
  searchBar: {
    width: "450px",
  },
  searchLabel: {
    fontSize: "14px",
    fontWeight: "500",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  removeButton: {
    float: "right",
    display: "flex",
    border: "2px solid #333333",
    borderRadius: "5px",
    padding: "5px 16px",
    fontSize: "12px",
    fontWeight: "700",
    textTransform: "none",
  },
  removeButtonIcon: {
    marginRight: "10px",
  },
  noteWarning: {
    color: "red",
    fontSize: "16px",
    fontWeight: "500",
  },
  actionDialogIcon: {
    fill: "#E50D0D",
  },
}));

const TopElements = (props) => {
  const classes = useStyles();
  const { name } = props;
  const [openDateRange, setOpenDateRange] = useState(false);
  const [filter, updateFilter] = useFilter(name);
  const checkedList = useSelector((state) => state.Models.qa.checkedList);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const api = useApi(); // eslint-disable-line
  const [runApi, load, error, setError] = useAsyncTask("qa-batch-remove"); // eslint-disable-line
  const [dateRange, setDateRange] = useState({
    startDate: moment(moment().subtract(30, "days")),
    endDate: moment().endOf("day"),
  });
  const [openDelete, setOpenDelete] = useState(false);

  const toggleDateRange = useCallback(
    () => setOpenDateRange((val) => !val),
    [setOpenDateRange]
  );
  const dateDisplay = useMemo(() => {
    let startDate = moment(dateRange.startDate).format("DD/MM/YYYY");
    let endDate = moment(dateRange.endDate).format("DD/MM/YYYY");
    return `${startDate} - ${endDate}`;
  }, [dateRange.startDate, dateRange.endDate]);

  useEffect(() => {
    updateFilter({
      ...filter,
      offset: 0,
      created_at: `${moment(dateRange.startDate).format("X")},${moment(
        dateRange.endDate
      ).format("X")}`,
    });
  }, [dateRange.startDate, dateRange.endDate]); // eslint-disable-line

  const clickOpenDateRange = useCallback(
    () => setOpenDateRange(true),
    [setOpenDateRange]
  ); // eslint-disable-line

  const handleDelete = useCallback(
    (model) => {
      runApi(async () => {
        const body = {
          ids: checkedList,
        };
        try {
          const result = await api.path("question_answer/delete").del({ body });
          if (result?.data?.data) {
            updateFilter({ ...filter, reload: !filter.reload });
            dispatch(
              Actions.Models.update({ key: name, value: { checkedList: [] } })
            );
            setOpenDelete(false);
            enqueueSnackbar(`Removed successfully.`, {
              variant: "success",
            });
          } else {
            throw new Error(result?.data?.message);
          }
        } catch (error) {
          enqueueSnackbar(error.message || error, {
            variant: "error",
          });
        }
      });
    },
    [checkedList] //eslint-disable-line
  );

  return (
    <React.Fragment>
      <Box className={classes.filterContainer}>
        <SearchBar
          name={props.name}
          placeholder="Search inquiry by name or keywords..."
        />
        <Box className={classes.orderCreateDate}>
          <Box className={classes.searchLabel}>Search Date</Box>
          <TextField
            className={classes.textField}
            variant="outlined"
            type="text"
            placeholder="DD/MM/YYYY - DD/MM/YYYY"
            onFocus={clickOpenDateRange}
            value={dateDisplay}
          />
          <Box className={classes.dateRangePicker}>
            <DateRangePicker
              open={openDateRange}
              toggle={toggleDateRange}
              initialDateRange={dateRange}
              onChange={(range) => {
                setDateRange({
                  startDate: range.startDate,
                  endDate: moment(range.endDate).endOf("day"),
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.actionContainer}>
        <LoadButton
          disabled={checkedList?.length <= 0 ? true : false}
          load={load}
          className={classes.removeButton}
          onClick={() => setOpenDelete(true)}
        >
          <IconComponent name="bin" className={classes.removeButtonIcon} />
          Remove from list
        </LoadButton>
      </Box>
      <ActionDialog
        overwriteIcon={
          <IconComponent name="delete" className={classes.actionDialogIcon} />
        }
        overwriteMessage={"Are you sure you want to delete the inquiries?"}
        overwriteNameButton={"Delete"}
        cancelBtnText={"Cancel"}
        handleUpdate={handleDelete}
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
      />
    </React.Fragment>
  );
};

export default TopElements;
