import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  Box,
  TableHead,
  TableRow,
  CircularProgress,
  TextField,
  Button
} from "@material-ui/core";
import React from "react";
import { useRouter } from "utils/tools";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
  editCell: {
    cursor: "pointer",
  },
  header: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#24243A",
    whiteSpace: "nowrap",
  },
  row: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#24243A",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "200px",
    overflow: "hidden",
    display: "revert",
  },
  table: {
    width: "100%",
  },
  addButton: {
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.615385px",
    color: "#333333",
    cursor: "pointer",
  },
  field: {
    background: "#FFFFFF",
    border: "1px solid #C9D1D9",
    borderRadius: "5px",
    "& .MuiInputBase-input": {
      textAlign: "center",
    }
  }
}));

interface IProps {
  headers: {
    key: string;
    value: string;
    group_id?: string;
    id?: string;
  }[];
  rows: any[];
  isDelete?: boolean;
  handleClose?: Function;
  handlePatchDtl?: Function;
  handleAddDtl?: Function;
  routeRoot?: string;
  loading?: boolean;
}

const TableListing = (props: IProps) => {
  const { headers, rows, isDelete, handleClose, routeRoot, loading, handlePatchDtl, handleAddDtl } = props;
  const router = useRouter();
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell className={classes.header} key={index}>
                {header?.value ?? "-"}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {loading ? (
          <TableBody>
            <CircularProgress />
          </TableBody>
        ) : (
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                {headers.map((header, index) => {
                  if (header.value === "Action" && header.key === "campaign_dtl_id") {
                    if (row[index]?.[header.key]) {
                      return (
                        <TableCell
                          key={index}
                          className={clsx(classes.row, classes.editCell)}
                        >
                          <img
                            style={{ marginRight: "5px" }}
                            src="/images/buttonIcons/close-button.png"
                            alt=""
                            onClick={() =>
                              handleClose &&
                              handleClose(row[index]?.[header.key])
                            }
                          />
                        </TableCell>
                      )
                    } else {
                      return (
                        <TableCell className={classes.addButton} key={index}>
                          <Button onClick={() => {
                            handleAddDtl && header.group_id && handleAddDtl(row[index]?.[header.group_id])
                          }}>
                          Add
                          </Button>
                        </TableCell>
                      );
                    }
                  } else if (header.value === "Action" && header.key !== "campaign_dtl_id") {
                    if (!isDelete) {
                      if (header.key !== "") {
                        return (
                          <TableCell
                            key={index}
                            className={clsx(classes.row, classes.editCell)}
                            onClick={() =>
                              router.history.push(
                                `/${routeRoot}/${row[index]?.[header.key]}`
                              )
                            }
                          >
                            <img
                              style={{ marginRight: "5px" }}
                              src="/images/buttonIcons/Edit_icon.png"
                              alt=""
                            />
                            Edit
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell className={classes.addButton} key={index}>
                            Add
                          </TableCell>
                        );
                      }
                    } else {
                      return (
                        <TableCell
                          key={index}
                          className={clsx(classes.row, classes.editCell)}
                        >
                          <img
                            style={{ marginRight: "5px" }}
                            src="/images/buttonIcons/close-button.png"
                            alt=""
                            onClick={() =>
                              handleClose &&
                              handleClose(row[index]?.[header.key])
                            }
                          />
                        </TableCell>
                      );
                    }
                  } else if (header.value === "Photo") {
                    return (
                      <TableCell className={classes.row} key={index}>
                        <img
                          style={{ width: "30px", height: "30px" }}
                          src={row[index]?.[header.key]}
                          alt=""
                        />
                      </TableCell>
                    );
                  } else if (header.value === "Status") {
                    return (
                      <TableCell className={classes.row} key={index}>
                        {row[index]?.[header.key] === 1 ||
                        row[index]?.[header.key] === "published"
                          ? "Published"
                          : "Unpublished"}
                      </TableCell>
                    );
                  } else if (header.value === "Overwrite Discount") {
                    return (
                      <TableCell className={classes.row} key={index}>
                        <TextField
                          className={classes.field}
                          defaultValue={row[index]?.[header.key]}
                          onBlur={(e) =>
                            handlePatchDtl && header.id && handlePatchDtl(e.target.value, row[index]?.[header.id])
                          }
                          InputProps={{ disableUnderline: true }}
                        />
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell className={classes.row} key={index}>
                        {row[index]?.[header.key] ?? "-"}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </Box>
  );
};

export default TableListing;
