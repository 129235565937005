import React from "react";
import { useStyles } from "./styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  Checkbox,
} from "@material-ui/core";
import { useRouter } from "utils/tools";
import { Sort, AutoComplete, DropDownMenu, EditableDatePicker } from "components";
import { useFilter } from "utils/filter";
import { FilterKeys } from "store/filter/actions";
import { getNestedProperty } from "utils/getNestedProperty";
import Editable from "components/Editable";
import MultipleSelect from "components/MultipleSelect";
import InfiniteScroll from "react-infinite-scroll-component";
import Placeholder from "assets/images/placeholder/placeholder.svg";

interface Entity {
  id?: string;
}
interface IProps<T> {
  config: any;
  items: T[];
  loading?: boolean;
  hasMore?: boolean;
  height?: number;
  filterKey?: FilterKeys;
  resetData?: () => void;
}

function CustomTable<T>({ config, items, loading, hasMore, height, filterKey, resetData }: IProps<T>) {
  const classes = useStyles({ height });
  const router = useRouter();
  const [filter, setFilter] = useFilter(filterKey);

  const rowContent = (type: string, key: number, config: any, content: any) => {
    switch (type) {
      case "toShow":
        return (
          <TableCell className={classes.toShowCell} key={key}>
            {config.showContent
              ? config.showContent(getNestedProperty(content, config.key))
              : getNestedProperty(content, config.key) ?? "-"}
          </TableCell>
        );
      case "toShowImage":
        return (
          <TableCell className={classes.toShowCell} key={key}>
            <img width={50} height={50} src={getNestedProperty(content, config.key) ?? Placeholder} alt="" />
          </TableCell>
        );
      case "toSelect":
        return (
          <TableCell className={classes.toShowCell} key={key}>
            <AutoComplete
              valueKey={config.valueKey}
              labelKey={config.labelKey}
              optionsStoreKey={config.optionsStoreKey}
              findOptionByKey={config.findOptionByKey}
              findOptionByKeyValue={getNestedProperty(content, config.findOptionByKey)}
              optionsFilterKey={config.optionsFilterKey}
              draftKey={config.draftKey}
              defaultOption={{
                [config.labelKey]: getNestedProperty(content, config.labelKey),
                [config.valueKey]: getNestedProperty(content, config.valueKey),
              }}
              hardOptions={config?.hardOptions}
              searchBy={config?.searchBy ?? "search"}
            />
          </TableCell>
        );
      case "toDropDownMenu":
        const modifiedItems = config.items.map((item: any) => {
          const newHandle = () => item.handle(content);
          return {
            ...item,
            handle: newHandle,
          };
        });
        return (
          <TableCell className={classes.toShowCell} key={key}>
            <DropDownMenu items={modifiedItems} />
          </TableCell>
        );
      case "toRedirect":
        const url = config.routerKeys.reduce((acc: string, key: string) => {
          return acc.replace(`:${key}`, content[key]);
        }, config.routerTo);
        return (
          <TableCell
            key={key}
            onClick={() => {
              config.runBeforeRoute && config.runBeforeRoute();
              getNestedProperty(content, config.key) && router.history.push(url);
            }}
          >
            {config?.showContent ? (
              <span
                style={{
                  textDecoration: getNestedProperty(content, config.key) ? "underline" : "none",
                  color: "#0D5AE5",
                }}
                className={classes.toRedirectCell}
              >
                {getNestedProperty(content, config.key) ?? "-"}
              </span>
            ) : (
              <span className={classes.toRedirectCell}>
                <img style={{ marginRight: "5px" }} src="/images/buttonIcons/Edit_icon.png" alt="" />
                Edit
              </span>
            )}
          </TableCell>
        );
      case "toDelete":
        return (
          <TableCell
            className={classes.toDeleteCell}
            key={key}
            onClick={() => config.deleteFunction(getNestedProperty(content, config.deleteKey))}
          >
            <img src="/images/buttonIcons/close-button.png" alt="" />
          </TableCell>
        );
      case "toEdit":
        return (
          <TableCell className={classes.toDeleteCell} key={key}>
            <Editable
              draftKey={config?.draftKey}
              id={content?.id}
              propKey={config?.key}
              text={getNestedProperty(content, config.key)}
              multiline={config?.multiline}
              rows={config?.rows}
            />
          </TableCell>
        );
      // render checkbox cell in each table row
      case "toCheck":
        const checked = config?.checkItemSelected(content);

        return (
          <TableCell className={classes.toShowCell} key={key}>
            <Checkbox
              disabled={config?.checkDisabled && config?.checkDisabled(content)}
              className={classes.toCheck}
              checked={checked}
              onClick={e => config?.selected(e, content)}
            />
          </TableCell>
        );
      // handle render for cell if it value depends on other cells in each row
      case "toMultipleSelect":
        return (
          <TableCell className={classes.toShowCell} key={key}>
            <MultipleSelect
              propKey={config?.key}
              labelKey={config.labelKey}
              optionsStoreKey={config.optionsStoreKey}
              findOptionByKey={config.findOptionByKey}
              findOptionByKeyValue={getNestedProperty(content, config.findOptionByKey)}
              optionsFilterKey={config.optionsFilterKey}
              draftKey={config.draftKey}
              searchBy={config?.searchBy ?? "search"}
              loading={config?.loading}
            />
          </TableCell>
        );
      case "toShowDepends":
        return (
          <TableCell className={classes.toShowCell} key={key}>
            {config?.handleShowContent(content)}
          </TableCell>
        );
      case "toPickDate":
        return (
          <TableCell className={classes.toShowCell} key={key}>
            <EditableDatePicker
              draftKey={config?.draftKey}
              id={content?.id}
              propKey={config?.key}
              format={config?.format}
              label={config?.label}
              text={getNestedProperty(content, config.key)}
            />
          </TableCell>
        );
      default:
        return <TableCell key={key}>{getNestedProperty(content, config.key) ?? "--"}</TableCell>;
    }
  };
  return (
    <Box className={classes.root}>
      <InfiniteScroll
        dataLength={items.length}
        next={() => {
          setFilter({
            ...filter,
            offset: filter.offset + filter.limit,
          });
        }}
        hasMore={hasMore ?? false}
        scrollableTarget={`scrollableCustomTable${filterKey}`}
        style={{ overflow: "unset" }}
        loader={false}
      >
        <TableContainer component={Paper} className={classes.tableContainer} id={`scrollableCustomTable${filterKey}`}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.theader}>
              <TableRow>
                {config.map((item: any, index: number) => (
                  <TableCell key={index} className={classes.headerItemWrapper}>
                    <div className={classes.headerItems}>
                      {item.title}
                      {item.sortable ? (
                        <Sort
                          filterKey={filterKey}
                          sortKey={item.key}
                          resetTable={resetData}
                          splitSort={item?.splitSort}
                        />
                      ) : null}
                      {/* render checkbox cell in table header */}
                      {item?.type === "toCheck" && (
                        <Box className={classes.toCheck}>
                          <Checkbox
                            disabled={!!item?.checkAllDisabled && item?.checkAllDisabled(items)}
                            checked={item?.checkedAll}
                            onClick={item?.selectedAll}
                          />
                        </Box>
                      )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((row, index: number) => {
                const data = row as T & Entity;
                return (
                  <TableRow key={data.id ?? index}>
                    {config.map((item: any, index: number) => rowContent(item.type, index, item, row))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {loading ? (
            <Box className={classes.spinner}>
              <CircularProgress size={24} />
            </Box>
          ) : null}
        </TableContainer>
      </InfiniteScroll>
    </Box>
  );
}

export default CustomTable;
