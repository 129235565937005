import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { Listing } from "components";
import { useFilter } from "utils/filter";
import { buttonConfig, fields } from "./managingReviewsConfig";
import { TopElements, ItemList, CreateReview } from "./components";
import { ButtonGroup } from "components";
import { useDispatch } from "react-redux";
import { Actions } from "store";

const tabName = {
  ALL_REVIEWS: "All Reviews",
  PENDING_REVIEWS: "Pending Reviews",
  PUBLISHED_REVIEWS: "Published Reviews",
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    boxShadow: " 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  button: {
    textTransform: "none",
    padding: "8px 20px",
    fontSize: "16px",
    "&:hover": {
      borderRadius: 0,
    },
    "&.MuiButton-root": {
      borderRadius: 0,
    },
  },
  active: {
    fontWeight: "600",
    borderBottom: "solid 4px " + theme.palette.primary.main,
  },
}));

const ManagingReviews = (props) => {
  const classes = useStyles();
  const { product_type_id, pageName, filterName } = props;
  const [openCreate, setOpenCreate] = useState(false);
  const [filter, updateFilter] = useFilter(filterName);
  const [customersFilter, updateCustomersFilter] = useFilter("customers");
  const [productFilter, updateProductFilter] = useFilter("product");
  const dispatch = useDispatch();

  const handleChangeTab = useCallback(
    (e) => {
      switch (e.currentTarget.value) {
        case tabName.ALL_REVIEWS:
          updateFilter({ ...filter, offset: 0, status: "all" });
          break;
        case tabName.PUBLISHED_REVIEWS:
          updateFilter({ ...filter, offset: 0, status: "published" });
          break;
        default:
          updateFilter({ ...filter, offset: 0, status: "unpublished" });
          break;
      }
    },
    [filter, updateFilter]
  );
  const onCreate = () => {
    setOpenCreate(false);
    dispatch(Actions.Filter.reload({ key: filterName }));
    updateCustomersFilter({
      ...customersFilter,
      sort: "firstname:DESC",
      search: "",
      reload: false,
    });
    updateProductFilter({
      ...productFilter,
      sort: "",
      search: "",
      reload: false,
    });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.headerTitle}>
        <Typography variant="h5">{pageName}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Button
            className={
              filter.status === "all"
                ? clsx(classes.button, classes.active)
                : classes.button
            }
            value="All Reviews"
            onClick={handleChangeTab}
          >
            All
          </Button>
          <Button
            className={
              filter.status === "published"
                ? clsx(classes.button, classes.active)
                : classes.button
            }
            value="Published Reviews"
            onClick={handleChangeTab}
          >
            Published
          </Button>
          <Button
            className={
              filter.status === "unpublished"
                ? clsx(classes.button, classes.active)
                : classes.button
            }
            value="Pending Reviews"
            onClick={handleChangeTab}
          >
            Pending
          </Button>
        </Box>
        <Box mb={2}>
          <ButtonGroup
            buttons={{
              create: {
                ...buttonConfig.create,
                onClick: () => {
                  setOpenCreate(true);
                },
              },
            }}
            btnClass={classes.btnClass}
          />
        </Box>
      </Box>
      <Box>
        <Listing
          name={filterName}
          path="review/list"
          params={{ item_class_id: product_type_id }}
          hasCheck={true}
          fields={fields}
          dense={true}
          pageRow={true}
          topElement={(props) => (
            <React.Fragment>
              <TopElements name={filterName} models={props.models} />
            </React.Fragment>
          )}
          itemComponent={ItemList}
          errorMsg="Sorry, we cannot find any orders. Please try searching again or check in again later."
        />
        <CreateReview open={openCreate} handleClose={() => onCreate()} />
      </Box>
    </Box>
  );
};

export default ManagingReviews;
