import {
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core';
import React from 'react';
import "slick-carousel/slick/slick.css";
import clsx from "clsx";

import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles((theme) => ({
  selectLayout: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginBottom: 50,
  },
  selectTitle: {
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#333333',
    marginBottom: 24,
    marginTop: 20,
  },
  layout: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '12px',
    cursor: 'pointer',
    alignItems: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
  layoutBox: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    border: '1px solid #fff',
  },
  layoutImage: {
    width: 200,
  },
  title: {
    fontWeight: 900,
    fontSize: '12px',
    lineHeight: '24px',
    color: '#A5A9B9',
  },
  selectedLayout: {
    border: '1px solid #0D5AE5',
    borderRadius: '4px',
  },
}));

interface IProps {
  handleSelectLayout: Function;
  selectedLayout: number;
}

const LayoutSelect = (props: IProps) => {
  const {handleSelectLayout, selectedLayout} = props;
  const classes = useStyles();
  const listLayout = [
    {
      key: 1,
      title: 'Layout 1',
      src: '/images/layout_middle.png',
    },
    {
      key: 2,
      title: 'Layout 2',
      src: '/images/layout_right.png',
    },
    {
      key: 3,
      title: 'Layout 3',
      src: '/images/layout_left.png',
    },
  ];

  return (
    <Box className={classes.selectLayout}>
          <Typography className={classes.selectTitle}>
            Select layout
          </Typography>
          <Box className={classes.layout}>
            {listLayout.map((layout) => (
              <Box
                key={layout.key}
                className={
                  clsx(classes.layoutBox,
                    selectedLayout === layout.key && classes.selectedLayout)
                }
                onClick={() => handleSelectLayout(layout.key)}>
                <Typography className={classes.title}>
                  {layout.title}
                </Typography>
                <img className={classes.layoutImage} alt='' src={layout.src} />
              </Box>
            ))}
          </Box>
        </Box>
  );
};

export default LayoutSelect;
