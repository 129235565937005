import { Box } from "@material-ui/core";
import React from "react";

interface ProductRecord {
  name: string;
  id: number;
  listing_id: number;
  url: string;
}

interface ProductOption {
  id: number;
  name: string;
}

export const formStructure = {
  product_id: {
    initialValue: "",
    placeholder: "Product Name",
    type: "autocomplete",
    label: "Tag Product",
    pathname: "product/list",
    filterName: "tagProduct",
    validate: {
      presence: { allowEmpty: false, message: "is required" },
    },
    getOptionLabel: (option: ProductRecord) => option?.name ?? "",
    getOptionSelected: (option: ProductRecord) => option,
    onChange: (event: any, value: ProductRecord, reason: any, field: any) => {
      field.setValue({
        id: value?.id,
        name: value?.name,
      });
    },
    renderOption: (option: ProductRecord) => {
      return <Box>{option.name}</Box>;
    },
    filterByParams: true,
  },
  product_options_id: {
    initialValue: "",
    label: "Tag Product Options (If Applicable)",
    placeholder: "Product options",
    type: "autocomplete",
    pathname: "product-options",
    filterName: "tagProductOptions",
    getOptionLabel: (option: ProductOption) => {
      return option?.name ?? "";
    },
    getModel: (res: any) => res.data?.data,
    getOptionSelected: (option: ProductOption) => option,
    onChange: (event: any, value: ProductOption, reason: any, field: any) => {
      field.setValue({
        id: value?.id,
        name: value?.name,
      });
    },
    renderOption: (option: ProductOption) => {
      return <Box>{option.name}</Box>;
    },
  },
};
