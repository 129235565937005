import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useFilter } from "utils/filter";
import { useDispatch } from "react-redux";
import { Actions } from "store";

export const useGetCustomersDetail = (account_id?: number | string) => {
  const [filter] = useFilter("getCustomersDetail")
  const dispatch = useDispatch();
  const api = useApi();
  const getCustomersDetail = async () => {
    const res = await api
      .path("account/list", {},{...filter, ...(account_id && {account_id: account_id, offset: 0})})
      .get();
    if (res?.data?.status === 0 && account_id) {
      dispatch(Actions.PreFillData.update({key: "customer_detail", value: res?.data?.data?.data[0]}))
    }
    return res?.data?.data
  };

  const { data, isLoading, error, mutate } = useMutation(() => getCustomersDetail());

  return {
    getCustomersDetailData: data,
    getCustomersDetailError: error,
    getCustomersDetailLoading: isLoading,
    getCustomersDetailMutate: mutate,
  };
};
