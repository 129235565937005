import React, { useState, useEffect } from "react";
import { ImageDropzone, InputGroup } from "components";
import { Box, Dialog, IconButton, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import { ButtonConfirm } from "./components";
import { usePostAssetImage } from "./api";
import { UseMutateFunction } from "react-query";

interface Props {
  open: boolean;
  handleClose: () => void;
  reload: () => void;
  handleMutate: (callback: UseMutateFunction<boolean | undefined, unknown, FormData, unknown>, file: File) => void;
  fields?: any;
  dirties?: any;
}

export const PopupDropForm = ({ open, handleClose, handleMutate, reload, fields, dirties }: Props) => {
  const classes = useStyles();
  const [file, setFile] = useState<any>(null);
  const [error, setError] = useState(null);
  const { postImagePreviewMutate, postImagePreviewLoading } = usePostAssetImage(reload, handleClose);
  const handleUpdate = (file: any, isClear = false) => {
    if (isClear) {
      setFile(null);
    } else {
      setFile(file);
    }
  };

  useEffect(() => {
    if (!open) {
      setFile(null);
    }
  }, [open]);

  return (
    <Dialog
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box className={classes.content}>
        <Box className={classes.headerSection}>
          <Typography className={classes.title}>NEW ASSET</Typography>
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon style={{ cursor: "pointer" }} />
          </IconButton>
        </Box>
        {fields && <InputGroup className={classes.inputGroup} fields={fields} />}
        <Box>
          <ImageDropzone
            error={error}
            setError={setError}
            updateLabel="Confirm"
            handleUpdate={handleUpdate}
            shouldClear={false}
          />
        </Box>
        <ButtonConfirm
          disabledSave={(dirties ? !dirties && !dirties?.assoc_type : false) || !file || postImagePreviewLoading}
          justifyContent="center"
          functionCancel={handleClose}
          functionSave={() => {
            handleMutate(postImagePreviewMutate, file);
          }}
        />
      </Box>
    </Dialog>
  );
};
