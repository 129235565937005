import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "45px",
  },
  routeMenu: {
    padding: "0 30px",
    paddingTop: "36px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "18px",
    },
  },
  routeChild: {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "48px",
    color: "#333333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      lineHeight: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "28px",
    },
  },
  routeMenuChild: {
    cursor: "pointer",
  },
  formContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: 16,
    },
  },
  formContainerChild: {
    margin: "0px 30px",
    padding: 30,
    backgroundColor: "#fff",
    [theme.breakpoints.down("xs")]: {
      padding: 16,
    },
  },
  textField: {
    "& legend": {
      display: "none",
    },
    "& .MuiFormLabel-root.MuiInputLabel-shrink": {
      transform: "translate(14px, 5px) scale(0.75)",
      fontWeight: 500,
      lineHeight: "14px",
      color: "#A5A9B9",
    },

    "& textarea.MuiInputBase-input ": {
      height: "40px !important",
    },
  },
  linkRow: {
    marginTop: 16,
    display: "flex",
    alignItems: "flex-end",
  },
  coppyButton: {
    width: 100,
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#0D5AE5",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  subContentContainer: {
    padding: "0px 30px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: 16,
    },
  },
  saveAndCancelContainer: {
    padding: "0 30px 30px 30px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px 16px 16px",
    },
  },
  subContentContainerChild: {
    position: "relative",
    width: "calc(50% - 30px)",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  deleteButton: {
    position: "absolute",
    right: "14px",
    top: "8px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      top: "-10px",
    },
  },
}));
