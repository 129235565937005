import { OptionsStoreUpdateProps, TYPES } from './actions';

export type PropAction = {
  type: string;
  props: any;
};

export type OptionsStoreState = {
  ItemClass: any;
  ClassificationItemClassItemCategory: any;
  ClassificationItemClassItemType: any;
  ItemSubClass: any;
  defaultNull: any;
  ClassificationItemSubClassItemClass: any;
  OptionAddOns: any;
  AccessoriesAddOns: any;
};

const initial_state: OptionsStoreState = {
  defaultNull: [],
  // [{id: 1, items: []}]
  ItemClass: [],
  ClassificationItemClassItemCategory: [],
  ClassificationItemClassItemType: [],
  ItemSubClass: [],
  ClassificationItemSubClassItemClass: [],
  OptionAddOns: [],
  AccessoriesAddOns: [],
};

const optionsStoreReducer = (state: OptionsStoreState = initial_state, actions: PropAction) => {
  switch (actions.type) {
    case TYPES.UPDATE: {
      const updateProps: OptionsStoreUpdateProps = actions.props;
      return {
        ...state,
        [updateProps.key]: updateProps.value,
      };
    }
    default:
      return state;
  }
};

export default optionsStoreReducer;
