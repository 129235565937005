import React, { useState } from "react";
import { ImageDropzone, SaveAndCancel } from "components";
import { Box, Modal } from "@material-ui/core";
import { useStyles } from "./styles";
import { usePostCreateAsset } from "views/Frontend/ListingDetail/apis/usePostCreateAsset";
import { OwnerType } from "types";

interface IProps {
  open: boolean;
  handleClose: () => void;
  id: string;
  reload: Function;
  owner_type: keyof typeof OwnerType;
}

const PopupDropImage = ({
  open,
  handleClose,
  id,
  reload,
  owner_type,
}: IProps) => {

  const classes = useStyles();

  const [file, setFile] = useState<any>(null);

  const [error, setError] = useState(null);

  const { postCreateAssetMutate, postCreateAssetLoading } = usePostCreateAsset({
    reload,
    handleClose,
  });

  const handleUpdate = (file: any, isClear = false) => {
    if (isClear) {
      setFile(null);
    } else {
      setFile(file);
    }
  };

  const handleMutate = () => {
    if (!file.type.match(/image.*/) && !file.type.match(/video.*/))
      throw new Error("file not accepted");
    if (!file) throw new Error("file not found");
    const formData = new FormData();
    formData.append("filename", `${id}-product_detail`);
    formData.append("assoc_type", "product_detail");
    formData.append("id", id);
    formData.append("owner_type", owner_type);
    formData.append("multiple", "false");
    formData.append("file", file);

    postCreateAssetMutate(formData);
  };
  
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box className={classes.content}>
        <ImageDropzone
          error={error}
          setError={setError}
          updateLabel="Confirm"
          handleUpdate={handleUpdate}
          shouldClear={false}
          canUploadVideo={true}
        />
        <SaveAndCancel
          disabledSave={!file || postCreateAssetLoading}
          justifyContent="center"
          functionCancel={handleClose}
          functionSave={handleMutate}
        />
      </Box>
    </Modal>
  );
};

export default PopupDropImage;
