import { useEffect, useState, useRef } from "react";

function useElementWidth() {
  const [elementWidth, setElementWidth] = useState(0);
  const elementRef = useRef<any>(null);

  useEffect(() => {
    const updateWidth = () => {
      if (elementRef.current) {
        const width = elementRef.current.offsetWidth;
        setElementWidth(width);
      }
    };

    // Call the function once to get the initial width
    updateWidth();

    // Attach an event listener to window's resize event to update width on resize
    window.addEventListener("resize", updateWidth);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return [elementRef, elementWidth];
}

export default useElementWidth;
