import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  MenuItem,
  Paper,
  TextField,
  makeStyles,
} from "@material-ui/core";
import IconComponent from "components/IconComponent";
import { useFilter } from "utils/filter";
import useApi from "utils/api/useApi";
import { pascalize } from "utils/formatting";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "11px 13px",
    gap: "10px",
    background: "#FFFFFF",
    border: "1px solid #C9D1D9",
    borderRadius: "5px",
    "& .MuiInputBase-root": {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "14px",
      color: "#A5A9B9",
    },
  },
  textField: {
    minWidth: "122px",
    "& .MuiInputBase-input": {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "14px",
      color: "#A5A9B9",
    },
    "& .MuiInput-underline:after, & .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      gap: "16px",
    },
  },
  typeField: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "14px",
    color: "#A5A9B9",
    "& .MuiInput-underline:after, & .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiSelect-selectMenu": {
      lineHeight: "initial",
    },
  },
  placeholder: {
    "&::placeholder": {
      fontWeight: 500,
      fontSize: "14px",
      color: "#A5A9B9",
    },
  },
  divider: {
    backgroundColor: "#D0D7DB",
    height: "20px",
  },
  autoComplete: {
    minWidth: "122px",
    "& .MuiInputBase-input": {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "14px",
      color: "#A5A9B9",
      padding: "0!important",
    },
    "& .MuiInput-underline:after, & .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      gap: "16px",
      padding: "0!important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
    "& .MuiIconButton-root, & .MuiSvgIcon-root": {
      color: "#A5A9B9",
      fill: "#A5A9B9",
    },
  },
  dropdownMenu: {
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    marginTop: "4px",
    width: "fit-content",
  },
  dropdownOption: {
    width: "fit-content",
    whiteSpace: "nowrap",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "14px",
  },
}));

export const SearchByType = (props) => {
  const classes = useStyles();
  const { field } = props;
  const api = useApi();
  // State to handle loading when calling the get options
  const [loading, setLoading] = useState(false);
  // Options state
  const [model, setModel] = useState();
  // Type state
  const [filterType, setFilterType] = useState(field.spec.types[0]);
  // Pathname
  const pathName = field.spec.formatPathName(filterType);
  // Filter use for call api get options
  // eslint-disable-next-line no-unused-vars
  const [filter, updateFilter, listenFilters, parsedFilter] = useFilter(
    field.spec.formatFilterKey(filterType) || "product"
  );
  const componentMounted = useRef(true); //component is mounted

  // Call api to get options
  const getData = async () => {
    setLoading(true);
    const result = await api.path(pathName, {}, { ...parsedFilter }).get();
    setLoading(false);
    if (result?.data?.data?.data) {
      // Obtain the model when the response is different
      if (field.spec?.getModel) {
        setModel(field.spec?.getModel(result));
        return;
      }
      setModel(result?.data?.data?.data);
    }
  };

  // Prevent call api get options at the first render
  useEffect(() => {
    if (componentMounted.current) {
      componentMounted.current = false;
      return;
    }
    getData();
  }, [filter]); // eslint-disable-line
  const timeoutID = useRef();

  // Handle on change options
  const handleOnChange = (event, value, reason) => {
    if (!value) {
      updateFilter({ ...filter, search: undefined });
    }
    field.spec.onChange(event, value, reason, field, pathName);
  };

  // Handle the change in the search input
  const handleInputChange = useCallback(
    (event, value, reason) => {
      if (timeoutID.current) {
        clearTimeout(timeoutID.current);
      }
      // Debounce the API call to get options by 1 second
      timeoutID.current = setTimeout(() => {
        updateFilter({
          ...filter,
          ...(field?.spec?.searchKey && field?.spec?.queryKeyword
            ? {
                [field.spec.searchKey]: value
                  ? `${field.spec.queryKeyword + ":" + value}`
                  : "",
              }
            : { search: value }),
        });
      }, 1000);
    },
    [field.spec.queryKeyword, field.spec.searchKey, filter, updateFilter]
  );
  return (
    <Box className={classes.root}>
      <Autocomplete
        className={classes.autoComplete}
        value={field?.value || null}
        defaultValue={field.spec.initialValue}
        fullWidth
        disabled={field.spec.disabled}
        autoHighlight
        loading={loading}
        options={model || []}
        getOptionLabel={(option) => field?.spec?.getOptionLabel(option, model)}
        getOptionSelected={field?.spec?.getOptionSelected}
        filterOptions={(options, state) => options}
        onChange={handleOnChange}
        onFocus={() => {
          !model && getData();
        }}
        PaperComponent={({ children }) => (
          <Paper className={classes.dropdownMenu}>{children}</Paper>
        )}
        renderOption={(option, value) => (
          <li className={classes.dropdownOption}>
            {field.spec.renderOption(option, value)}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.textField}
            label={field.spec.label}
            placeholder={field.spec.placeholder}
            onBlur={field.onBlur}
            error={field.dirty && !!field.error}
            helperText={field.dirty && field.error}
            onChange={(e) => handleInputChange(e, e.target.value)}
            InputProps={{
              ...params.InputProps,
              classes: {
                input: classes.placeholder,
              },
              startAdornment: <IconComponent name="filter" />,
            }}
          />
        )}
      />
      <Divider orientation="vertical" className={classes.divider} />
      <TextField
        className={classes.typeField}
        fullWidth
        disabled={false}
        value={filterType}
        placeholder="Search Fields"
        onChange={(e) => {
          setFilterType(e.target.value);
        }}
        select
      >
        {field.spec.types.map((each, id) => {
          return (
            <MenuItem value={each} key={id}>
              {pascalize(each)}
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
};
