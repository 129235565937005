import { FilterUpdateProps, FilterReloadProps, TYPES } from "./actions";
import { PropAction } from "../props";
import { Content, PublishedNumberType } from "types";

const initial_substate = {
  limit: 20,
  count: 0,
  offset: 0,
};

const initial_get_banner = {
  bannerType: "",
  sort: "created_at:ASC",
};

export type FilterState = {
  default: any;
  customers: any;
  order_history: any;
  order_items: any;
  orders: any;
  products: any;
  product: any;
  classifications: any;
  subClassifications: any;
  frequentlyAskedQuestions: any;
  productTypeReviews: any;
  allReviews: any;
  inventory: any;
  classificationCharacter: any;
  classificationCategory: any;
  classificationProductType: any;
  classificationProduct: any;
  point_history: any;
  pages: any;
  pageElements: any;
  navigation: any;
  promotionGroups: any;
  promotionProducts: any;
  promotionProductsHomeNull: any;
  // productGroups: any;
  vouchers: any;
  qa: any;
  banner: any;
  getProduct: any;
  getCampaign: any;
  getProductType: any;
  getCustomersDetail: any;
  getCustomerOrders: any;
  vouchersIssued: any;
  getItemClass: any;
  listing: any;
  getListing: any;
  getProductList: any;
  getProductGiftList: any;
  getProductListSearch: any;
  getClassificationItemClass: any;
  getClassificationItemSubClass: {
    limit: number;
    offset: number;
    search?: string;
    sort?: string;
  };
  getClassificationItemCategory: {
    limit: number;
    offset: number;
    search?: string;
    sort?: string;
  };
  getClassificationItemType: {
    limit: number;
    offset: number;
    search?: string;
    sort?: string;
  };
  ClassificationItemClassItemCategory: any;
  ClassificationItemClassItemType: any;
  ClassificationItemSubClassItemClass: any;
  ItemType: any;
  ItemCategory: any;
  ItemClass: any;
  ItemSubClass: any;
  ItemGroup: any;
  Color: any;
  Group: any;
  IsGift: any;
  HasGift: any;
  IsAccessory: any;
  HasAccessory: any;
  Listing: any;
  BannerGroup: any;
  LandingPage: any;
  review: any;
  reviewSearch: any;
  QA: any;
  QASearch: any;
  getPromotionProducts: any;
  getAllPromotionProducts: any;
  getBundleProducts: any;
  getAllBundleProducts: any;
  getPromotionSummary: any;
  OptionAddOns: any;
  AccessoriesAddOns: any;
  getPromotion: any;
  SearchFromVMOS: any;
  Vouchers: any;
  promotions: any;
  getVmosCatalogue: any;
  getAccount: any;
  VouchersIssued: any;
  listingReviewTabGetProduct: any;
  tagProduct: any;
  tagProductOptions: any;
  Webpages: any;
  Asset: any;
  LandingPageV2: {
    limit?: number;
    offset?: number;
    sort?: string;
    content?: Content;
  };
  LadingPageListing: {
    limit: number;
    offset: number;
    search?: string;
    sort?: string;
    published?: PublishedNumberType;
  };
  getAllConditionPromotionProducts: {
    limit: number;
    offset: number;
    sortBy: string;
    sort: string;
  };
  collection: {
    limit: number;
    offset: number;
    search?: string;
    sortBy: string;
    sort: string;
  };
  collectionAsset: {
    limit: number;
    offset: number;
    owner_type: string;
    assoc_type: string;
    sortBy: string;
    sort: string;
  };
  collectionProduct: {
    limit: number;
    offset: number;
    status: "published" | "unpublished";
    sort: string;
    collection_id?: number;
  };
  collectionAllProduct: {
    limit: number;
    offset: number;
    status: "published" | "unpublished";
    sort?: string;
  };
  promotionAllProduct: {
    limit: number;
    offset: number;
    sort?: string;
    sortBy?: string;
  };
  promotionAllBundleProduct: {
    limit: number;
    offset: number;
    sort?: string;
    sortBy?: string;
  };
  promotionAsset: {
    limit: number;
    offset: number;
    owner_type: string;
    assoc_type: string;
    sortBy: string;
    sort: string;
  };
  getClassificationItemBrand: {
    limit: number;
    offset: number;
    search?: string;
    sort?: string;
  };
  brandAsset: {
    limit: number;
    offset: number;
    owner_type: string;
    assoc_type?: string;
    sortBy?: string;
    sort?: string;
  };
  itemClassAsset: {
    limit: number;
    offset: number;
    owner_type: string;
    assoc_type?: string;
    sortBy?: string;
    sort?: string;
  };
  itemTypeAsset: {
    limit: number;
    offset: number;
    owner_type: string;
    assoc_type?: string;
    sortBy?: string;
    sort?: string;
  };
  itemCategoryAsset: {
    limit: number;
    offset: number;
    owner_type: string;
    assoc_type?: string;
    sortBy?: string;
    sort?: string;
  };
  itemSubClassAsset: {
    limit: number;
    offset: number;
    owner_type: string;
    assoc_type?: string;
    sortBy?: string;
    sort?: string;
  };
  getClassificationItemGroup: {
    limit: number;
    offset: number;
    search?: string;
    sort?: string;
  };
  itemGroupAsset: {
    limit: number;
    offset: number;
    owner_type: string;
    assoc_type?: string;
    sortBy?: string;
    sort?: string;
  };
};

const initial_state: FilterState = {
  default: {},
  customers: {
    ...initial_substate,
    sort: "firstname:DESC",
    search: "",
    reload: false,
  },
  order_history: {
    ...initial_substate,
    sort: "",
    search: "",
    filterBy: "",
    account_id: 0,
  },
  order_items: {
    count: 0,
    sort: "",
    search: "",
    filterBy: "",
    reload: false,
    error: false,
    success: false,
  },
  orders: {
    ...initial_substate,
    sort: "DESC",
    search: "",
    sortBy: "created_at",
    status: "",
    created_at: "",
    error: false,
    success: false,
    warning: false,
  },
  products: { ...initial_substate, limit: 20, sort: "id:desc", search: "" },
  product: {
    ...initial_substate,
    limit: 10,
    sort: "",
    search: "",
    reload: false,
  },
  classifications: {
    ...initial_substate,
    sort: "",
    search: "",
    filterBy: "",
    type: "",
    reload: false,
  },
  subClassifications: {
    ...initial_substate,
    sort: "",
    search: "",
    filterBy: "",
    type: "",
    reload: false,
  },
  productTypeReviews: {
    ...initial_substate,
    product_type_id: null,
    sort: "",
    search: "",
    status: "",
    created_at: "",
    score: "",
    reload: false,
  },
  allReviews: {
    ...initial_substate,
    sort: "",
    search: "",
    status: "all",
    create_at: "",
    score: "all",
    reload: false,
  },
  frequentlyAskedQuestions: {
    ...initial_substate,
    search: "",
    sort: "",
    status: "",
    reload: false,
  },
  inventory: {
    ...initial_substate,
    sort: "",
    search: "",
    filterBy: "",
    type: "",
    reload: false,
    inventory: null,
  },
  classificationCharacter: {
    ...initial_substate,
    sort: "",
    search: "",
    filterBy: "",
    type: "character",
    published: "",
    reload: false,
  },
  classificationCategory: {
    ...initial_substate,
    sort: "",
    search: "",
    filterBy: "",
    type: "category",
    published: "",
    reload: false,
  },
  classificationProductType: {
    ...initial_substate,
    sort: "",
    search: "",
    published: "",
    reload: false,
  },
  classificationProduct: {
    ...initial_substate,
    sort: "",
    search: "",
    filterBy: "",
    reload: false,
  },
  point_history: {
    ...initial_substate,
    sort: "",
    search: "",
    filterBy: "",
    reload: false,
    campaign: null,
  },
  pages: { ...initial_substate, sort: "", search: "", reload: false },
  pageElements: {
    ...initial_substate,
    sort: "",
    search: "",
    reload: false,
    webpage: null,
  },
  navigation: { ...initial_substate, sort: "", search: "", reload: false },
  promotionGroups: {
    ...initial_substate,
    sort: "",
    search: "",
    reload: false,
    type: "accessory",
    published: 1,
  },
  promotionProducts: {
    ...initial_substate,
    sort: "",
    home_feature_id: "notnull",
    published: "all",
  },
  promotionProductsHomeNull: {
    ...initial_substate,
    sort: "",
    home_feature_id: "null",
    published: "all",
  },
  vouchers: { ...initial_substate, sort: "", search: "", published: "all" },
  qa: {
    ...initial_substate,
    sort: "",
    search: "",
    status: "all",
    created_at: "",
    reload: false,
  },
  banner: { ...initial_get_banner },
  getProduct: { search: "", sort: "id:ASC", limit: 10, offset: 0 },
  getProductType: {
    search: "",
    sort: "name:ASC",
    limit: 10,
    offset: 0,
    published: "",
  },
  getCampaign: {
    id: "",
    name: "",
    campaign_type: "",
    start_at: "",
    end_at: "",
    status: "",
    sortBy: "start_at",
    sort: "DESC",
    limit: 10,
    offset: 0,
  },
  getCustomersDetail: {
    limit: 20,
    offset: 0,
    sort: "id:desc",
    account_id: "",
    search: "",
  },
  getCustomerOrders: {
    limit: 10,
    offset: 0,
    sortBy: "created_at",
    sort: "DESC",
    account_id: "",
    status: "",
    created_at: "",
  },
  vouchersIssued: { ...initial_substate, sortBy: "created_at", sort: "DESC" },
  getItemClass: { limit: 10, offset: 0, sort: "name:ASC", search: "" },
  listing: {
    limit: 20,
    offset: 0,
    sort: "",
    published: undefined,
  },
  getListing: { limit: 20, offset: 0, sort: "", search: "" },
  getProductList: {
    limit: 20,
    offset: 0,
    listing_id: undefined,
    search: undefined,
    status: undefined,
    sort: "id:ASC",
  },
  getProductGiftList: {
    limit: 20,
    offset: 0,
    listing_id: undefined,
    search: undefined,
    status: undefined,
    sort: "id:ASC",
  },
  getProductListSearch: {
    limit: 20,
    offset: 0,
    listing_id: undefined,
    search: undefined,
    status: undefined,
    sort: undefined,
  },
  getClassificationItemClass: {
    limit: 20,
    offset: 0,
    search: undefined,
    sort: "id:DESC",
    published: undefined,
  },
  getClassificationItemSubClass: {
    limit: 20,
    offset: 0,
    search: undefined,
    sort: "id:DESC",
  },
  getClassificationItemCategory: {
    limit: 20,
    offset: 0,
    search: undefined,
    sort: "id:DESC",
  },
  getClassificationItemType: {
    limit: 20,
    offset: 0,
    sort: "id:DESC",
  },
  LandingPage: {
    limit: 20,
    offset: 0,
    search: undefined,
    sort: "created_at:DESC",
  },
  ClassificationItemClassItemCategory: {
    limit: 100,
    offset: 0,
    search: undefined,
    sort: "id:ASC",
  },
  ClassificationItemClassItemType: {
    limit: 100,
    offset: 0,
    search: undefined,
    sort: "id:ASC",
  },
  ClassificationItemSubClassItemClass: {
    limit: 100,
    offset: 0,
    search: undefined,
    sort: "id:ASC",
  },
  ItemType: { limit: 100, offset: 0, search: undefined, sort: "id:ASC" },
  ItemCategory: { limit: 100, offset: 0, search: undefined, sort: "id:ASC" },
  ItemClass: { limit: 100, offset: 0, search: undefined, sort: "id:ASC" },
  ItemSubClass: { limit: 100, offset: 0, search: undefined, sort: "id:ASC" },
  ItemGroup: { limit: 100, offset: 0, search: undefined, sort: "id:ASC" },
  Color: {
    limit: 100,
    offset: 0,
    search: undefined,
    sort: "ASC",
    sortBy: "id",
  },
  IsGift: {
    limit: 100,
    offset: 0,
    search: undefined,
    sort: "ASC",
    sortBy: "id",
  },
  HasGift: {
    limit: 100,
    offset: 0,
    search: undefined,
    sort: "ASC",
    sortBy: "id",
  },
  IsAccessory: {
    limit: 100,
    offset: 0,
    search: undefined,
    sort: "ASC",
    sortBy: "id",
  },
  HasAccessory: {
    limit: 100,
    offset: 0,
    search: undefined,
    sort: "ASC",
    sortBy: "id",
  },
  Group: { limit: 100, offset: 0, search: undefined, sort: "id:ASC" },
  Listing: { limit: 100, offset: 0, search: undefined, sort: "id:ASC" },
  BannerGroup: {
    limit: 100,
    offset: 0,
    published: "all",
    search: undefined,
    sort: "id:ASC",
  },
  review: {
    limit: 20,
    offset: 0,
    sortBy: "created_at",
    search: undefined,
    sort: "DESC",
  },
  reviewSearch: {
    limit: 20,
    offset: 0,
    sortBy: "created_at",
    search: undefined,
    sort: "DESC",
  },
  QA: {
    limit: 20,
    offset: 0,
    search: undefined,
    sort: "id:desc",
  },
  QASearch: {
    status: "all",
    limit: 20,
    offset: 0,
    search: undefined,
    sort: "id:ASC",
  },
  getPromotionProducts: {
    limit: 10,
    offset: 0,
    sortBy: "created_at",
    sort: "DESC",
  },
  getAllPromotionProducts: {
    limit: 10,
    offset: 0,
    sortBy: "created_at",
    sort: "DESC",
  },
  getBundleProducts: {
    limit: 10,
    offset: 0,
    sortBy: "created_at",
    sort: "DESC",
  },
  getAllBundleProducts: {
    limit: 10,
    offset: 0,
    sortBy: "created_at",
    sort: "DESC",
  },
  getPromotionSummary: {
    limit: 20,
    offset: 0,
    sortBy: "created_at",
    sort: "DESC",
    ended: false,
  },
  OptionAddOns: { product_id: null },
  AccessoriesAddOns: { limit: 10, offset: 0, is_accessory: 0 },
  getPromotion: {
    limit: 20,
    offset: 0,
    sortBy: "created_at",
    sort: "DESC",
    ended: false,
  },
  SearchFromVMOS: { limit: 50, offset: 0, search: undefined },
  Vouchers: {
    limit: 20,
    offset: 0,
    sort: "DESC",
    sortBy: "id",
    published: true,
  },
  promotions: {
    limit: 20,
    offset: 0,
    sortBy: "created_at",
    sort: "DESC",
    ended: false,
  },
  getVmosCatalogue: {
    limit: 20,
    offset: 0,
    sortBy: "",
    sort: "",
  },
  getAccount: {
    limit: 20,
    offset: 0,
    search: "",
    sort: "id:desc",
  },
  VouchersIssued: {
    limit: 10,
    offset: 0,
    sort: "DESC",
    sortBy: "created_at",
    published: true,
  },
  listingReviewTabGetProduct: {
    limit: 50,
    offset: 0,
    sort: "id:desc",
    status: "published",
  },
  tagProduct: {
    limit: 50,
    offset: 0,
    sort: "id:desc",
    status: "published",
  },
  tagProductOptions: {
    product_id: null,
  },
  Webpages: {
    limit: 20,
    offset: 0,
    sortBy: "created_at",
    sort: "DESC",
  },
  Asset: {
    limit: 20,
    offset: 0,
    sortBy: "created_at",
    sort: "DESC",
    owner_type: "webpage",
  },
  LandingPageV2: {
    limit: 20,
    offset: 0,
    sort: "id:DESC",
  },
  LadingPageListing: {
    limit: 100,
    offset: 0,
    search: undefined,
    sort: "id:ASC",
    published: PublishedNumberType.published,
  },
  getAllConditionPromotionProducts: {
    limit: 10,
    offset: 0,
    sortBy: "created_at",
    sort: "DESC",
  },
  collection: {
    limit: 20,
    offset: 0,
    sortBy: "created_at",
    sort: "DESC",
  },
  collectionAsset: {
    limit: 20,
    offset: 0,
    owner_type: "collection",
    assoc_type: "collection_thumbnail",
    sortBy: "created_at",
    sort: "DESC",
  },
  collectionProduct: {
    limit: 20,
    offset: 0,
    status: "published",
    sort: "id:desc",
  },
  collectionAllProduct: {
    limit: 20,
    offset: 0,
    status: "published",
    sort: "id:desc",
  },
  promotionAllProduct: {
    limit: 10,
    offset: 0,
    sort: "ASC",
    sortBy: "id",
  },
  promotionAllBundleProduct: {
    limit: 10,
    offset: 0,
    sort: "ASC",
    sortBy: "id",
  },
  promotionAsset: {
    limit: 20,
    offset: 0,
    owner_type: "promotion",
    assoc_type: "banner",
    sortBy: "created_at",
    sort: "DESC",
  },
  getClassificationItemBrand: {
    limit: 20,
    offset: 0,
    sort: "id:DESC",
  },
  brandAsset: {
    limit: 20,
    offset: 0,
    owner_type: "item_brand",
    sortBy: "created_at",
    sort: "DESC",
  },
  itemClassAsset: {
    limit: 20,
    offset: 0,
    owner_type: "item_class",
    sortBy: "created_at",
    sort: "DESC",
  },
  itemTypeAsset: {
    limit: 20,
    offset: 0,
    owner_type: "item_type",
    sortBy: "created_at",
    sort: "DESC",
  },
  itemCategoryAsset: {
    limit: 20,
    offset: 0,
    owner_type: "item_category",
    sortBy: "created_at",
    sort: "DESC",
  },
  itemSubClassAsset: {
    limit: 20,
    offset: 0,
    owner_type: "item_sub_class",
    sortBy: "created_at",
    sort: "DESC",
  },
  getClassificationItemGroup: {
    limit: 20,
    offset: 0,
    sort: "id:DESC",
  },
  itemGroupAsset: {
    limit: 20,
    offset: 0,
    owner_type: "item_group",
    sortBy: "created_at",
    sort: "DESC",
  },
};

export default (state: FilterState = initial_state, actions: PropAction) => {
  switch (actions.type) {
    case TYPES.RELOAD:
      const reloadProps: FilterReloadProps = actions.props;
      const getKeyValue =
        <U extends keyof T, T extends object>(key: U) =>
        (obj: T) =>
          obj[key];
      const value = getKeyValue<keyof FilterState, FilterState>(reloadProps.key)(state);
      return {
        ...state,
        [reloadProps.key]: { ...value, reload: !value.reload },
      };
    case TYPES.UPDATE:
      const updateProps: FilterUpdateProps = actions.props;
      return {
        ...state,
        [updateProps.key]: updateProps.value,
      };
    default:
      return state;
  }
};
