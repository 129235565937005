import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";

enum type {
  percent = "percent",
  gift = "gift",
  value = "value"
}

enum Published {
  one = "1",
  zero = "0"
}

interface PatchBody {
  name?: string;
  discount_value?: string | number;
  discount_type?: type;
  start_at?: string;
  end_at?: string;
  description?: string;
  published?: Published;
}

interface Body {
  body: PatchBody,
  id: number | string;
}

export const usePatchGroupId = () => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const patchGroup = async (body: Body) => {
    const res = await api
      .path("group/promotion_group/patch", {id: body.id})
      .patch({ body: body.body });
    if (res?.data?.status === 0) {
      enqueueSnackbar("Update group created!", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(`Update group faiiled! ${res?.data?.message}`, {
        variant: "error",
      });
    }
    return res?.data?.data
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => patchGroup(body));

  return {
    patchGroupIdData: data,
    patchGroupIdError: error,
    patchGroupIdLoading: isLoading,
    patchGroupIdMutate: mutate,
  };
};
