import { makeStyles, Box, IconButton, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import Slider from "react-slick";
import { ReactComponent as ArrowLeft } from "assets/images/icons/arrow_left.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow_right.svg";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles(theme => ({
  slick: {
    margin: "0 30px",
    padding: "21px 130px",
    backgroundColor: "#fff",
    position: "relative",
    "& .slick-list": {
      padding: "0px!important",
    },
    "& .slick-prev:before": {
      display: "none",
    },
    "& .slick-next:before": {
      display: "none",
    },
    "& .slick-slide": {
      margin: "0 18px",
    },
    "& .slick-slide div": {
      outline: "none",
      padding: "13px",
    },
    "& .slick-track": {
      display: "flex",
    },
    "& .slick-next": {
      right: "35px",
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
    "& .slick-prev": {
      left: "35px",
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 30px",
      margin: "18px 30px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px",
    },
  },
  imageWrapper: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0",
    },
  },
  deleteButton: {
    position: "absolute",
    right: "-14px",
    top: "-8px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      top: "-10px",
    },
  },
  title: {
    fontWeight: 900,
    margin: "0 30px",
    paddingTop: "30px",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#333333",
    backgroundColor: "#fff",
  },
  with50: {
    width: "50%",
    maxWidth: "300px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  padding0: {
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px",
    },
    "& .slick-slide": {
      margin: "0",
    },
    "& .slick-slide div": {
      outline: "none",
      padding: "0",
      paddingTop: "10px",
    },
  },
}));

export interface Product {
  id: number;
  owner_type?: string;
  owner_id?: string | number;
  assoc_type?: string;
  filename?: string;
  host_key?: string;
  uri?: string;
  name?: string;
  title?: string;
  description?: string;
  cta_text?: string;
  layout_type?: number;
  group_id?: number;
  cta_link?: string;
}

interface IProps {
  products: Product[];
  setImage?: Function;
  handleDelete: Function;
  title?: string;
  show1Slide?: boolean;
}

const SlickSlider = (props: IProps) => {
  const classes = useStyles();
  const { products, setImage, handleDelete, title, show1Slide } = props;

  const settings = {
    dots: false,
    slidesToShow: show1Slide ? 1 : 4,
    slidesToScroll: 1,
    infinite: products.length > 4,
    swipeToSlide: true,
    autoplay: false,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: show1Slide ? 1 : 3,
          infinite: products.length > 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: show1Slide ? 1 : 2,
          infinite: products.length > 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          infinite: products.length > 1,
        },
      },
    ],
  };

  return (
    <Box className={show1Slide ? classes.with50 : ""}>
      {title && <Typography className={classes.title}>{title}</Typography>}
      <Slider className={clsx(classes.slick, show1Slide && classes.padding0)} {...settings}>
        {products?.map((item, index) => {
          return (
            <Box key={index} className={classes.imageWrapper}>
              <IconButton className={classes.deleteButton} onClick={() => handleDelete(item.id)}>
                <img src="/images/buttonIcons/Delete_image_btn.png" alt="" />
              </IconButton>
              <img
                onClick={() => {
                  if (setImage) {
                    setImage(item);
                  }
                }}
                src={item?.uri}
                alt=""
                style={{ width: "100%", border: "1px solid #ccc" }}
              />
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

export default SlickSlider;
