import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { Actions } from "store";

enum Status {
  Published = 1,
  Unpublished = 0,
}

interface Payload {
  name?: string;
  description?: string;
  meta_keywords?: string | number;
  meta_title?: string | number;
  max_age?: number | string;
  min_age?: string | number;
  published?: Status;
}

interface Body {
  payload: Payload;
  id: number | string;
}

export const usePatchProductTypeDetail = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const patchProductTypeDetail = async (body: Body) => {
    const res = await api
      .path("item_class/update-detail", { item_class_id: body.id })
      .patch({body: body.payload});
    if (res?.data?.status === 0) {
      enqueueSnackbar("Update item successfully!", {
        variant: "success",
      });
      dispatch(Actions.PreFillData.update({ key: 'product_type_detail', value: res?.data?.data }));
    } else {
      enqueueSnackbar(`Update item faiiled! ${res?.data?.message}`, {
        variant: "error",
      });
    }
  };

  const { isLoading, error, mutate } = useMutation((body: Body) =>
    patchProductTypeDetail(body)
  );

  return {
    patchProductTypeDetailError: error,
    patchProductTypeDetailLoading: isLoading,
    patchProductTypeDetailMutate: mutate,
  };
};
