import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { Actions } from "store";

export const useGetLandingPageDetail = (id: number) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();
  const getLandingPage = async () => {
    try {
      const res = await api
        .path("landing-page/detail", { id: id }, {})
        .get();
      if (res?.data?.status === 0) {
        dispatch(
          Actions.BannerList.update({
            key: "landing_page_detail",
            value: res?.data?.data,
          })
        );
      }
      return res?.data?.data;
    } catch (error) {
      enqueueSnackbar("Got error when try to get landingPage data");
    }
  };

  const { data, isLoading, error, mutate } = useMutation(() => getLandingPage());

  return {
    getLandingPageData: data,
    getLandingPageError: error,
    getLandingPageLoading: isLoading,
    getLandingPageMutate: mutate,
  };
};
