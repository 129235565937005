import { BannerListUpdateProps, TYPES } from "./actions";


export type PropAction = {
  type: string;
  props: any;
};

export type BannerListState = {
  home_banner_1: any;
  landing_page_detail: any;
};

const initial_state: BannerListState = {
  home_banner_1: {
    home_banner_1_mobile: [],
    home_banner_1_desktop: [],
  },
  landing_page_detail: {
    desktop_data: [],
    mobile_data: []
  }
};

const bannerListReducer = (state: BannerListState = initial_state, actions: PropAction) => {
  switch (actions.type) {
    case TYPES.UPDATE: {
      const updateProps: BannerListUpdateProps = actions.props;
      return {
        ...state,
        [updateProps.key]: updateProps.value,
      };
    }
    default:
      return state;
  }
};

export default bannerListReducer;
