export const TYPES = {
  UPDATE: 'OPTIONS_STORE_UPDATE',
};

export type OptionsStoreKeys =
  | 'defaultNull'
  | 'ItemClass'
  | 'ClassificationItemClassItemCategory'
  | 'ClassificationItemClassItemType'
  | 'ItemSubClass'
  | 'ClassificationItemSubClassItemClass'
  | 'OptionAddOns'
  | 'AccessoriesAddOns';

export type OptionsStoreUpdateProps = {
  key: OptionsStoreKeys;
  value: any;
};

export function update(props: OptionsStoreUpdateProps) {
  return {
    type: TYPES.UPDATE,
    props,
  };
}
