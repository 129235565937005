import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";
import { useEffect } from "react";

interface PatchBody {
  status: string;
}

interface Body {
  body: PatchBody,
  id: number | string;
}

export const usePatchCustomerOrder = () => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const patchCustomerOrder = async (body: Body) => {
    const res = await api
      .path("order/update-by-id", {id: body.id})
      .patch({ body: body.body });
    if (res?.data?.status === 0) {
      enqueueSnackbar(`Update order ${body.id} created!`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(`Update order ${body.id} failed! ${res?.data?.message}`, {
        variant: "error",
      });
    }
    return res?.data?.data
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => patchCustomerOrder(body));

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Update order failed!`, {
        variant: "error",
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    patchCustomerOrderData: data,
    patchCustomerOrderError: error,
    patchCustomerOrderLoading: isLoading,
    patchCustomerOrderMutate: mutate,
  };
};
