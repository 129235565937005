export default {
  root: {
    "& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
  }
};
