export const TYPES = {
  UPDATE: "DATA_LIST_UPDATE",
};

export type DataListKeys =
  | "listing"
  | "listingDetail"
  | "productList"
  | "productListSearch"
  | "getClassificationItemType"
  | "getReview"
  | "getReviewSearch"
  | "getQA"
  | "getQASearch"
  | "getProductList"
  | "getProductGiftList"
  | "vouchersIssuedListing";
export type DataListUpdateProps = {
  key: DataListKeys;
  value: any;
};

export function update(props: DataListUpdateProps) {
  return {
    type: TYPES.UPDATE,
    props,
  };
}
