import React, { useEffect, useLayoutEffect, useState, useMemo } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import { useRouter } from 'utils/tools';
import clsx from 'clsx';
import { InputGroup, SaveAndCancel } from 'components';
import useFormHandler from 'utils/forms/useFormHandler';
import { formConfig } from './configs';
import { useSnackbar } from 'notistack';
import { useGetLandingPageDetail, usePatchLandingPageBanner } from '../apis';
import { SubContent } from './components';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

const Banner1 = () => {
  const classes = useStyles();
  const router = useRouter();
  const havePromo = useMemo(
    () => ['banner_1', 'item_carousel_1', 'item_carousel_2'].includes(router.match.params.landing_page_content),
    [router]
  );
  const havePublishedAndAllowNullDesAndTitle = useMemo(
    () => ['landing_page_carousel'].includes(router.match.params.landing_page_content),
    [router]
  );
  const isShopByAge = useMemo(() => router.match.params.landing_page_content === 'shop_by_age', [router]);
  const isItemCarousel = useMemo(
    () => ['item_carousel_1', 'item_carousel_2'].includes(router.match.params.landing_page_content),
    [router]
  );
  const { enqueueSnackbar } = useSnackbar();
  const [selectedLayout, setSelectedLayout] = useState({
    desktop: 0,
    mobile: 0,
  });
  const [files, setFiles] = useState({
    mobile: '',
    desktop: '',
  });
  const { getLandingPageLoading, getLandingPageMutate } = useGetLandingPageDetail(router.match.params.id);
  const { patchLandingPageMutate } = usePatchLandingPageBanner(getLandingPageMutate);
  const { desktop_data, mobile_data } = useSelector((state: RootState) => state.bannerListReducer.landing_page_detail);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fields, inputs, errors, setInputs, setErrors, setDirtys, dirtys] = useFormHandler(
    formConfig({
      havePromo,
      havePublishedAndAllowNullDesAndTitle,
    })
  );

  const handleCopyLink = () => {
    if (inputs?.cta_link) {
      navigator.clipboard.writeText(inputs?.cta_link ?? '');
      enqueueSnackbar('Copied to clipboard!', {
        variant: 'success',
      });
    }
  };

  const handleSave = (deletedAt = 0, deleteType = '') => {
    let formData = new FormData();
    formData.append('id', router.match.params.id);
    formData.append('title', inputs?.title ?? '');
    formData.append('description', inputs?.description ?? '');
    formData.append('cta_text', inputs?.cta_text ?? '');
    formData.append('cta_link', inputs?.cta_link ?? '');
    formData.append('published', inputs?.published === 'Published' ? '1' : '0');
    if (isShopByAge) {
      formData.append('desktop_layout_type', '1');
      formData.append('mobile_layout_type', '1');
    } else {
      selectedLayout.desktop && formData.append('desktop_layout_type', selectedLayout.desktop.toString());
      selectedLayout.mobile && formData.append('mobile_layout_type', selectedLayout.mobile.toString());
    }
    formData.append('promo_id', inputs?.promotion?.id || 0);
    if (files.mobile && !deleteType) {
      formData.append('asset_id_mobile', mobile_data[0]?.asset_id ?? '');
      formData.append('banner_mobile', files.mobile);
    }
    if (files.desktop && !deleteType) {
      formData.append('asset_id_desktop', desktop_data[0]?.asset_id ?? '');
      formData.append('banner_desktop', files.desktop);
    }
    if (deletedAt && deleteType) {
      if (deleteType === 'mobile') {
        formData.append('asset_id_mobile', mobile_data[0]?.asset_id ?? '');
      } else {
        formData.append('asset_id_desktop', desktop_data[0]?.asset_id ?? '');
      }
      formData.append('deletedAt', '1');
    }
    patchLandingPageMutate(formData);
  };

  useLayoutEffect(() => {
    getLandingPageMutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!inputs?.promotion || (Object.keys(inputs?.promotion)?.length === 0 && havePromo)) {
      setInputs({
        ...inputs,
        cta_link: '',
      });
      return;
    }
    if (havePromo) {
      setInputs({
        ...inputs,
        cta_link: `${process.env.REACT_APP_HOST}/promotion/${inputs.promotion.handle}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs?.promotion]);

  const handlePreFill = () => {
    const fillInData = desktop_data[0];
    setSelectedLayout({
      desktop: desktop_data[0]?.layout_type,
      mobile: mobile_data[0]?.layout_type,
    });
    setInputs({
      promotion: {
        id: fillInData?.promo_id,
        name: fillInData?.promotion_name,
        handle: fillInData?.promotion_handle,
      },
      title: fillInData?.title ?? '',
      description: fillInData?.description ?? '',
      cta_text: fillInData?.cta_text ?? '',
      cta_link: fillInData?.cta_link ?? '',
      published: fillInData?.published === 1 ? 'Published' : 'Unpublished',
    });
  };

  useEffect(() => {
    handlePreFill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktop_data]);

  return (
    <Box className={classes.root}>
      <Box className={classes.routeMenu}>
        <Typography
          onClick={() => router.history.push('/frontend/homepage')}
          className={clsx(classes.routeChild, classes.routeMenuChild)}>
          Home Page
        </Typography>
        <Typography className={classes.routeChild}>&nbsp;{'>'}&nbsp;</Typography>
        <Typography className={classes.routeChild}>{router.match.params.landing_page_content}</Typography>
      </Box>
      {getLandingPageLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box className={classes.formContainer}>
            <Box className={classes.formContainerChild}>
              <InputGroup className={classes.textField} fields={havePromo ? fields.slice(0, 4) : fields.slice(1, 4)} />
              <Box className={classes.linkRow}>
                <InputGroup className={classes.textField} fields={fields.slice(4, 5)} />
                <Box className={classes.coppyButton} onClick={() => handleCopyLink()}>
                  <img alt="" src="/images/copy_link_icon.svg" />
                  Copy Link
                </Box>
              </Box>
              <Box className={classes.linkRow}>
                <InputGroup className={classes.textField} fields={fields.slice(5, 6)} />
              </Box>
            </Box>
          </Box>
          {!isItemCarousel && (
            <Box className={classes.subContentContainer}>
              <Box
                className={classes.subContentContainerChild}
                style={{
                  width: router.match.params.landing_page_content === 'shop_by_age' ? '100%' : 'calc(50% - 30px)',
                }}>
                <IconButton
                  className={classes.deleteButton}
                  onClick={() => {
                    desktop_data[0]?.asset_id && handleSave(1, 'desktop');
                  }}>
                  <img src="/images/buttonIcons/Delete_image_btn.png" alt="" />
                </IconButton>
                <SubContent
                  title="Desktop"
                  viewSize="desktop"
                  data={desktop_data[0]}
                  selectedLayout={selectedLayout}
                  setSelectedLayout={setSelectedLayout}
                  setFiles={setFiles}
                  disableLayout={isShopByAge}
                />
              </Box>
              {router.match.params.landing_page_content !== 'shop_by_age' ? (
                <Box className={classes.subContentContainerChild}>
                  <IconButton
                    className={classes.deleteButton}
                    onClick={() => {
                      mobile_data[0]?.asset_id && handleSave(1, 'mobile');
                    }}>
                    <img src="/images/buttonIcons/Delete_image_btn.png" alt="" />
                  </IconButton>
                  <SubContent
                    title="Mobile"
                    viewSize="mobile"
                    data={mobile_data[0]}
                    selectedLayout={selectedLayout}
                    setSelectedLayout={setSelectedLayout}
                    setFiles={setFiles}
                    disableLayout={isShopByAge}
                  />
                </Box>
              ) : null}
            </Box>
          )}
          {Object.keys(dirtys).length > 0 && (
            <Box className={classes.saveAndCancelContainer}>
              <SaveAndCancel
                disabledSave={errors !== undefined}
                functionSave={handleSave}
                functionCancel={handlePreFill}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Banner1;
