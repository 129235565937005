import { DataListUpdateProps, TYPES } from "./actions";

export type PropAction = {
  type: string;
  props: any;
};

export type DataListState = {
  listing: any;
  listingDetail: any;
  productList: any;
  productListSearch: any;
  getClassificationItemType: any;
  getReview: any;
  getReviewSearch: any;
  getQA: any;
  getQASearch: any;
  getProductList: any;
  getProductGiftList: any;
  vouchersIssuedListing: any;
};

const initial_state: DataListState = {
  listing: {
    items: [],
    hasMore: false,
  },
  listingDetail: {
    items: [],
    hasMore: false,
  },
  productList: {
    items: [],
    hasMore: false,
  },
  productListSearch: {
    items: [],
    hasMore: false,
  },
  getClassificationItemType: {
    items: [],
    hasMore: false,
  },
  getReview: {
    items: [],
    hasMore: false,
  },
  getReviewSearch: {
    items: [],
    hasMore: false,
  },
  getQA: {
    items: [],
    hasMore: false,
  },
  getQASearch: {
    items: [],
    hasMore: false,
  },
  getProductList: {
    items: [],
    hasMore: false,
  },
  getProductGiftList: {
    items: [],
    hasMore: false,
  },
  vouchersIssuedListing: {
    items: [],
    hasMore: false,
  },
};

const dataListReducer = (state: DataListState = initial_state, actions: PropAction) => {
  switch (actions.type) {
    case TYPES.UPDATE: {
      const updateProps: DataListUpdateProps = actions.props;
      return {
        ...state,
        [updateProps.key]: updateProps.value,
      };
    }
    default:
      return state;
  }
};

export default dataListReducer;
