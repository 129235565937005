import { AppBar, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

interface IProps {
  tabs: string[];
  value: number;
  setValue: (value: number) => void;
}

const ActiveTab = ({tabs, value, setValue}: IProps) => {
  const classes = useStyles()
  const a11yProps = (index: any) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <AppBar position="static" className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        {
          tabs.map((item, index) => (
            <Tab key={index} label={item} {...a11yProps(index)} className={value === index ? classes.activeTab : classes.tab} />
          ))
        }
      </Tabs>
    </AppBar>
  )
}

export default ActiveTab;