import { Box, Button, Fade, makeStyles, Modal, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import UpdateIcon from "@material-ui/icons/Update";
import clsx from "clsx";
import { ErrorMessage, LoadButton } from "components";
import { messages } from "./actionDialogConfig";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    boxShadow: " 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(5, 8),
  },
  button: {
    textTransform: "none",
    minWidth: 192,
    color: "#0D5AE5",
    padding: theme.spacing(1),
    "&:hover": {
      color: "#0D5AE5",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  editButtonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: "32px",
    marginTop: "32px",
  },
  cancelButton: {
    border: "1px solid #333333",
    color: "#333333",
    marginRight: "8px",
    "&:hover": {
      color: "#333333",
    },
  },
  updateButton: {
    color: "white",
    backgroundColor: "#0D5AE5",
    "&:hover": {
      color: "white",
      backgroundColor: "#0D5AE5",
    },
  },
  marginTop16: {
    marginTop: 16,
  },
  redText: {
    color: "red",
  },
  icon: {
    color: "#0D5AE5",
    height: 100,
    width: 100,
  },
  error: {
    marginBottom: theme.spacing(2),
  },
}));

const UpdateDialog = props => {
  const {
    handleClose,
    name,
    open,
    handleUpdate,
    loading,
    error,
    overwriteMessage,
    overwriteWarning,
    overwriteNameButton,
    overwriteIcon,
  } = props;
  const classes = useStyles();

  return (
    <Fragment>
      <Modal
        open={open}
        className={classes.modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box className={classes.root}>
            {overwriteIcon || <UpdateIcon className={classes.icon} />}
            <Typography variant="h4" className={classes.marginTop16}>
              {overwriteMessage || `Are you sure you want to update this ${name.toLowerCase()}?`}
            </Typography>
            <Typography variant="h6" className={clsx(classes.marginTop16, classes.redText)}>
              {overwriteWarning || "*The action cannot be undone"}
            </Typography>
            <ErrorMessage message={error?.message} map={messages} className={classes.error} />
            <Box className={classes.marginTop16}>
              <Button disabled={loading} className={clsx(classes.button, classes.cancelButton)} onClick={handleClose}>
                Cancel
              </Button>
              <LoadButton
                variant="contained"
                padding={4}
                size="large"
                load={loading}
                disabled={loading}
                className={clsx(classes.button, classes.updateButton)}
                onClick={handleUpdate}
              >
                {overwriteNameButton || "Done"}
              </LoadButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Fragment>
  );
};

UpdateDialog.defaultProps = {
  name: "Customer",
};

export default UpdateDialog;
