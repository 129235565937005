import { makeStyles } from "@material-ui/core";
import { InputGroup } from "components";
import React, { useEffect } from "react";
import useFormHandler from "utils/forms/useFormHandler";
const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root" :{
      backgroundColor: "white",
    },
    marginTop: theme.spacing(1)
  },
}));
const TextForm = (props) => {
  const {
    name,
    commonDirtys,
    setCommonDirtys,
    elementDetail,
    setElementDetail,
    commonErrors,
    setCommonErrors,
    errorSubmit,
    setErrorSubmit,
    initialValue,
    setInitialValue,
    formStructure
  } = props;
  const classes = useStyles();
  const [fields, values, errors, setValues, setErrors, setDirtys, dirtys] = // eslint-disable-line
    useFormHandler(formStructure);
  useEffect(() => {
    if(initialValue){
      setValues(elementDetail[name])
      setInitialValue(false)
    }
  }, [initialValue])// eslint-disable-line
  useEffect(() => {
    setElementDetail({
      ...elementDetail,
      [name]: {
        ...elementDetail[name],
        ...values,
      },
    });
    if (Object.keys(dirtys)?.length > 0) {
      setCommonDirtys({ ...commonDirtys, [name]: true });
    }
  }, [values]); // eslint-disable-line
  useEffect(() => {
    setCommonErrors({ ...commonErrors, [name]: errors });
  }, [errors]); // eslint-disable-line

  useEffect(() => {
    if (errorSubmit) {
      if(errors) {
        setDirtys(errors);
      }
      else {
        setDirtys({});
      }
      setErrorSubmit(false);
    }
  }, [errorSubmit]); // eslint-disable-line
  return (
    <React.Fragment>
      <InputGroup className={classes.textField} fields={fields} />
    </React.Fragment>
  );
};

export default TextForm;
