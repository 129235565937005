interface IProps {
  handleDeleteFunction: (id: number) => void;
}

export const tableConfig = [
  {
    title: "Id",
    type: "toShow",
    key: "id",
    sortable: true,
  },
  {
    title: "Title",
    type: "toShow",
    key: "title",
    sortable: true,
  },
  {
    title: "Handle",
    type: "toShow",
    key: "handle",
    sortable: true,
  },
  {
    title: "Status",
    type: "toShow",
    key: "webpage.published",
    showContent: (value: number) => (value === 1 ? "Published" : "UnPublished"),
  },
  {
    title: "Action",
    type: "toRedirect",
    routerKeys: ["id"],
    routerTo: "/frontend/listing/:id",
    runBeforeRoute: () => {},
    showContent: false,
    key: "id",
  },
];
