import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";

interface IProps {
  functionSave: Function;
  functionCancel: Function;
  disabledSave?: boolean;
  disabledCancel?: boolean;
  labelCancel?: string;
  labelSave?: string;
  justifyContent?: string;
  fullWidth?: boolean;
}

interface PropsType {
  justifyContent?: string;
  fullWidth?: boolean;
}

const useStyles = makeStyles(() => ({
  container: (props: PropsType) => ({
    display: "flex",
    justifyContent: props?.justifyContent ?? "flex-end",
    width: "100%",

    "& .MuiButtonBase-root": {
      width: "100%",
    },
  }),
  root: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: "12px",
  },
  saveButton: {
    background: "#0D5AE5",
    borderRadius: "5px",
    width: "100%",
    color: "#fff",
    textTransform: "none",
    fontWeight: 700,
    lineHeight: "14px",
    padding: "15px 0",
    "&:hover": {
      background: "#4257f5",
    },
  },
  cancelButton: {
    border: "2px solid #333333",
    borderRadius: "5px",
    width: "100%",
    color: "#333333",
    textTransform: "none",
    lineHeight: "14px",
    padding: "15px 0",
    fontWeight: 700,
  },
}));

export const ButtonConfirm = ({
  functionSave,
  functionCancel,
  disabledSave,
  disabledCancel,
  labelCancel,
  labelSave,
  justifyContent,
  fullWidth,
}: IProps) => {
  const classes = useStyles({
    fullWidth,
    justifyContent,
  });
  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <Button
          className={classes.cancelButton}
          onClick={() => functionCancel()}
          disabled={disabledCancel}
        >
          {labelCancel ?? "Cancel"}
        </Button>
        <Button
          className={classes.saveButton}
          onClick={() => functionSave()}
          disabled={disabledSave}
        >
          {labelSave ?? "Confirm"}
        </Button>
      </Box>
    </Box>
  );
};
