import React, { useCallback } from "react";
import { Box, makeStyles, Button } from "@material-ui/core";
import { useFilter } from "utils/filter";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    padding: "0 16px",
    marginBottom: "50px",
    borderBottom: "1px solid #F3F5FA",
  },
  tabButton: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0px",
    padding: "17px 50px",
    cursor: "pointer",
    borderRadius: 0
  },
  tabButtonActived: {
    borderBottom: "4px solid #0d5ae5",
  },
}));

const TabActive = (props) => {
  const { name, loading } = props;
  const classes = useStyles();
  const [filter, updateFilter] = useFilter(name);
  const tabsName = ["All", "Published", "Unpublished"];
  const handleSwitch = useCallback(
    (tab) => {
      updateFilter({ ...filter, published: tab.toLocaleLowerCase() });
    },
    [filter, updateFilter]
  );
  return (
    <Box className={classes.container}>
      {tabsName?.map((tab, index) => {
        return (
          <Button
            key={index}
            disabled={loading}
            className={clsx(
              classes.tabButton,
              filter.published === tab.toLocaleLowerCase()
                ? classes.tabButtonActived
                : null
            )}
            onClick={() => handleSwitch(tab)}
          >
            {tab}
          </Button>
        );
      })}
    </Box>
  );
};

export default TabActive;
