/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { InputGroup, Page } from "components";
import React, { useState, useEffect } from "react";
import useFormHandler from "utils/forms/useFormHandler";
import { useSnackbar } from "notistack";
import {
  useCreateCarouselBanner,
  useGetBanner,
  usePatchBanner,
  useGetGroupInfo,
} from "hooks";
import { useFilter } from "utils/filter";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    padding: 30,
    margin: 0,
    marginTop: 42,
    width: "100%",
  },
  heading: {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "48px",
    color: "#333333",
  },
  textField: {
    "& legend": {
      display: "none",
    },
    "& .MuiFormLabel-root.MuiInputLabel-shrink": {
      transform: "translate(14px, 5px) scale(0.75)",
      fontWeight: 500,
      lineHeight: "14px",
      color: "#A5A9B9",
    },

    "& textarea.MuiInputBase-input ": {
      height: "40px !important",
    },
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 8,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "& > div:last-child": {
      display: "flex",
      flexDirection: "row",
      marginTop: 16,
      alignItems: "flex-end",
      gap: "12px",
      whiteSpace: "nowrap",
      "& .text": {
        color: "#0D5AE5",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "nowrap",
        gap: "12px",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "14px",
        cursor: "pointer",
      },
    },
  },
  buttonGroup: {
    display: "flex",
    gap: "12px",
    justifyContent: "flex-end",
    marginTop: 42,
  },
  btnSave: {
    background: "#0D5AE5",
    borderRadius: "5px",
    padding: "6px 47px",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#FFFFFF",
    textTransform: "none",
  },
  btnCancel: {
    border: "2px solid #333333",
    borderRadius: "5px",
    padding: "6px 41.5px",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    color: "#333333",
    textTransform: "none",
  },
}));

const ItemCarousel = ({ title, getBanner }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [groupSelect, setGroupSelect] = useState({});
  const { getGroupInfoData, getGroupInfoMutate } = useGetGroupInfo();
  useEffect(() => {
    if (getGroupInfoData) {
      // eslint-disable-next-line array-callback-return
      getGroupInfoData.map((item) => {
        setGroupSelect((prev) => {
          return {
            ...prev,
            [item?.id]: item?.name,
          };
        });
      });
    }
  }, [getGroupInfoData]);
  const formStructure = {
    group_id: {
      initialValue: "",
      label: "Product Group",
      type: "select",
      validate: {
        presence: { allowEmpty: false, message: "is required" },
        inclusion: Object.values(groupSelect || {}),
      },
      options: groupSelect || {},
    },
    title: {
      initialValue: "",
      label: "Banner Title",
      type: "text",
      validate: {
        presence: { allowEmpty: false, message: "is required" },
      },
    },
    description: {
      initialValue: "",
      label: "Banner Description",
      type: "textarea",
      validate: {
        presence: { allowEmpty: false, message: "is required" },
      },
    },
    cta_link: {
      initialValue: "",
      label: "Button Link",
      type: "text",
      validate: {
        presence: { allowEmpty: false, message: "is required" },
      },
      disabled: isUpdate,
    },
  };
  const {
    createNewBannerData,
    createNewBannerError,
    createNewBannerLoading,
    createNewBannerMutate,
  } = useCreateCarouselBanner();
  const {
    patchBannerData,
    patchBannerError,
    patchBannerMutate,
    patchBannerLoading,
  } = usePatchBanner();
  const [fields, inputs, errors, setInputs, setDirtys] =
    useFormHandler(formStructure);
  const { getBannerData, getBannerMutate } = useGetBanner();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [filter, updateFilter] = useFilter("banner");

  useEffect(() => {
    getGroupInfoMutate();
    updateFilter({ ...filter, bannerType: getBanner });
    return function cleanup() {
      updateFilter({ ...filter, bannerType: "" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (filter?.bannerType !== "") {
      getBannerMutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (!inputs.group_id || !getGroupInfoData) return;
    setInputs({
      ...inputs,
      cta_link: `${process.env.REACT_APP_HOST}/promo_group/${
        getGroupInfoData?.filter((item) => item?.name === inputs?.group_id)[0]
          ?.handle
      }`,
    });
  }, [inputs.group_id, getGroupInfoData]);

  const handleSave = () => {
    let formData = new FormData();
    formData.append("bannerType", getBanner);
    formData.append("title", inputs?.title ?? "");
    formData.append("description", inputs?.description ?? "");
    formData.append("layout_type", 1);
    // eslint-disable-next-line no-unused-expressions
    formData.append(
      "group_id",
      getGroupInfoData?.filter((item) => item?.name === inputs?.group_id)[0]
        ?.id ?? null
    );
    formData.append("cta_link", inputs?.cta_link ?? "");
    formData.append("viewSize", "desktop");
    if (isUpdate) {
      formData.append(
        "asset_id",
        getBannerData.data?.[getBanner + "_desktop"][0]?.id
      );
    }
    if (!errors) {
      if (isUpdate) {
        patchBannerMutate(formData);
      } else {
        createNewBannerMutate(formData);
      }
    } else {
      setDirtys(errors);
    }
  };

  const handleCancel = () => {
    setInputs((prev) => {
      return {
        ...prev,
        title: "",
        description: "",
        group_id: "",
        cta_link: "",
      };
    });
  };

  useEffect(() => {
    if (createNewBannerData || createNewBannerError) {
      if (createNewBannerData?.status === 0) {
        enqueueSnackbar("Create new banner successfully!", {
          variant: "success",
        });
        getBannerMutate();
      } else {
        enqueueSnackbar("Create new banner failed!", {
          variant: "error",
        });
      }
    }
    if (patchBannerData || patchBannerError) {
      if (patchBannerData?.status === 0) {
        enqueueSnackbar("Update banner successfully!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Update banner failed!", {
          variant: "error",
        });
      }
    }
  }, [
    createNewBannerData,
    createNewBannerError,
    patchBannerData,
    patchBannerError,
  ]);

  useEffect(() => {
    if (
      getBanner.includes("item_carousel") &&
      getBannerData?.data?.[getBanner + "_desktop"]?.length > 0
    ) {
      const data = getBannerData.data?.[getBanner + "_desktop"][0];
      setInputs((prev) => {
        return {
          ...prev,
          title: data.title ?? "",
          description: data.description ?? "",
          group_id:
            getGroupInfoData?.filter((item) => item?.id === data.group_id)[0]
              ?.name ?? "",
          cta_link: data?.cta_link ?? "",
        };
      });
      setIsUpdate(true);
    }
  }, [getBanner, getBannerData, getGroupInfoData, setInputs]);

  const handleCopyLink = () => {
    if (inputs?.cta_link) {
      navigator.clipboard.writeText(inputs?.cta_link ?? "");
      enqueueSnackbar("Copied to clipboard!", {
        variant: "success",
      });
    }
  };

  return (
    <Page title="Home page">
      <Typography variant="h1" className={classes.heading}>
        {`Home Page > ${title}`}
      </Typography>
      <Box className={classes.root}>
        <Box className={classes.inputGroup}>
          <InputGroup
            className={classes.textField}
            fields={fields.slice(0, 3)}
          />
          <Box>
            <InputGroup
              className={classes.textField}
              fields={fields.slice(3)}
            />
            <Box className="text" onClick={() => handleCopyLink()}>
              <img alt="" src="/images/copy_link_icon.svg" />
              Copy Link
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.buttonGroup}>
        <Button onClick={handleCancel} className={classes.btnCancel}>
          Cancel
        </Button>
        <Button
          disabled={
            createNewBannerLoading || patchBannerLoading || Boolean(errors)
          }
          onClick={handleSave}
          className={classes.btnSave}
        >
          {isUpdate ? "Update" : "Create"}
        </Button>
      </Box>
    </Page>
  );
};
export default ItemCarousel;
