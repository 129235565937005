import { Paths } from "utils/constants";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import CameraIcon from "@material-ui/icons/Camera";
import { ReactComponent as HomeIcon } from "assets/images/icons/home.svg";

export default [
  {
    pages: [
      {
        title: "Frontend",
        icon: HomeIcon,
        children: [
          {
            title: "Homepage",
            href: Paths.Frontend.NewLandingPage,
          },
          {
            title: "Webpages",
            href: Paths.Frontend.Webpages,
          },
          {
            title: "Listing",
            href: Paths.Frontend.ListingDashboard,
          },
          {
            title: "Collection",
            href: Paths.Frontend.CollectionPage,
          },
          {
            title: "Site Settings",
            href: Paths.Frontend.Settings,
          },
        ],
      },
      {
        title: "User",
        icon: PeopleAltIcon,
        children: [
          {
            title: "Order Mgt",
            href: Paths.Sales.OrderOverview,
          },
          {
            title: "Review",
            href: Paths.Sales.ReviewManagement,
          },
          {
            title: "Q&A",
            href: Paths.Sales.QAManagement,
          },
          {
            title: "User Accounts",
            href: Paths.Accounts.List,
          },
          {
            title: "Vouchers",
            href: Paths.VoucherSettings,
          },
        ],
      },
      {
        title: "Products",
        icon: CameraIcon,
        children: [
          {
            title: "Catalogue",
            href: Paths.Products.CatalogueList,
          },
          {
            title: "Classification",
            href: Paths.Frontend.Classifications,
          },
          {
            title: "Promotions",
            href: Paths.Promotions.PromotionListPage,
          },
        ],
      },
    ],
  },
];
