export const TYPES = {
  UPDATE: "DATA_DRAFT_UPDATE",
};

export type DataDraftKeys =
  | "ItemClass"
  | "ItemSubClass"
  | "ItemCategory"
  | "ItemType"
  | "Color"
  | "Asset"
  | "SelectedProducts"
  | "CartProductTabDraftData"
  | "SelectedCartProducts"
  | "VoucherIssued"
  | "checklistCollectionProductsTab"
  | "checklistCollectionAllProductsTab"
  | "checklistPromotionProductsTab"
  | "checklistPromotionAllProductsTab"
  | "checklistPromotionAllBundleProductsTab";

export type DataDraftUpdateProps = {
  key: DataDraftKeys;
  value: any;
};

export function update(props: DataDraftUpdateProps) {
  return {
    type: TYPES.UPDATE,
    props,
  };
}
