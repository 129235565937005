import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { RenderGuard } from "components";
import { useFilter } from "utils/filter";
import { resultLimit, showPaginate } from "utils/tools";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  paginator: {
    padding: "10px 16px",
    [theme.breakpoints.only("xs")]: {
      display: "block",
    },
  },
  pager: {
    paddingLeft: 0,
    overflowX: "auto",
    [theme.breakpoints.only("xs")]: {
      marginTop: 12,
    },
  },
  spacer: {
    [theme.breakpoints.only("xs")]: {
      flex: "none",
    },
  },
  pagination: {
    fontSize: "15px",
    fontWeight: "500",
  },
}));

const TablePaginate = (props) => {
  const { count, name, loading } = props;

  const classes = useStyles();
  const [filter, updateFilter] = useFilter(name);
  const page = filter ? Math.ceil(filter.offset / filter.limit) : 1;
  const rowsPerPage = filter ? filter.limit : 0;

  const handleChangePage = (event, page) => {
    const new_offset = Math.floor(rowsPerPage * (page - 1));
    if (filter) {
      updateFilter({ ...filter, offset: new_offset });
    }
  };

  return (
    <RenderGuard renderIf={!loading}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.paginator}
      >
        <RenderGuard renderIf={filter.count > 0 && count > 0}>
          Showing {filter.offset + 1} -{" "}
          {resultLimit(filter.offset, filter.limit, filter.count)} of{" "}
          {filter.count} results
        </RenderGuard>
        <RenderGuard renderIf={showPaginate(filter.count, filter.limit)}>
          <Pagination
            color="primary"
            showFirstButton
            showLastButton
            page={page + 1}
            count={Math.ceil(filter.count / rowsPerPage)}
            onChange={handleChangePage}
            className={classes.pagination}
          />
        </RenderGuard>
      </Grid>
    </RenderGuard>
  );
};

export default TablePaginate;
