import { Actions } from 'store';
import { useDispatch, useSelector } from 'react-redux';

export default function useFilter(filterKey, initialFilter) {
  const filter = useSelector(state => state.Filter[filterKey]);
  const dispatch = useDispatch();

  const updateFilter = newFilterComponents => {
    dispatch(
      Actions.Filter.update({
        key: filterKey,
        value: newFilterComponents,
      })
    );
  };

  const keys = Object.keys(filter).filter(key => !['count', 'init'].includes(key));

  const listenFilters = keys.map(key => filter[key]);

  const parsedFilter = keys.reduce((object, key) => {
    if (['reload'].includes(key)) return object;
    object[key] = filter[key];
    return object;
  }, {});

  return [filter, updateFilter, listenFilters, parsedFilter];
}