import { DataDraftUpdateProps, TYPES } from "./actions";

export type PropAction = {
  type: string;
  props: any;
};

export type DataDraftState = {
  ItemClass: any;
  ItemSubClass: any;
  ItemCategory: any;
  ItemType: any;
  Color: any;
  Asset: any;
  SelectedProducts: any;
  CartProductTabDraftData: any;
  SelectedCartProducts: any;
  VoucherIssued: any;
  checklistCollectionProductsTab: any;
  checklistCollectionAllProductsTab: any;
  checklistPromotionProductsTab: any;
  checklistPromotionAllProductsTab: any;
  checklistPromotionAllBundleProductsTab: any;
};

const initial_state: DataDraftState = {
  ItemClass: [],
  ItemSubClass: [],
  ItemCategory: [],
  ItemType: [],
  Color: [],
  Asset: [],
  SelectedProducts: [],
  CartProductTabDraftData: [],
  SelectedCartProducts: [],
  VoucherIssued: [],
  checklistCollectionProductsTab: [],
  checklistCollectionAllProductsTab: [],
  checklistPromotionProductsTab: [],
  checklistPromotionAllProductsTab: [],
  checklistPromotionAllBundleProductsTab: [],
};

const dataDraftReducer = (state: DataDraftState = initial_state, actions: PropAction) => {
  switch (actions.type) {
    case TYPES.UPDATE: {
      const updateProps: DataDraftUpdateProps = actions.props;
      return {
        ...state,
        [updateProps.key]: updateProps.value,
      };
    }
    default:
      return state;
  }
};

export default dataDraftReducer;
