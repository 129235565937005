import { combineReducers } from "redux";
import Layout, { LayoutState } from "./layout/reducers";
import Preference, { PreferenceState } from "./preference/reducers";
import Session, { SessionState } from "./session/reducers";
import Filter, { FilterState } from "./filter/reducers";
import Models, { ModelsState } from "./models/reducers";
import TrackingId, { FormTrackingState } from "./trackingid/reducers";
import UpLoadFile, { FormUpLoadFileState } from "./uploadfile/reducers";
import ContentNotification, {
  FormcontentState,
} from "./contentnotification/reducers";
import ExchangeItemSelected, {
  FormExchangeItemState,
} from "./exchangeitemselected/reducers";
import PreFillData, { PreFillState } from "./prefilldata/reducers";
import dataListReducer, { DataListState } from "./datalist/reducers";
import optionsStoreReducer, {
  OptionsStoreState,
} from "./optionsstore/reducers";
import dataDraftReducer, { DataDraftState } from "./draftdata/reducers";
import bannerListReducer, { BannerListState } from "./banner/reducers";
import promotionInfoReducer, {
  PromotionInfoState,
} from "./promotionInfo/reducers";

export type RootState = {
  Layout: LayoutState;
  Preference: PreferenceState;
  Session: SessionState;
  Filter: FilterState;
  Models: ModelsState;
  TrackingId: FormTrackingState;
  UpLoadFile: FormUpLoadFileState;
  ContentNotification: FormcontentState;
  ExchangeItemSelected: FormExchangeItemState;
  PreFillData: PreFillState;
  dataListReducer: DataListState;
  optionsStoreReducer: OptionsStoreState;
  dataDraftReducer: DataDraftState;
  bannerListReducer: BannerListState;
  promotionInfoReducer: PromotionInfoState;
};

export const Reducers = combineReducers({
  Layout,
  Preference,
  Session,
  Filter,
  Models,
  TrackingId,
  UpLoadFile,
  ContentNotification,
  ExchangeItemSelected,
  PreFillData,
  dataListReducer,
  optionsStoreReducer,
  dataDraftReducer,
  bannerListReducer,
  promotionInfoReducer,
});
