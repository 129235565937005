import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
  },
  tab: {
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "uppercase",
    color: "#333333",
    maxWidth: 138,
  },
  activeTab: {
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "uppercase",
    color: "#0D5AE5",
    backgroundColor: "#fff",
    maxWidth: 138,
  },
}));
