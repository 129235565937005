import { useMemo } from "react";
import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useFilter } from "utils/filter";

export const useGetCustomerOrders = (account_id?: number | string) => {
  const [filter] = useFilter("getCustomerOrders");
  const filterPayload = useMemo(
    () =>
      Object.assign(
        {},
        ...Object.keys(filter)
          .filter((item) => ![null, undefined, ""].includes(filter[item]))
          .map((item) => {
            return { [item]: filter[item] };
          })
      ),
    [filter]
  );
  const api = useApi();
  const getCustomerOrders = async () => {
    const res = await api
      .path(
        "order/list",
        {},
        { ...filterPayload, ...(account_id && { account_id: account_id }) }
      )
      .get();
    return res?.data?.data;
  };

  const { data, isLoading, error, mutate } = useMutation(() =>
    getCustomerOrders()
  );

  return {
    getCustomerOrdersData: data,
    getCustomerOrdersError: error,
    getCustomerOrdersLoading: isLoading,
    getCustomerOrdersMutate: mutate,
  };
};
