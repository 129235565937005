import { useMutation } from 'react-query';
import useApi from 'utils/api/useApi';
import { useSnackbar } from 'notistack';
import { useFilter } from 'utils/filter';

interface Props {
  id: number;
  handleRedirect?: () => void;
}

export const useDeleteListing = (resetData?: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useFilter('listing');
  const api = useApi();
  const deleteListing = async ({ id, handleRedirect }: Props) => {
    const res = await api.path('delete/listing', { listing_id: id }).del();
    try {
      if (res?.data?.status === 0) {
        resetData && resetData();
        handleRedirect && handleRedirect();
        setFilter({
          ...filter,
          offset: 0,
        });
        enqueueSnackbar('Delete listing successfully.', {
          variant: 'success',
        });
        return res?.data;
      } else {
        enqueueSnackbar('Delete listing failed!', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('Delete listing failed!', {
        variant: 'error',
      });
    }
  };

  const { data, isLoading, error, mutate } = useMutation((props: Props) => deleteListing(props));

  return {
    deleteListingData: data,
    deleteListingError: error,
    deleteListingLoading: isLoading,
    deleteListingMutate: mutate,
  };
};
