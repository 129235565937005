import React, { useState } from "react";
import { makeStyles, Grid, Modal, Fade, Backdrop, Box } from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import clsx from "clsx";

import ImageGallery from 'react-image-gallery';

const useStyles = makeStyles(theme => ({
  root: {
    width: "116px",
  },
  imgSquare: {
    height: "52px",
    width: "52px",
    cursor: "pointer",
  },
  imgShowMore: {
    cursor: "pointer",
    height: "52px",
    width: "52px",
    filter: "brightness(50%)"
  },
  showMoreIcon: {
    cursor: "pointer",
    color: "white",
    position: "relative",
    top: "-44px",
    left: "14px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gallery: {
    width: "550px"
  }
}));

const ImageGrid = (props) => {
  const { images, className } = props;
  const [isShowMore, setIsShowMore] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const classes = useStyles();
  const toggleShowMore = (index) => {
    setIsShowMore(!isShowMore);
    setStartIndex(index);
  };

  const limit = 3;
  let imageSquares = [];
  for (let i = 0; i < limit; i++) {
    const img1 = images[i];
    i++;
    const img2 = images[i];
    imageSquares.push(
      <Grid container item xs={12} key={i}>
        <Grid item xs={6} onClick={()=>toggleShowMore(i-1)}>
          {img1 && (<img className={classes.imgSquare} src={img1.img} alt={"Review"}/>)}
        </Grid>
        <Grid item xs={6} onClick={()=>toggleShowMore(i)}>
          {
            ( i===limit && img2 && (
              <div>
                <img className={classes.imgShowMore} src={img2.img} alt={"Review"}/>
                <MoreHorizIcon className={classes.showMoreIcon} />
              </div>
            ) )
            || ( img2 && (<img className={classes.imgSquare} src={img2.img} alt={"Review"}/>) )
          }
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container>
        {imageSquares}
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isShowMore}
        onClose={toggleShowMore}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isShowMore}>
          <Box className={classes.gallery} >
            <ImageGallery
              className={classes.gallery}
              infinite={false}
              showPlayButton={false}
              items={images.map(image => {
                return {
                  original: image.img,
                  thumbnail: image.img,
                }
              })}
              startIndex={startIndex}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ImageGrid;
