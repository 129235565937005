import {
  Box,
  Button,
  Fade,
  Grid,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "cropperjs/dist/cropper.css";
import clsx from "clsx";
import { Cropper } from "react-cropper";
import CropIcon from "@material-ui/icons/Crop";
import ImageIcon from "@material-ui/icons/Image";
import SaveIcon from "@material-ui/icons/Save";
import noImage from "assets/images/not-found/no-images.png";
import CloseIcon from "@material-ui/icons/Close";

const CropperImage = (props) => {
  const { open, setOpen, src, handleSaveImage, aspectRatio, clear } = props;
  const [image, setImage] = useState("");
  const [cropper, setCropper] = useState();
  const classes = useStyles();
  const [cropFile, setCropFile] = useState(null);
  const [cropData, setCropData] = useState(null);
  useEffect(() => {
    if (open?.name) {
      if (typeof src === "object")
        setImage(
          src[open?.name]?.image?.imagePreviewUrl ||
            src[open?.name]?.imagePreviewUrl ||
            noImage
        );
      else {
        setImage(src);
      }
    }
    return () => {
      setImage("");
      setCropData(null);
    };
  }, [open.name, src]);

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setCropData(null);
    };
    if (files[0]) {
      reader.readAsDataURL(files[0]);
    }
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      cropper.getCroppedCanvas().toBlob((blob) => {
        blob.lastModifiedDate = new Date();
        blob.name = open.name;
        setCropFile(blob);
      });
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open?.isOpen}
        onClose={() => {
          setOpen((prev) => {
            return { isOpen: !prev?.isOpen, name: null };
          });
          if(typeof clear === "function") {
            clear()
          }
        }}
        closeAfterTransition
      >
        <Fade in={open?.isOpen}>
          <Box className={classes.root}>
            <Button
              onClick={() => {
                setOpen((prev) => {
                  return { isOpen: !prev?.isOpen, name: null };
                });
                if(typeof clear === "function") {
                  clear()
                }
              }}
              className={classes.closeButton}
            >
              <CloseIcon />
            </Button>
            <Grid container spacing={3}>
              <Grid className={classes.cropperBox} item>
                <Cropper
                  className={classes.cropper}
                  style={{ height: 400, width: "100%" }}
                  preview=".img-preview"
                  aspectRatio={aspectRatio}
                  center={true}
                  src={image}
                  viewMode={1}
                  checkCrossOrigin={false}
                  background={true}
                  responsive={true}
                  scalable={true}
                  autoCropArea={1}
                  checkOrientation={true}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
                <Box className={classes.editButtonBox}>
                  <Button onClick={getCropData} className={classes.buttonBox}>
                    <CropIcon />
                    &nbsp;Crop Image
                  </Button>
                  <Button
                    className={clsx(
                      classes.buttonBox,
                      classes.changeImageButton
                    )}
                  >
                    <input
                      type="file"
                      className={classes.inputImage}
                      accept="image/png, image/jpeg"
                      onChange={onChange}
                    />
                    <Box className={classes.changeImageBox}>
                      <ImageIcon />
                      &nbsp;Change Image
                    </Box>
                  </Button>
                </Box>
              </Grid>
              <Grid
                className={clsx(classes.cropperBox, classes.cropperflex)}
                item
              >
                <Box className={classes.previewBox}>
                  <Typography variant="h5">Preview</Typography>
                  <Box className="img-preview" />
                </Box>
                <Box>
                  <Typography variant="h5">Crop</Typography>
                  {cropData && (
                    <Box className={classes.cropBox}>
                      <img
                        className={classes.cropImage}
                        src={cropData}
                        alt="cropped"
                      />
                      <Button
                        onClick={(e) => {
                          handleSaveImage(e, open?.name, cropFile);
                          setOpen((prev) => {
                            return { isOpen: !prev?.isOpen, name: null };
                          });
                        }}
                        className={clsx(classes.buttonBox, classes.marginTop10)}
                      >
                        <SaveIcon />
                        &nbsp;Save
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cropper:{
    display: "flex",
    justifyContent: "center",
  },
  root: {
    minWidth: "50%",
    backgroundColor: "aliceblue",
    position: "relative",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3, 4),
    borderRadius: "4px",
  },
  closeButton: {
    right: 0,
    position: "absolute",
    top: 0,
    padding: "10px",
    borderRadius: "25px",
    minWidth: "fit-content",
  },
  cropperBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "65%",
    },
  },
  cropperflex: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      width: "100%!important",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "35%!important",
      flexDirection: "column",
    },
  },
  previewBox: {
    "& .img-preview": {
      overflow: "hidden",
      width: "170px",
      height: "170px",
      marginTop: "6px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      height: "45%",
    },
  },
  cropBox: {
    display: "flex",
    flexDirection: "column",
  },
  cropImage: {
    width: "170px",
    marginTop: "6px",
  },
  editButtonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
  buttonBox: {
    width: "100%",
    textTransform: "none",
  },
  changeImageButton: {
    position: "relative",
  },
  changeImageBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputImage: {
    position: "absolute",
    opacity: 0,
    height: "100%",
    width: "100%",
  },
  marginTop10: {
    marginTop: "10px",
  },
}));
export default CropperImage;
