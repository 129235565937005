import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";


interface Body {
  account_id: number | string;
  voucher_code: string;
  voucher_id: number | string;
}

export const usePostCustomerVoucher = () => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const postCustomerVoucher = async (body: Body) => {
    const res = await api
      .path("vouchers/create")
      .post({ body });
      if (res?.data?.status === 0) {
        enqueueSnackbar("New voucher created!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`Create new voucher failed! ${res?.data?.message}`, {
          variant: "error",
        });
      }
    return res?.data?.data
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => postCustomerVoucher(body));

  return {
    postCustomerVoucherData: data,
    postCustomerVoucherError: error,
    postCustomerVoucherLoading: isLoading,
    postCustomerVoucherMutate: mutate,
  };
};
