import { TYPES } from "./actions";
import { PropAction } from "../props";


export type FormcontentState = {
  complete_order: string;
  fail_order: string;
  no_tracking_id : string;
  exchange_status : string;
  open_success : boolean;
  open_fail : boolean;
};

const initial_state: FormcontentState = {
  complete_order : "",
  fail_order: "",
  no_tracking_id : "",
  exchange_status: "",
  open_success : false,
  open_fail : false,
}

export default (state: FormcontentState = initial_state, actions: PropAction) => {
  switch (actions.type) {
    case TYPES.UPDATE_CONTENT:
        let exchange_status = ""
        if(actions.props.exchange_status!==undefined){
          exchange_status = actions.props.exchange_status
        }
      return {
        complete_order : actions.props.complete_order,
        fail_order: actions.props.fail_order,
        no_tracking_id: actions.props.no_tracking_id,
        exchange_status,
        open_success : actions.props.open_success ,
        open_fail : actions.props.open_fail ,
      };
    default:
      return state;
  }
}