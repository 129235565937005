import { useMutation } from 'react-query';
import useApi from 'utils/api/useApi';
import { useSnackbar } from 'notistack';

export const useDeleteAsset = (reload: Function) => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const deleteAsset = async (id: number) => {
    try {
      const res = await api.path('delete/asset', { id: id }).del();
      if (res?.data?.status === 0) {
        enqueueSnackbar('Image has been deleted!', {
          variant: 'success',
        });
        reload();
      } else {
        enqueueSnackbar('Delete Image failed!', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('Delete Image failed!', {
        variant: 'error',
      });
    }
  };

  const { data, isLoading, error, mutate } = useMutation((id: number) => deleteAsset(id));

  return {
    deleteAssetData: data,
    deleteAssetError: error,
    deleteAssetLoading: isLoading,
    deleteAssetMutate: mutate,
  };
};
