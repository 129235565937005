import React, { Fragment, useState, useRef } from "react";
import { makeStyles, TextField, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useFilter } from "utils/filter";
import { IconComponent } from "components";

const useStyles = makeStyles(theme => ({
  textField: {
    width: "60ch",
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    marginBottom: 20,
  },
  fullWidthTextField: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    marginBottom: 20,
  },
  searchIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    fontSize: "30px",
  },
}));

const Search = props => {
  const { name, placeholder, fullWidth, style, setOnSearch, reload } = props;

  const classes = useStyles();

  const [filter, updateFilter] = useFilter(name);

  const [valueSearch, setValueSearch] = useState("");

  const timeoutID = useRef();

  const handleChangeInput = e => {
    let value = e.target.value;
    setValueSearch(value);
    if (timeoutID.current) {
      clearTimeout(timeoutID.current);
    }
    timeoutID.current = setTimeout(() => {
      reload && reload();
      updateFilter({ ...filter, search: value, offset: 0 });
    }, 500);
  };

  return (
    <Fragment>
      <TextField
        style={style}
        variant="outlined"
        value={valueSearch}
        type="text"
        placeholder={placeholder}
        InputProps={{
          onFocus: () => {
            if (setOnSearch) {
              if (filter.search === undefined) {
                updateFilter({ ...filter, search: "" });
              }
              setOnSearch(true);
            }
          },
          startAdornment: valueSearch ? (
            <IconButton
              color="inherit"
              onClick={() => {
                setValueSearch("");
                updateFilter({ ...filter, offset: 0, search: "" });
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <IconComponent name="search" className={classes.searchIcon} />
          ),
        }}
        classes={{
          root: fullWidth ? classes.fullWidthTextField : classes.textField,
        }}
        onChange={handleChangeInput}
      />
    </Fragment>
  );
};

export default Search;
