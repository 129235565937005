import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useFilter } from "utils/filter";

export const useGetCustomerVouchers = (account_id?: number | string) => {
  const api = useApi();
  const [filter] = useFilter("vouchersIssued");
  const getCustomerVouchers = async () => {
    const res = await api
      .path(
        "vouchers_issued",
        {},
        { ...filter, ...(account_id && { account_id: account_id }) }
      )
      .get();
    return res?.data?.data;
  };

  const { data, isLoading, error, mutate } = useMutation(() =>
    getCustomerVouchers()
  );

  return {
    getCustomerVouchersData: data,
    getCustomerVouchersError: error,
    getCustomerVouchersLoading: isLoading,
    getCustomerVouchersMutate: mutate,
  };
};
