import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";

enum type {
  gwp = "GWP",
  pwp = "PWP",
  bundle = "BUNDLE"
}

enum Published {
  published = "published",
  unpublished = "unpublished"
}

interface PatchBody {
  name?: string;
  campaign_type?: type;
  start_at?: string;
  end_at?: string;
  published?: Published;
}

interface Body {
  body: PatchBody,
  campaign_id: number | string;
}

export const usePatchCampaign = () => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const patchCampaign = async (body: Body) => {
    const res = await api
      .path("campaign/campaign/update", {campaign_id: body.campaign_id})
      .patch({ body: body.body });
    if (res?.data?.status === 0) {
      enqueueSnackbar("Update campaign successfully!", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(`Update campaign faiiled! ${res?.data?.message}`, {
        variant: "error",
      });
    }
    return res?.data?.data
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => patchCampaign(body));

  return {
    patchCampaignData: data,
    patchCampaignError: error,
    patchCampaignLoading: isLoading,
    patchCampaignMutate: mutate,
  };
};
