import { useMutation } from "react-query";
import useApi from "utils/api/useApi";

export const useGetPromotion = () => {
  const api = useApi();

  const getPromotion = async (filter: any) => {
    try {
      const res = await api.path("promotion/list", {}, { ...filter }).get();
      return res?.data?.data?.data;
    } catch (error) {}
  };

  const { data, isLoading, error, mutate } = useMutation((filter: any) => getPromotion(filter));

  return {
    getPromotionData: data,
    getPromotionError: error,
    getPromotionLoading: isLoading,
    getPromotionMutate: mutate,
  };
};
