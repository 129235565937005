import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";

interface PatchBody {
  log_status: number;
}

interface Body {
  body: PatchBody,
  id: number | string;
}

export const usePatchVouchersIssued = () => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const patchVouchersIssued = async (body: Body) => {
    const res = await api
      .path("vouchers_issued/patch", {id: body.id})
      .patch({ body: body.body });
    if (res?.data?.status === 0) {
      enqueueSnackbar(`Update voucher ${body.id} created!`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(`Update voucher ${body.id} failed! ${res?.data?.message}`, {
        variant: "error",
      });
    }
    return res?.data?.data
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => patchVouchersIssued(body));

  return {
    patchVouchersIssuedData: data,
    patchVouchersIssuedError: error,
    patchVouchersIssuedLoading: isLoading,
    patchVouchersIssuedMutate: mutate,
  };
};
