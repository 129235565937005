import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";

export const usePostAssetImage = (reload: () => void, handleClose?: () => void) => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const postProductImage = async (formData: FormData) => {
    try {
      const res = await api.path("asset/create").multipost({ body: formData });
      if (res?.data?.status === 0) {
        enqueueSnackbar("Image Preview has been successfully uploaded!", {
          variant: "success",
        });
        reload();
        handleClose && handleClose();
        return true;
      } else {
        enqueueSnackbar("Upload Image Preview failed!", {
          variant: "error",
        });
        return false;
      }
    } catch (error) {}
  };

  const { data, isLoading, error, mutate } = useMutation((formData: FormData) => postProductImage(formData));

  return {
    postImagePreviewData: data,
    postImagePreviewError: error,
    postImagePreviewLoading: isLoading,
    postImagePreviewMutate: mutate,
  };
};
