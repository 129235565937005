import { PreFillProps, TYPES } from './actions';
import { PropAction } from '../props';

export type PreFillState = {
  product_type_detail: any;
  customer_detail: any;
  product_detail: any;
};

const initial_state: PreFillState = {
  product_type_detail: {},
  customer_detail: {},
  product_detail: {},
};

export default (state: PreFillState = initial_state, actions: PropAction) => {
  switch (actions.type) {
    case TYPES.UPDATE:
      const updateProps: PreFillProps = actions.props;
      return {
        ...state,
        [updateProps.key]: updateProps.value,
      };
    default:
      return state;
  }
};
