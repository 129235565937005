export enum OwnerType {
  listing = "listing",
  home_carousel = "home_carousel",
  home_banner_1 = "home_banner_1",
  item_carousel_1 = "item_carousel_1",
  item_carousel_2 = "item_carousel_2",
  "landing_page_v2" = "landing_page_v2",
  age_group = "age_group",
  review = "review",
  product_option = "product_option",
  webpage = "webpage",
  product = "product",
}

export enum PublishedNumberType {
  published = 1,
  unpublished = 0,
}

export enum WebpageType {
  listing = "listing",
  item_type = "item_type",
  item_class = "item_class",
  item_group = "item_group",
  item_sub_class = "item_sub_class",
  item_category = "item_category",
  item_brand = "item_brand",
  promotion = "promotion",
  collection = "collection",
  meta_keyword = "meta_keyword",
}
