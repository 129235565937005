export const formStructure: any = (getBanner: string) => {
  return {
    title: {
      initialValue: '',
      label: 'Banner Title',
      type: 'text',
      validate: {
        presence: {allowEmpty: false, message: 'is required'},
      },
    },
    description: {
      initialValue: '',
      label: 'Banner Description',
      type: 'textarea',
      validate: {
        presence: {allowEmpty: false, message: 'is required'},
      },
    },
    cta_text: {
      initialValue: '',
      label: 'Banner Button Text',
      type: 'text',
      validate: {
        presence: {allowEmpty: false, message: 'is required'},
      },
    },
    cta_link: {
      initialValue: '',
      label: 'Button Link',
      type: 'text',
      validate: {
        presence: {allowEmpty: false, message: 'is required'},
      },
      disabled: getBanner === "home_banner_1"
    },
  }
};