import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useFilter } from "utils/filter";

export const useGetAllPromotionProductList = (name = "getAllPromotionProducts") => {
  const [filter] = useFilter(name);
  const [data, setData] = useState<any>({
    items: [],
    hasMore: true,
  });
  const api = useApi();
  const getAllPromotionProductList = async () => {
    const res = await api.path("promotion_product_all/list", {}, { ...filter }).get();
    if (res?.data?.status === 0) {
      if (filter.offset === 0) {
        setData({
          items: res?.data?.data.data,
          hasMore: res?.data?.data?.data?.length < res?.data?.data?.total,
        });
      } else {
        setData((prev: any) => ({
          items: [...prev.items, ...res?.data?.data?.data],
          hasMore: res?.data?.data?.data.length + prev?.items.length < res?.data?.data?.total,
        }));
      }
    }
  };

  const { isLoading, error, mutate } = useMutation(() => getAllPromotionProductList());

  useEffect(() => {
    getAllPromotionProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return {
    getAllPromotionProductListData: data,
    getAllPromotionProductListError: error,
    getAllPromotionProductListLoading: isLoading,
    getAllPromotionProductListMutate: mutate,
  };
};
