import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {},
  toMultipleSelect: {
    width: '100%',
    minWidth: 200,
    borderRadius: 4,
    padding: '0px 8px',
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& .MuiFormControl-root': {
      height: 'auto',
    },
    '& .MuiInputBase-root': {
      height: '100%',
    },
    '& .MuiInputBase-input': {
      boxSizing: 'border-box',
    },
  },
}));
