import React from "react";
import { Box, IconButton, Modal, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { useFilter } from "utils/filter";
import { TImageSection } from "../../types";
import { FabricCanvas } from "../FabricCanvas";

interface Props {
  open: boolean;
  handleClose: () => void;
  currentViewItem: TImageSection;
  reload: Function;
}

export const PopupSVGOverlay = ({ open, handleClose, currentViewItem, reload }: Props) => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, updateFilter] = useFilter("tagProductOptions");

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Box className={classes.content}>
        <Box className={classes.header}>
          <Typography className={classes.title}>Image Overlay Editor</Typography>
          <IconButton className={classes.iconButton} onClick={handleClose}>
            <img src="/images/buttonIcons/close-button.png" alt="" />
          </IconButton>
        </Box>
        <hr className={classes.hr} />
        <FabricCanvas
          reload={reload}
          handleClose={handleClose}
          updateFilter={updateFilter}
          currentViewItem={currentViewItem}
        />
      </Box>
    </Modal>
  );
};
