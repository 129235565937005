import { useMutation } from 'react-query';
import useApi from 'utils/api/useApi';
import { useSnackbar } from 'notistack';
import { useFilter } from 'utils/filter';

export interface Body {
  title: string;
}

export const usePostCreateNewListing = (
  resetData: () => void,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useFilter('listing');
  const api = useApi();
  const createNewListing = async (body: Body) => {
    const res = await api.path('create/listing').post({ body });
    if (res?.data?.status === 0) {
      resetData();
      setOpen(false);
      setFilter({
        ...filter,
        offset: 0,
      });
      enqueueSnackbar('Create new listing successfully.', {
        variant: 'success',
      });
      return res?.data;
    } else {
      enqueueSnackbar('Create new listing failed!', {
        variant: 'error',
      });
    }
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => createNewListing(body));

  return {
    createNewListingData: data,
    createNewListingError: error,
    createNewListingLoading: isLoading,
    createNewListingMutate: mutate,
  };
};
