import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";

export enum ProductInfoType {
  material_ingredient_care = "material_ingredient_care",
  features = "features",
  certificate = "certificate"
}

interface PostBody {
  sub_component_img: File;
  sub_component_link: string;
  sub_component_desc: string;
  component_type: ProductInfoType;
}

interface Body {
  body: PostBody;
  product_type_id: number;
}

export const useCreateProductTypeInfo = () => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const createProductTypeInfo = async (body: Body) => {
    let formData = new FormData();
    formData.append("sub_component_img", body.body.sub_component_img);
    formData.append("sub_component_link", body.body.sub_component_link);
    formData.append("sub_component_desc", body.body.sub_component_desc);
    formData.append("component_type", body.body.component_type);
    const res = await api
      .path("item_class/product_info/create", {item_class_id: body.product_type_id})
      .multipost({body: formData});
    if (res?.data?.status === 0) {
      enqueueSnackbar("New item created!", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(`Create new item faiiled! ${res?.data?.message}`, {
        variant: "error",
      });
    }
    return res?.data
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => createProductTypeInfo(body));

  return {
    createProductTypeInfoData: data,
    createProductTypeInfoError: error,
    createProductTypeInfoLoading: isLoading,
    createProductTypeInfoMutate: mutate,
  };
};
