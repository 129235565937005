import { Box, Typography, Button, CircularProgress } from "@material-ui/core";
import Slider, { Settings } from "react-slick";
import { useStyles } from "./styles";
import React, { useEffect, useState } from "react";
import { TImageSection } from "./types";
import { formStructure } from "./configs";
import { InputGroup, SaveAndCancel } from "components";
import useFormHandler from "utils/forms/useFormHandler";
import Placeholder from "assets/images/placeholder/placeholder.svg";
import { useDeleteAsset } from "views/Frontend/ListingDetail/apis/useDeleteAsset";
import { usePatchAsset } from "views/Frontend/ListingDetail/apis/usePatchAsset";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { PopupSVGOverlay } from "./components";
import { ReactComponent as ArrowLeft } from "assets/images/icons/arrow_left.svg";
import { ReactComponent as ArrowRight } from "assets/images/icons/arrow_right.svg";
import { isValidVideoPath } from "utils/formatting";
import ReactPlayer from "react-player";
import PopupDropImage from "./components/PopupDropImage";
import { OwnerType } from "types";

interface IProps {
  headerLabel: string;
  id: string;
  reload: Function;
  owner_type: keyof typeof OwnerType;
  isLoading: boolean;
}

const ProductDetailImagePost = ({
  headerLabel,
  id,
  reload,
  owner_type,
  isLoading,
}: IProps) => {

  const classes = useStyles();

  const items: TImageSection[] = useSelector(
    (state: RootState) => state.dataDraftReducer.Asset
  );

  const [isPlaying, setIsPlaying] = useState<number | null>(null);

  const [open, setOpen] = useState({
    dropImage: false,
    svgOverlay: false,
  });

  const [currentViewItem, setCurrentViewItem] = useState<TImageSection>(
    items[0]
  );

  const { deleteAssetLoading, deleteAssetMutate } = useDeleteAsset(reload);
  
  const { patchAssetLoading, patchAssetMutate } = usePatchAsset({});

  interface ArrowProps {
    onClick?: () => void;
  }

  const PrevArrow = ({ onClick }: ArrowProps) => (
    <button className="slick-prev" onClick={onClick}>
      <ArrowLeft />
    </button>
  );

  const NextArrow = ({ onClick }: ArrowProps) => (
    <button className="slick-next" onClick={onClick}>
      <ArrowRight />
    </button>
  );

  const settings: Settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: items?.length > 1,
    swipeToSlide: items?.length > 1,
    autoplay: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fields, inputs, errors, setInputs] = useFormHandler(formStructure);

  const handlePause = () => {
    setIsPlaying(null);
  };

  const handlePlay = (item: TImageSection) => {
    setIsPlaying(item?.id);
  };
  const handleGetCurrentData = (index: number) => {
    setCurrentViewItem(items?.[index]);
    setInputs({
      name: items?.[index]?.name ?? "",
      description: items?.[index]?.description ?? "",
    });
    setIsPlaying(null);
  };

  const handleCancel = () => {
    let index = -1;
    for (let i = 0; i < items?.length; i++) {
      if (items?.[i]?.id === currentViewItem?.id) {
        index = i;
        break;
      }
    }
    handleGetCurrentData(index);
  };

  /**
   * The function `handlePatchAsset` is used to update an asset by sending a PATCH request with the
   * updated data.
   */
  const handlePatchAsset = () => {
    const formData = new FormData();
    formData.append("asset_id", `${currentViewItem?.id}`);
    formData.append("name", `${inputs?.name}`);
    formData.append("description", `${inputs?.description}`);
    patchAssetMutate(formData);
  };

  // Function used to handle rendering of images or videos based on the provided path
  const content = (item: TImageSection) => {
    if (isValidVideoPath(item?.uri)) {
      return (
        <ReactPlayer
          url={item?.uri}
          playing={isPlaying === item?.id}
          className={classes.reactPlayer}
          width={"100%"}
          height={"auto"}
          controls={true}
          onPause={handlePause}
          onPlay={() => handlePlay(item)}
        />
      );
    } else {
      return (
        <img
          src={item?.uri || Placeholder}
          alt={item?.name ?? "product image"}
          className={classes.image}
        />
      );
    }
  };
  useEffect(() => {
    if (items?.length === 0) return;
    handleGetCurrentData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  // When entering the page, the function to retrieve values from the cart has not been called yet, so the Redux store does not have the values. The 'isLoading' dependency in the 'useEffect' 
  // ensures that the API call is successful before the values are stored in Redux.
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography className={classes.title}>{headerLabel}</Typography>
        <img
          onClick={() => setOpen((prev) => ({ ...prev, dropImage: true }))}
          src="/images/buttonIcons/Plus_icon.png"
          alt="plus icon"
          style={{ paddingLeft: 30, borderLeft: "1px solid #ccc" }}
        />
      </Box>
      {isLoading ? (
        <Box style={{ display: "flex",justifyContent: "center",alignItems: "center", width: "100%",height: "227px"}}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          {items?.length > 0 && (
            <Slider
              className={classes.slider}
              {...settings}
              afterChange={handleGetCurrentData}
            >
              {items?.map((item) => {
                return (
                  <Box key={item?.id} className={classes.imageWrapper}>
                    {content(item)}
                  </Box>
                );
              })}
            </Slider>
          )}
          {!items || items?.length === 0 ? (
            <Box className={classes.noItem}>No Image Selected</Box>
          ) : null}
          {!items || items?.length === 0 ? null : (
            <>
              <div
                className={classes.wrapOverlay}
                style={{
                  visibility: isValidVideoPath(currentViewItem?.uri)
                    ? "hidden"
                    : "visible",
                }}
              >
                <Button
                  className={classes.addSVGOverlay}
                  onClick={() =>
                    setOpen((prev) => ({ ...prev, svgOverlay: true }))
                  }
                >
                  Add SVG Overlay
                </Button>
              </div>
              <Box className={classes.inputsSection}>
                <InputGroup fields={fields} />
              </Box>
              <Box className={classes.buttonWrapper}>
                <Button
                  className={classes.deleteButton}
                  disabled={deleteAssetLoading || items?.length === 0}
                  onClick={() => {
                    deleteAssetMutate(currentViewItem?.id);
                  }}
                >
                  Delete
                </Button>
                <SaveAndCancel
                  disabledSave={patchAssetLoading}
                  disabledCancel={patchAssetLoading}
                  functionCancel={handleCancel}
                  functionSave={handlePatchAsset}
                />
              </Box>
            </>
          )}
        </>
      )}

      <PopupDropImage
        reload={reload}
        id={id}
        open={open.dropImage}
        handleClose={() => setOpen((prev) => ({ ...prev, dropImage: false }))}
        owner_type={owner_type}
      />
      <PopupSVGOverlay
        reload={reload}
        currentViewItem={currentViewItem}
        open={open.svgOverlay}
        handleClose={() => setOpen((prev) => ({ ...prev, svgOverlay: false }))}
      />
    </Box>
  );
};

export default ProductDetailImagePost;
