import { Box, Typography, makeStyles } from "@material-ui/core";
import { useCustomQuillJs } from "hooks";
import React from "react";

interface Props {
  field: any;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  quillStyle: {
    "& .ql-container": { height: 136 },
  },
  title: {
    color: "#A5A9B9",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "14px",
  },
}));

export const TextEditor = ({ field }: Props) => {
  const classes = useStyles();
  const reactQuillJs = useCustomQuillJs();
  // Register custom fonts

  const handleOnChange = (value: string) => {
    field?.onChange(null, value);
  };

  /**
   * Custom React Quill
   */

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{field?.spec?.label}</Typography>
      {reactQuillJs({
        className: classes.quillStyle,
        theme: "snow",
        value: field?.value,
        placeholder: field?.spec?.placeholder,
        onChange: handleOnChange,
      })}
    </Box>
  );
};
