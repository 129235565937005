import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import type { Body } from "./useCreateCarouselBanner";

enum ViewSizeEnum {
  desktop = 'desktop',
  mobile = 'mobile'
}

enum BannerTypeEnum {
  home_carousel = 'home_carousel',
  home_banner_1 = 'home_banner_1',
  home_banner_2 = 'home_banner_2',
  age_group_toddler = 'age_group_toddler',
  age_group_nursery = 'age_group_nursery',
  age_group_kindergarden  = 'age_group_kindergarden'
}

enum DeleteAtEnum {
  delete_true = 1,
  delete_false = 0
}

interface IProps {
  viewSize?: ViewSizeEnum;
  bannerType?: BannerTypeEnum;
  layoutType?: number;
  banner?: File,
  cta_link?: string;
  title?: string;
  description?: string;
  cta_text?: string;
  group_id?: number;
  asset_id?: number;
  deletedAt?: DeleteAtEnum;
}

export const usePatchBanner = () => {
  const api = useApi();
  const patchBanner = async (body: IProps) => {
    const res = await api
      .path("asset/banner_carousel")
      .patchformdata({body: body});
    return res?.data
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => patchBanner(body));

  return {
    patchBannerData: data,
    patchBannerError: error,
    patchBannerLoading: isLoading,
    patchBannerMutate: mutate,
  };
};
