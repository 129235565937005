import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useFilter } from "utils/filter";

export const useGetOrders = () => {
  const api = useApi();
  const [filter, ] = useFilter("orders")
  const getOrders = async () => {
    const res = await api
      .path("order/list", {},{...filter})
      .get();
    return res?.data?.data
  };

  const { data, isLoading, error, mutate } = useMutation(() => getOrders());

  return {
    getOrdersData: data,
    getOrdersError: error,
    getOrdersLoading: isLoading,
    getOrdersMutate: mutate,
  };
};
