import { makeStyles } from "@material-ui/core";

interface Props {
  sortUp: boolean;
  sortDown: boolean;
}

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
  },
  sortIconUp: (props: Props) => ({
    width: 10,
    height: 10,
    cursor: "pointer",
    "& svg": {
      fill: props.sortUp ? "#0D5AE5" : "#24243A",
      height: "10px",
    },
  }),
  sortIconDown: (props: Props) => ({
    width: 10,
    height: 10,
    cursor: "pointer",
    "& svg": {
      fill: props.sortDown ? "#0D5AE5" : "#24243A",
      height: "10px",
    },
  }),
}));
