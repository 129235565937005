import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";

const customFonts = ["Nunito"];
const Quill = ReactQuill.Quill;
// Custom Fonts
let Font = Quill.import("formats/font");
Font.whitelist = customFonts;
Quill.register(Font, true);

export const useCustomQuillJs = () => {
  const modules = {
    toolbar: [
      [
        {
          font: customFonts,
        },
        { header: [1, 2, 3, 4, 5, 6, false] },
        { size: [] },
        "bold",
        "italic",
        "underline",
        "strike",
        { color: [] },
        { background: [] },
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { script: "sub" },
        { script: "super" },
        "link",
        "image",
        "video",
      ],
    ],
  };

  const formats = [
    "font",
    "header",
    "bold",
    "size",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "list",
    "indent",
    "script",
    "link",
    "image",
    "video",
  ];

  const reactQuillJs = (props: ReactQuill.ReactQuillProps) => {
    return (
      <ReactQuill
        {...props}
        modules={props.modules || modules}
        formats={props.formats || formats}
      />
    );
  };

  return reactQuillJs;
};
