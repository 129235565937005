import { LinearProgress, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DashboardGuard } from "components";
import PropTypes from "prop-types";
import React, { Suspense, useState } from "react";
import { renderRoutes } from "react-router-config";
import { NavBar, TopBar } from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  topBar: {
    zIndex: 2,
    position: "relative",
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  navBar: {
    zIndex: 3,
    flex: "0 0 auto",
  },
  content: {
    flex: "1 1 auto",
    width: "100%",
    marginLeft: 0,
    [theme.breakpoints.up(1280)]: {
      marginLeft: "300px",
      width: "calc(100% - 300px)",
    },
  },
  mainContent: {
    // overflowY: "auto",
    flex: "1 1 auto",
    width: "100%",
  },
  contentGrid: {
    display: "contents",
  },
  gridContainer: {
    // height: "100%"
  },
  wrapNavBar: {
    position: "fixed",
    width: "100%",
    [theme.breakpoints.up(1280)]: {
      width: "300px",
    },
  },
}));

const Dashboard = props => {
  const { route } = props;

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  return (
    <div className={classes.root}>
      <DashboardGuard>
        {/* <PrivilegeGuard hidden={false}> */}
        <Box container spacing={0} className={classes.gridContainer}>
          <Box className={classes.wrapNavBar}>
            <NavBar className={classes.navBar} onMobileClose={handleNavBarMobileClose} openMobile={openNavBarMobile} />
          </Box>
          <Box className={classes.content}>
            <TopBar className={classes.topBar} onOpenNavBarMobile={handleNavBarMobileOpen} />
            <div className={classes.container}>
              <main className={classes.mainContent}>
                <Suspense fallback={<LinearProgress />}>{renderRoutes(route.routes)}</Suspense>
              </main>
            </div>
          </Box>
        </Box>

        {/* </PrivilegeGuard> */}
      </DashboardGuard>
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
