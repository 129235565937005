import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";

export const usePatchLandingPageBanner = (resetData: any) => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const patchLandingPage = async (body: any) => {
    try {
      const res = await api
        .path("landing-page/update")
        .patchformdata({ body });
      if (res?.data.status === 0) {
        enqueueSnackbar("Update banner successfully!", {
          variant: "success",
        });
        resetData();
      } else {
        enqueueSnackbar("Update banner failed!", {
          variant: "error",
        });
      }
      return res?.data;
    } catch (error) {
      enqueueSnackbar(`Update banner failed!`, {
        variant: "error",
      });
    }
  };

  const { data, isLoading, error, mutate } = useMutation((body: any) =>
    patchLandingPage(body)
  );

  return {
    patchLandingPageData: data,
    patchLandingPageError: error,
    patchLandingPageLoading: isLoading,
    patchLandingPageMutate: mutate,
  };
};
