export const TYPES = {
  UPDATE: "FILTER_UPDATE",
  RELOAD: "FILTER_RELOAD",
} as const;

export type FilterKeys =
  | "default"
  | "customers"
  | "order_history"
  | "order_items"
  | "orders"
  | "products"
  | "product"
  | "classifications"
  | "subClassifications"
  | "frequentlyAskedQuestions"
  | "productTypeReviews"
  | "allReviews"
  | "inventory"
  | "classificationCharacter"
  | "classificationCategory"
  | "classificationProductType"
  | "classificationProduct"
  | "point_history"
  | "pages"
  | "pageElements"
  | "navigation"
  | "promotionGroups"
  | "promotionProducts"
  | "promotionProductsHomeNull"
  | "vouchers"
  | "qa"
  | "banner"
  | "getProduct"
  | "getCampaign"
  | "getProductType"
  | "getCustomersDetail"
  | "getCustomerOrders"
  | "vouchersIssued"
  | "getItemClass"
  | "listing"
  | "getListing"
  | "getProductList"
  | "getProductGiftList"
  | "getProductListSearch"
  | "getClassificationItemClass"
  | "getClassificationItemSubClass"
  | "getClassificationItemCategory"
  | "getClassificationItemType"
  | "ClassificationItemClassItemCategory"
  | "ClassificationItemClassItemType"
  | "ClassificationItemSubClassItemClass"
  | "ItemType"
  | "ItemCategory"
  | "ItemClass"
  | "ItemSubClass"
  | "ItemGroup"
  | "Color"
  | "Group"
  | "IsGift"
  | "HasGift"
  | "IsAccessory"
  | "HasAccessory"
  | "Listing"
  | "BannerGroup"
  | "LandingPage"
  | "review"
  | "reviewSearch"
  | "QA"
  | "QASearch"
  | "getPromotionProducts"
  | "getAllPromotionProducts"
  | "getBundleProducts"
  | "getAllBundleProducts"
  | "getPromotionSummary"
  | "OptionAddOns"
  | "AccessoriesAddOns"
  | "SearchFromVMOS"
  | "Vouchers"
  | "promotions"
  | "getVmosCatalogue"
  | "getAccount"
  | "VouchersIssued"
  | "tagProduct"
  | "tagProductOptions"
  | "Webpages"
  | "Asset"
  | "LandingPageV2"
  | "LadingPageListing"
  | "getAllConditionPromotionProducts"
  | "collection"
  | "collectionAsset"
  | "collectionProduct"
  | "collectionAllProduct"
  | "promotionAllProduct"
  | "promotionAllBundleProduct"
  | "promotionAsset"
  | "getClassificationItemBrand"
  | "brandAsset"
  | "itemClassAsset"
  | "itemTypeAsset"
  | "itemCategoryAsset"
  | "itemSubClassAsset"
  | "getClassificationItemGroup"
  | "itemGroupAsset";

export type FilterUpdateProps = {
  key: string;
  value: any;
};

export type FilterReloadProps = {
  key: any;
};

export function update(props: FilterUpdateProps) {
  return {
    type: TYPES.UPDATE,
    props,
  };
}

export function reload(props: FilterReloadProps) {
  return {
    type: TYPES.RELOAD,
    props,
  };
}
