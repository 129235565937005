import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";

enum type {
  percent = "percent",
  gift = "gift",
  value = "value"
}

interface Body {
  name: string;
  discount_value: string | number;
  discount_type: type;
  handle: string;
  start_at: string;
  end_at: string;
  description: string;
  published: boolean;
}

export const useCreateNewPromotion = () => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const createNewPromotion = async (body: Body) => {
    const res = await api
      .path("group/promotion_group/create")
      .post({ body });
      if (res?.data?.status === 0) {
        enqueueSnackbar("New promotion created!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`Create new promotion faiiled! ${res?.data?.message}`, {
          variant: "error",
        });
      }
    return res?.data?.data
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => createNewPromotion(body));

  return {
    createNewPromotionData: data,
    createNewPromotionError: error,
    createNewPromotionLoading: isLoading,
    createNewPromotionMutate: mutate,
  };
};
