import { makeStyles } from "@material-ui/core";

interface IProps {
  height?: number;
}

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    overflow: "auto",
    "& .MuiTableCell-root": {
      padding: 10,
    },
  },
  tableContainer: (props: IProps) => ({
    maxHeight: props?.height ?? 500,
    overflow: "auto",
    minWidth: 1200,
  }),
  theader: {
    border: "1px solid #ccc",
    whiteSpace: "nowrap",
  },
  headerItemWrapper: {
    padding: 4,
  },
  headerItems: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#24243A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  toShowCell: {
    textAlign: "center",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#24243A",
    "& .MuiCheckbox-root": {
      color: "#0D5AE5",
    },
    "& .MuiIconButton-root.Mui-disabled": {
      color: "gray",
    },
  },
  toRedirectCell: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  toDeleteCell: {
    cursor: "pointer",
    textAlign: "center",
  },
  toSelect: {
    width: "100%",
    borderRadius: 4,
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
  },
  toCheck: {
    "& .MuiCheckbox-root": {
      color: "#0D5AE5",
    },
    "& .MuiIconButton-root.Mui-disabled": {
      color: "gray",
    },
  },
  toMultipleSelect: {
    width: "100%",
    minWidth: 200,
    borderRadius: 4,
    padding: "0px 8px",
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
    "& .MuiFormControl-root": {
      height: "auto",
    },
    "& .MuiInputBase-root": {
      height: "100%",
    },
    "& .MuiInputBase-input": {
      boxSizing: "border-box",
    },
  },
  spinner: {
    textAlign: "center",
    width: "100%",
    marginTop: "8px",
  },
}));
