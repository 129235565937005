import { CircularProgress, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "store";
import { DataDraftKeys } from "store/draftdata/actions";
import { FilterKeys } from "store/filter/actions";
import { OptionsStoreKeys } from "store/optionsstore/actions";
import { RootState } from "store/reducers";
import { useStyles } from "./styles";
import { useFilter } from "utils/filter";
import { useDebouncedCallback } from "use-debounce";

interface IProps {
  //value key of select option
  valueKey: string;
  //label key of select option
  labelKey: string;
  //key of options in optionsstore
  optionsStoreKey: OptionsStoreKeys;
  //to find options to show by key: id, name, ... (unique value)
  findOptionByKey: string;
  //value to compare and exactly options you want
  findOptionByKeyValue: any;
  //draft key to update data
  draftKey: DataDraftKeys;
  defaultOption: any;
  hardOptions?: any[];
  optionsFilterKey?: FilterKeys;
  searchBy: string;
}

const AutoComplete = ({
  valueKey,
  labelKey,
  optionsStoreKey,
  findOptionByKey,
  findOptionByKeyValue,
  draftKey,
  defaultOption,
  hardOptions,
  optionsFilterKey,
  searchBy,
}: IProps) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useFilter(optionsFilterKey ?? "default");
  const storeOptions =
    useSelector(
      (state: RootState) => state.optionsStoreReducer[optionsStoreKey]
    )?.filter((item: any) => item[findOptionByKey] === findOptionByKeyValue)[0]
      ?.items ?? [];
  const options = hardOptions ?? storeOptions;
  const drafts = useSelector(
    (state: RootState) => state.dataDraftReducer[draftKey]
  );
  const currentDraft = drafts?.filter(
    (item: any) => item[findOptionByKey] === findOptionByKeyValue
  )[0];
  const classes = useStyles();
  const handleDebounceSearch = useDebouncedCallback((value) => {
    setFilter({
      ...filter,
      [searchBy]: value,
      [findOptionByKey]: findOptionByKeyValue,
    });
  }, 1000);
  const handleOnChangeOption = (label?: string, value?: string | number) => {
    if (!value && filter[searchBy]) {
      handleDebounceSearch(undefined);
    }
    const newDrafts = drafts.map((item: any) => {
      if (item[findOptionByKey] === findOptionByKeyValue) {
        return {
          ...item,
          [valueKey]: value,
          [labelKey]: label,
        };
      }
      return item;
    });
    dispatch(Actions.DataDraftList.update({ key: draftKey, value: newDrafts }));
  };

  return (
    <Autocomplete
      className={classes.toSelect}
      value={
        currentDraft
          ? {
              [labelKey]: currentDraft?.[labelKey],
              [valueKey]: currentDraft?.[valueKey],
            }
          : defaultOption
      }
      getOptionSelected={(option: any) => option}
      getOptionLabel={(option: any) => `${option[labelKey] ?? ""}`}
      options={hardOptions ? options : [...options, defaultOption]}
      loading={false}
      onChange={(e, value) =>
        handleOnChangeOption(value?.[labelKey], value?.[valueKey])
      }
      onFocus={() => {
        if (options?.length === 0 || !options) {
          setFilter({
            ...filter,
            [searchBy]: undefined,
            [findOptionByKey]: findOptionByKeyValue,
          });
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          onChange={(e) => handleDebounceSearch(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {false ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoComplete;
