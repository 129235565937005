import React, { useCallback, useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { makeStyles } from "@material-ui/core";
import useElementWidth from "hooks/useElementWidth";
import useFormHandler from "utils/forms/useFormHandler";
import { formStructure } from "./configs";
import { InputGroup, SaveAndCancel } from "components";
import { usePostCreateAsset } from "views/Frontend/ListingDetail/apis/usePostCreateAsset";
import { usePatchAsset } from "views/Frontend/ListingDetail/apis/usePatchAsset";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  controlGrp: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    marginBottom: "20px",
  },
  canvasElement: {
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
    width: "fit-content",
    flex: 1,
    maxWidth: 500,
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#656565",
      borderRadius: "10px",
      "-webkit-box-shadow": "inset 0px 7px 6px rgb(0 0 0 / 30%)",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0px 0px 6px rgb(0 0 0 / 30%)",
      backgroundColor: "#D9D9D9",
    },
  },
  editButton: {
    display: "flex",
    height: "40px",
    padding: "8px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    border: "2px solid #333",
    background: "#FFF",
    width: "fit-content",
  },
  bottomElement: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    height: "calc(100% - 164px)",
  },
  inputGrp: {
    width: "330px",
  },
  bottomRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
  },
}));

export const FabricCanvas = ({
  handleClose,
  updateFilter,
  currentViewItem,
  reload,
}) => {
  const classes = useStyles();
  const [canvas, setCanvas] = useState(null);
  const [onSelect, setOnSelect] = useState(false);
  const [elementRef, elementWidth] = useElementWidth();
  const canvasRef = useRef();
  const firstMount = useRef(true);
  // eslint-disable-next-line no-unused-vars
  const [fields, inputs, _, setInputs, setErrors, setDirtys] =
    useFormHandler(formStructure);

  const { postCreateAssetMutate, postCreateAssetLoading } = usePostCreateAsset({
    reload,
    handleClose,
  });
  const { patchAssetMutate } = usePatchAsset({
    reload,
    handleClose,
    isUpdateOverlay: true,
  });

  /**
   * The `handleMutate` function takes the canvas data, creates a blob file, appends necessary form
   * data, and either patches or creates an asset.
   */
  const handleMutate = useCallback(() => {
    const canvasData = canvas.toSVG();

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(canvasData, "text/xml");

    // Remove width and height attributes from the SVG tag
    const svgElement = xmlDoc.getElementsByTagName("svg")[0];
    svgElement.removeAttribute("width");
    svgElement.removeAttribute("height");

    // Remove the <g> element containing <image>
    const images = xmlDoc.getElementsByTagName("image");
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const gParent = image.parentNode;
      if (gParent.tagName === "g") {
        gParent.parentNode.removeChild(gParent);
      }
    }

    const file = new Blob([new XMLSerializer().serializeToString(xmlDoc)], {
      type: "image/svg+xml",
    });

    if (!file.type.match(/image.*/)) throw new Error("file not image");
    const formData = new FormData();
    formData.append("file", file);
    if (
      currentViewItem?.children?.[0]?.assoc_type === "product_detail_overlay"
    ) {
      formData.append("asset_id", currentViewItem?.children?.[0]?.id);
      patchAssetMutate(formData);
    } else {
      formData.append(
        "filename",
        `${currentViewItem?.id}-product_detail_overlay`
      );
      formData.append("assoc_type", "product_detail_overlay");
      formData.append("owner_type", "product_detail");
      formData.append("multiple", "false");
      formData.append("id", currentViewItem?.id);
      postCreateAssetMutate(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  const removeSelected = () => {
    const focusedElement = canvas.getActiveObject();

    if (focusedElement) {
      canvas.remove(focusedElement);
    }
  };

  const resetInputGroup = () => {
    setInputs({});
    setErrors({});
    setDirtys({});
  };

  const addBoxWithText = useCallback(() => {
    const rect = new fabric.Rect({
      left: 122,
      top: elementRef.current?.scrollTop + 192,
      width: 194,
      height: 170,
      fill: "rgba(0, 0, 0, 0)",
      strokeWidth: 1,
      stroke: "black",
    });

    const text = new fabric.IText("{}", {
      left: rect.left + 10,
      top: rect.top + 10,
      width: 130,
      height: 80,
      fontSize: 0.001,
      fill: "rgba(0, 0, 0, 0)",
      opacity: 0,
    });

    const group = new fabric.Group([rect, text], {
      left: rect.left,
      top: rect.top,
    });

    canvas.add(group);
    canvas.setActiveObject(group);
    resetInputGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef, canvas]);

  const updateGroupText = (groupText) => {
    const focusedObject = canvas.getActiveObject();

    if (focusedObject && focusedObject.type === "group") {
      const textObject =
        focusedObject.getObjects()?.[1] ??
        focusedObject
          .getObjects()
          .find((obj) => obj.type === "i-text" || obj.type === "textbox");

      if (textObject) {
        textObject.set({ text: groupText });
        canvas.requestRenderAll();
      }
    }
  };

  /* This `useEffect` hook is responsible for initializing and updating the canvas element based on the
  `elementWidth` value. */
  useEffect(() => {
    if (elementWidth > 0) {
      const newCanvas = new fabric.Canvas(canvasRef.current, {
        width: 500,
        height: 617,
      });

      fabric.Image.fromURL(currentViewItem?.uri, (img) => {
        if (img.width > elementWidth) {
          newCanvas.setDimensions({
            width: elementWidth,
            height: (elementWidth * img.height) / img.width,
          });
        } else {
          newCanvas.setDimensions({ width: img.width, height: img.height });
        }

        newCanvas.renderAll();
      });

      if (currentViewItem?.children?.[0]?.uri) {
        fabric.loadSVGFromURL(
          currentViewItem?.children?.[0]?.uri,
          (objects) => {
            let currentGroup = null;
            if (objects !== null ) {
              objects.forEach((object) => {
                if (object instanceof fabric.Rect) {
                  if (currentGroup) {
                    // Add the current group to the canvas
                    newCanvas.add(currentGroup);
                  }
                  // Create a new group and add the rect to it
                  currentGroup = new fabric.Group([object]);
                } else if (object instanceof fabric.Text) {
                  // Add the text to the current group
                  currentGroup.add(object);
                }
              });
            }
            if (currentGroup) {
              // Add the last group to the canvas
              newCanvas.add(currentGroup);
            }
            newCanvas.renderAll();
          }
        );
      }

      setCanvas(newCanvas);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementWidth]);

  useEffect(() => {
    if (canvas) {
      // Add click event listeners to objects
      canvas.on("mouse:up", (options) => {
        if (options.target) {
          setOnSelect(true);
          const focusedObject = canvas.getActiveObject();

          canvas.setActiveObject(options.target);
          if (focusedObject && focusedObject.type === "group") {
            const textObject = focusedObject.getObjects()[1]; // Assuming the text object is at index 1
            if (textObject) {
              setInputs(JSON.parse(textObject.text));
            }
          }
          canvas.renderAll();
        } else {
          setOnSelect(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      return;
    }
    if (inputs?.product_id?.id) {
      updateFilter({ product_id: inputs?.product_id?.id });
    }
    updateGroupText(JSON.stringify(inputs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  return (
    <div className={classes.root}>
      <div className={classes.controlGrp}>
        <button className={classes.editButton} onClick={addBoxWithText}>
          Add New Box
        </button>
        <button className={classes.editButton} onClick={removeSelected}>
          Remove Selected
        </button>
      </div>
      <div className={classes.bottomElement}>
        <div className={classes.canvasElement} ref={elementRef}>
          <img
            className={classes.backgroundImage}
            src={currentViewItem?.uri}
            alt="background"
          />
          <canvas ref={canvasRef} style={{ border: "1px solid #000" }}></canvas>
        </div>
        <div className={classes.bottomRight}>
          <div className={classes.inputGrp}>
            {onSelect && <InputGroup fields={fields} />}
          </div>
          <SaveAndCancel
            disabledSave={postCreateAssetLoading}
            justifyContent="center"
            functionCancel={handleClose}
            functionSave={handleMutate}
          />
        </div>
      </div>
    </div>
  );
};
