import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";

interface IProps {
  functionSave: Function;
  functionCancel: Function;
  functionGenerateContent?: Function;
  disabledSave?: boolean;
  disabledCancel?: boolean;
  disableGenerateContent?: boolean;
  disableGenerateReview?: boolean;
  functionGenerateReview?: Function;
  labelCancel?: string;
  labelSave?: string;
  labelGenerateContent?: string;
  labelGenerateReview?: string;
  justifyContent?: string;
  fullWidth?: boolean;
}

interface ICSSPropsType {
  justifyContent?: string;
  fullWidth?: boolean;
}

const useStyles = makeStyles(theme => ({
  container: (props: ICSSPropsType) => ({
    display: "flex",
    justifyContent: props?.justifyContent ?? "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      marginBottom: "10px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  }),
  root: (props: ICSSPropsType) => ({
    marginTop: "24px",
    maxWidth: props?.fullWidth ? "100%" : "592px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
    gap: "20px",
  }),
  saveButton: {
    padding: "6px 24px",
    background: "#0D5AE5",
    borderRadius: "5px",
    color: "#fff",
    textTransform: "none",
    fontWeight: 700,
    lineHeight: "14px",
    "&:hover": {
      background: "#4257f5",
    },
  },
  generateContent: {
    padding: "6px 24px",
    background: "#0D5AE5",
    borderRadius: "5px",
    color: "#fff",
    textTransform: "none",
    fontWeight: 700,
    lineHeight: "14px",
    "&:hover": {
      background: "#4257f5",
    },
  },
  cancelButton: {
    padding: "6px 24px",
    border: "2px solid #333333",
    borderRadius: "5px",
    color: "#333333",
    textTransform: "none",
    lineHeight: "14px",
    fontWeight: 700,
  },
}));

const SaveAndCancel = ({
  functionSave,
  functionCancel,
  functionGenerateContent,
  disabledSave,
  disabledCancel,
  labelCancel,
  labelSave,
  labelGenerateContent,
  justifyContent,
  fullWidth,
  disableGenerateContent,
  functionGenerateReview,
  labelGenerateReview,
  disableGenerateReview,
}: IProps) => {
  const classes = useStyles({
    fullWidth,
    justifyContent,
  });
  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <Button className={classes.cancelButton} onClick={() => functionCancel()} disabled={disabledCancel}>
          {labelCancel ?? "Cancel"}
        </Button>
        {labelGenerateReview && functionGenerateReview && (
          <Button
            className={classes.generateContent}
            onClick={() => functionGenerateReview()}
            disabled={disableGenerateReview}
          >
            {labelGenerateReview}
          </Button>
        )}
        {labelGenerateContent && functionGenerateContent && (
          <Button
            className={classes.generateContent}
            onClick={() => functionGenerateContent()}
            disabled={disableGenerateContent}
          >
            {labelGenerateContent}
          </Button>
        )}

        <Button className={classes.saveButton} onClick={() => functionSave()} disabled={disabledSave}>
          {labelSave ?? "Save"}
        </Button>
      </Box>
    </Box>
  );
};

export default SaveAndCancel;
