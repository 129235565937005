import { useMutation } from "react-query";
import useApi from "utils/api/useApi";

export const useGetGroupInfo = () => {
  const api = useApi();
  const getGroupInfo = async (body: Body) => {
    const res = await api
      .path("group/group", {}, {limit: 50, offset: 0, published: "all", sort: "id:desc"})
      .get();
    return res?.data?.data?.data
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => getGroupInfo(body));

  return {
    getGroupInfoData: data,
    getGroupInfoError: error,
    getGroupInfoLoading: isLoading,
    getGroupInfoMutate: mutate,
  };
};
