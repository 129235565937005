import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import { srcIcon } from "./iconComponentConfigs";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const IconComponent = (props) => {
  const { name, className, onClick } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <span
        id={name}
        className={clsx(classes.root, className)}
        onClick={onClick}
      >
        {srcIcon[name]}
      </span>
    </React.Fragment>
  );
};

export default IconComponent;
