import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px",
    backgroundColor: "#d8d8d8",
    borderRadius: "25px",
    margin: "8px",
    fontSize: "12px",
    fontWeight: "500",
  },
}));
const UnSaveAttributes = (props) => {
  const { active = false, dirtys = {}, name, model, values, setEditorState } = props;
  const classes = useStyles();
  const reload = () => {
    return "Your work will be lost.";
  };
  useEffect(() => {
    if (
      active ||
      (model &&
        Object.keys(dirtys).length !== 0 &&
        Object.keys(dirtys).some((key) => model && model[key] !== values[key]))
    ) {
      setEditorState && setEditorState(true);
      window.onbeforeunload = reload;
    } else {
      setEditorState && setEditorState(false);
      window.onbeforeunload = () => {};
    }
  }, [dirtys, model, active]); // eslint-disable-line
  useEffect(() => {
    return () => {
      window.onbeforeunload = () => {};
    };
  }, []);
  return (
    <React.Fragment>
      {(active ||
        (Object.keys(dirtys).length !== 0 &&
          Object.keys(dirtys).some(
            (key) => model && model[key] !== values[key]
          ))) && <span className={classes.root}>Unsaved</span>}
      <Prompt
        when={
          active ||
          (Object.keys(dirtys).length !== 0 &&
            Object.keys(dirtys).some(
              (key) => model && model[key] !== values[key]
            ))
        }
        message={(location) => {
          window.onpopstate = () => {};
          return `Are you sure you want to go to ${location.pathname}?\n ${name} has not saved.`;
        }}
      />
    </React.Fragment>
  );
};

export default UnSaveAttributes;
