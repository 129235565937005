import { AppBar, Button, colors, Hidden, IconButton, Toolbar, Typography, Box, Fade } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import {ReactComponent as Logo} from "assets/images/logo/white-kidztime-logo.svg";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Actions } from "store";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none"
  },
  toolbarRegular: {
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      minHeight: 56,
    }
  },
  toolbarGutters: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 16,
      paddingRight: 16
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  companyName: {
    color: "#FFF",
    fontSize: 14
  },
  logoLink: {
    display: "flex",
    alignItems: "center",
    padding: "0px 8px"
  },
  logo: {
    width: 42,
    maxHeight: 42
  },
  logoutButton: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    fontSize: 14
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
    fontSize: 18
  },
  expander: {
    color: "white",
    fontSize: "18px",
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  username: {
    color: "white",
    fontSize: "16px",
    marginRight: theme.spacing(1),
  },
  avatar: {
  	width: "35px",
  	height: "35px",
  	border: "2px solid #E4E4E4",
  	borderRadius: "100%",
    marginRight: theme.spacing(1),
    position: "relative",
    top: "2px"
  },
  profileSection: {
    position: "relative",
    cursor: "pointer"
  },
  profileCollapse: {
    backgroundColor: "white",
    border: "1px solid #C9D1D9",
    borderTop: "none",
    position: "absolute",
    marginLeft: theme.spacing(1),
    bottom: "-46px",
    zIndex: 1,
    borderRadius: "0px 0px 6px 6px",
  },
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.Session.user);
  const [open, setOpen] = useState(false);


  const handleLogout = async () => {
    dispatch(Actions.Session.logout());
  };

  const handleExpand = async () => {
    setOpen(!open);
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary" >
      <Toolbar classes={{regular: classes.toolbarRegular, gutters: classes.toolbarGutters}}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Typography variant="h6" className={classes.companyName}></Typography>
        </Hidden>
        <Hidden lgUp>
          <RouterLink to="/" className={classes.logoLink}>
            <Logo className={classes.logo} />
          </RouterLink>
        </Hidden>
        <Hidden mdDown>
        <Box display="flex" alignItems="center" className={classes.profileSection} onClick={handleExpand}>
          <Box>
            {open ? (
              <ExpandLessIcon className={classes.expander}/>
            ) : (
              <ExpandMoreIcon className={classes.expander}/>
            )}
          </Box>
          <Box>
            <Typography className={classes.username}>{user && user.firstname} {user && user.lastname}</Typography>
          </Box>
          <Box>
            <img className={classes.avatar} src="/images/avatars/placeholder_avatar.png" alt="placeholder_avatar"/>
          </Box>
          <Fade in={open}>
            <div className={classes.profileCollapse}>
              <Button
              className={classes.logoutButton}
              color="inherit"
              onClick={handleLogout}>
                <InputIcon className={classes.logoutIcon} />
                Sign Out
              </Button>
            </div>
          </Fade>
        </Box>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}>
            <InputIcon className={classes.logoutIcon} />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
