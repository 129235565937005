/* eslint-disable no-undef */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useRouter } from 'utils/tools';
import { makeStyles, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';

const NODE_ENV = process.env.NODE_ENV;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '48px',
    color: '#333333',
  },
  pagesNav: {
    marginBottom: theme.spacing(5),
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const Page = props => {
  const { title, children, dashboard, className, pagesNav, buttonTop, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return;
    }

    if (window.gtag) {
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: router.location.pathname,
        page_name: title,
      });
    }
  }, [title, router]);

  const text = pagesNav ? Object.keys(pagesNav) : [title];

  return (
    <div className={clsx(className, classes.root)} {...rest}>
      <Helmet>
        <title>{title + ' - Kidztime'}</title>
      </Helmet>
      {dashboard === true ? (
        <Box className={classes.headerBox}>
          <Typography variant="h3" className={classes.title}>
            {text.join(' > ')}
          </Typography>
          {buttonTop}
        </Box>
      ) : null}
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  dashboard: PropTypes.bool,
  pagesNav: PropTypes.object,
  buttonTop: PropTypes.node,
};

Page.defaultProps = {
  dashboard: false,
  className: '',
  pagesNav: null,
};

export default Page;
