import * as Layout from "./layout/actions";
import * as Preference from "./preference/actions";
import * as Session from "./session/actions";
import * as Filter from "./filter/actions";
import * as Models from "./models/actions";
import * as TrackingId from "./trackingid/actions";
import * as UpLoadFile from "./uploadfile/actions";
import * as ContentNotification from "./contentnotification/actions";
import * as ExchangeItemSelected from "./exchangeitemselected/actions";
import * as PreFillData from "./prefilldata/actions";
import * as DataList from "./datalist/actions";
import * as OptionsList from "./optionsstore/actions";
import * as DataDraftList from "./draftdata/actions";
import * as BannerList from "./banner/actions";
import * as PromotionInfo from "./promotionInfo/actions";

export default {
  Layout,
  Preference,
  Session,
  Filter,
  Models,
  TrackingId,
  UpLoadFile,
  ContentNotification,
  ExchangeItemSelected,
  PreFillData,
  DataList,
  OptionsList,
  DataDraftList,
  BannerList,
  PromotionInfo,
};
