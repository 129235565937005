import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { Box, Grid, Link, makeStyles, MenuItem, TextField, InputAdornment } from "@material-ui/core";
import { PasswordField } from "components";
import Loader from "components/Loader";
import React, { Fragment } from "react";
import { pascalize } from "utils/formatting";
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AutoComplete, SearchByType, TextEditor } from "./components";

const APP_HOST = process.env.APP_HOST || process.env.REACT_APP_HOST;

const useStyles = makeStyles(() => ({
  link: {
    border: "solid 1px rgb(217,217,217)",
    padding: "18.5px 14px",
    borderRadius: "4px",
    "&:hover": {
      border: "solid 1px black",
    },
  },
  linkHandle: {
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#0D5AE5 !important",
      cursor: "pointer",
    },
  },
  addItem: {
    display: "flex",
    gap: "30px",
    "& .MuiInputBase-input": {
      color: "#333333",
    },
  },
}));

const InputSwitch = props => {
  const { field, index, className, selectProps, loading, InputProps, ...rest } = props;
  const classes = useStyles();
  switch (field.spec.type) {
    case "select":
      const options = Object.values(field.options) || [];
      return (
        <TextField
          fullWidth
          disabled={field.disabled || loading}
          key={index}
          value={field.value}
          className={className}
          SelectProps={selectProps}
          onChange={field.onChange}
          onBlur={field.onBlur}
          variant="outlined"
          label={field.spec.label}
          error={field.dirty && !!field.error}
          helperText={field.dirty && field.error}
          placeholder={field.spec.placeholder}
          select
          InputProps={InputProps}
        >
          {options.map((each, id) => {
            if (typeof each === "string") {
              return (
                <MenuItem value={each} key={id}>
                  {pascalize(each)}
                  <Loader loading={loading} thickness={4} size={12} padding={0} />
                </MenuItem>
              );
            } else if (each === "None" || typeof each === "number") {
              return (
                <MenuItem value={each} key={id}>
                  {each}
                  <Loader loading={loading} thickness={4} size={12} padding={0} />
                </MenuItem>
              );
            } else {
              return (
                <MenuItem value={each.value} key={id}>
                  {each.node}
                  <Loader loading={loading} thickness={4} size={12} padding={0} />
                </MenuItem>
              );
            }
          })}
        </TextField>
      );
    case "link":
      return (
        <Link href={`${APP_HOST}/${field.value}`} target="_blank">
          <Box className={classes.link}>{field.value}</Box>
        </Link>
      );
    case "linkpromo":
      return (
        <Link href={field.value} target="_blank">
          <Box className={classes.link}>{field.value}</Box>
        </Link>
      );
    case "linkhandle":
      return (
        <Link href={`${APP_HOST}/products/${field.value}`} target="_blank">
          <TextField
            className={classes.linkHandle}
            value={field.value}
            label={field.spec.label}
            fullWidth
            disabled={field.disabled}
            variant="outlined"
            type={field.spec.type}
            key={index}
          ></TextField>
        </Link>
      );
    case "textarea":
      return (
        <TextField
          fullWidth
          disabled={field.disabled}
          key={index}
          value={field.value}
          className={className}
          onChange={field.onChange}
          onBlur={field.onBlur}
          variant="outlined"
          type={field.spec.type}
          label={field.spec.label}
          error={field.dirty && !!field.error}
          helperText={(field.dirty && field.error) || field.spec.helper}
          placeholder={field.spec.placeholder}
          multiline
          rowsMax={4}
          {...field?.spec?.inputProps}
          {...rest}
        />
      );
    case "password":
      return (
        <PasswordField
          disabled={field.disabled}
          key={index}
          value={field.value}
          className={className}
          onChange={field.onChange}
          onBlur={field.onBlur}
          label={field.spec.label}
          error={field.dirty && !!field.error}
          helperText={field.dirty && field.error}
          placeholder={field.spec.placeholder}
          {...rest}
        />
      );
    case "date":
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            className={className}
            inputVariant="outlined"
            format={field.spec?.format || "dd/MM/yyyy"}
            label={field.spec.label}
            value={field.value}
            error={field.dirty && !!field.error}
            helperText={field.dirty && field.error}
            onChange={value => field.onChange({ target: { value } })}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            {...rest}
          />
        </MuiPickersUtilsProvider>
      );

    case "date&time":
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            fullWidth
            className={className}
            inputVariant="outlined"
            format={field.spec?.format || "yyyy/MM/dd HH:mm"}
            label={field.spec.label}
            value={field.value}
            onChange={value => field.onChange({ target: { value } })}
            error={field.dirty && !!field.error}
            helperText={field.dirty && field.error}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            {...rest}
          />
        </MuiPickersUtilsProvider>
      );

    case "autocomplete":
      return <AutoComplete field={field} />;

    case "searchByType":
      return <SearchByType field={field} />;

    case "textEditor":
      return <TextEditor field={field} />;

    case "addItem":
      return (
        <div className={classes.addItem}>
          {field?.spec?.initialValue ? (
            <TextField
              fullWidth
              disabled={field?.spec?.disabled || field?.disabled}
              key={index}
              value={field?.value || ""}
              className={className}
              onChange={field?.onChange}
              onBlur={field?.onBlur}
              variant="outlined"
              type={field?.spec?.type}
              label={field?.spec?.label}
              error={field?.dirty && !!field?.error}
              helperText={field?.dirty && field?.error}
              placeholder={field?.spec?.placeholder}
              InputProps={{
                startAdornment: field?.spec?.startAdornment && (
                  <InputAdornment position="start">{field?.spec?.startAdornment}</InputAdornment>
                ),
              }}
              {...rest}
            />
          ) : (
            <>
              <TextField
                fullWidth
                disabled
                key={index}
                value={"No existing webpage"}
                className={className}
                variant="outlined"
                type={field?.spec?.type}
                label={field?.spec?.label}
                placeholder={field?.spec?.placeholder}
                InputProps={{
                  startAdornment: field?.spec?.startAdornment && (
                    <InputAdornment position="start">{field?.spec?.startAdornment}</InputAdornment>
                  ),
                }}
                {...rest}
              />
              <div
                style={{
                  padding: "0 30px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ cursor: "pointer" }}
                  width="24px"
                  src="/images/buttonIcons/Plus_icon.png"
                  alt="Plus Icon"
                  onClick={field?.spec?.handleClickAddButton}
                  disabled={field?.spec?.postWebpageLoading}
                />
              </div>
            </>
          )}
        </div>
      );

    default:
      return (
        <TextField
          fullWidth
          disabled={field.spec.disabled || field.disabled}
          key={index}
          value={field.value ?? ""}
          className={className}
          onChange={field.onChange}
          onBlur={field.onBlur}
          variant="outlined"
          type={field.spec.type}
          label={field.spec.label}
          error={field.dirty && !!field.error}
          helperText={field.dirty && field.error}
          placeholder={field.spec.placeholder}
          InputProps={{
            startAdornment: field.spec.startAdornment && (
              <InputAdornment position="start">{field.spec.startAdornment}</InputAdornment>
            ),
          }}
          {...rest}
        />
      );
  }
};

const InputGroup = props => {
  const { className, selectProps, fields, loading, ...rest } = props;
  return (
    <Fragment>
      <Grid container spacing={2}>
        {fields.map((field, index) => (
          <Grid item xs={12} sm={field.width} key={index}>
            <InputSwitch
              loading={loading}
              field={field}
              index={index}
              className={className}
              selectProps={selectProps}
              {...rest}
            />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

InputGroup.defaultProps = {
  selectProps: {},
};

export default InputGroup;
