import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TextField, makeStyles } from "@material-ui/core";
import IconComponent from "components/IconComponent";
import { useFilter } from "utils/filter";
import useApi from "utils/api/useApi";
import { useRouter } from "utils/tools";

const useStyles = makeStyles(() => ({
  productImage: {
    width: 75,
  },
  autoComplete: {
    width: "100%",
  },
}));

export const AutoComplete = props => {
  const classes = useStyles();
  const { field } = props;
  const api = useApi();
  // eslint-disable-next-line no-unused-vars
  const [filter, updateFilter, listenFilters, parsedFilter] = useFilter(field?.spec?.filterName || "product");
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState();
  const componentMounted = useRef(true); //component is mounted
  const router = useRouter();
  const params = router?.match?.params;

  const getData = async () => {
    setLoading(true);
    try {
      if (!field?.spec?.pathname || !field?.spec?.filterName) return;

      const result = await api
        .path(
          field?.spec?.pathname,
          {},
          {
            ...parsedFilter,
            ...(field?.spec?.filterByParams && params ? { ...params } : {}),
          }
        )
        .get();
      setLoading(false);

      if (result?.status === 200) {
        if (field?.spec?.getModel) {
          setModel(field?.spec?.getModel(result));
          return;
        }
        setModel(result?.data?.data?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (componentMounted.current) {
      componentMounted.current = false;
      return;
    }
    getData();
  }, [filter]); // eslint-disable-line
  const timeoutID = useRef();

  const handleOnChange = (event, value, reason) => {
    if (!value) {
      updateFilter({ ...filter, search: undefined });
    }
    field.spec.onChange(event, value, reason, field);
  };

  const handleInputChange = useCallback(
    (event, value, reason) => {
      if (timeoutID.current) {
        clearTimeout(timeoutID.current);
      }
      timeoutID.current = setTimeout(() => {
        if (!field?.spec?.pathname || !field?.spec?.filterName) return;

        updateFilter({
          ...filter,
          ...(field?.spec?.searchKey && field?.spec?.queryKeyword
            ? {
                [field.spec.searchKey]: value ? `${field.spec.queryKeyword + ":" + value}` : "",
              }
            : { search: value }),
        });
      }, 1000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.spec.queryKeyword, field.spec.searchKey, filter, updateFilter]
  );
  return field?.spec?.multiple ? (
    <Autocomplete
      multiple
      options={
        field?.spec?.staticOptions
          ? field?.spec?.staticOptions
          : field?.spec?.filterOptions
            ? field?.spec?.filterOptions(model || [])
            : model || []
      }
      fullWidth
      value={field?.value || []}
      disabled={field?.spec?.disabled}
      autoHighlight
      loading={loading}
      getOptionLabel={option => field?.spec?.getOptionLabel(option, model)}
      defaultValue={field?.spec?.initialValue}
      getOptionSelected={field?.spec?.getOptionSelected}
      filterOptions={(options, state) => options}
      onChange={handleOnChange}
      renderOption={(option, value) => field?.spec?.renderOption(option, value)}
      onFocus={() => {
        !model && getData();
      }}
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant="outlined"
            className={classes.textField}
            label={field?.spec?.label}
            placeholder={field?.spec?.placeholder}
            onBlur={field?.onBlur}
            error={field?.dirty && !!field?.error}
            helperText={field?.dirty && field?.error}
            onChange={e => handleInputChange(e, e.target.value)}
          />
        );
      }}
    />
  ) : (
    <Autocomplete
      className={classes.autoComplete}
      value={field?.value || null}
      defaultValue={field?.spec?.initialValue}
      fullWidth
      disabled={field?.spec?.disabled}
      autoHighlight
      loading={loading}
      options={
        field?.spec?.staticOptions
          ? field?.spec?.staticOptions
          : field?.spec?.filterOptions
            ? field?.spec?.filterOptions(model || [])
            : model || []
      }
      getOptionLabel={option => field?.spec?.getOptionLabel(option, model)}
      getOptionSelected={field?.spec?.getOptionSelected}
      filterOptions={(options, state) => options}
      onChange={handleOnChange}
      onFocus={() => {
        !model && getData();
      }}
      renderOption={(option, value) => field?.spec?.renderOption(option, value)}
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant="outlined"
            className={classes.textField}
            label={field?.spec?.label}
            placeholder={field?.spec?.placeholder}
            onBlur={field?.onBlur}
            error={field?.dirty && !!field?.error}
            helperText={field?.dirty && field?.error}
            onChange={e => handleInputChange(e, e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: <IconComponent name="search" />,
            }}
          />
        );
      }}
    />
  );
};
