import { useIsFirstRender } from "hooks/useIsFirstRender";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useFilter } from "utils/filter";

export const useGetBundleProductList = (promotion_id: any) => {
  const [filter] = useFilter("getBundleProducts");
  const isFirstRender = useIsFirstRender();
  const [data, setData] = useState<any>({
    items: [],
    hasMore: true,
  });
  const api = useApi();
  const getBundleProductList = async () => {
    const res = await api.path("bundle_product/list", {}, { promotion_id: promotion_id, search: filter.search }).get();
    if (res?.data?.status === 0) {
      setData({
        items: res?.data?.data,
        hasMore: res?.data?.length < res?.data?.total,
      });
    }
  };

  const { isLoading, error, mutate } = useMutation(() => getBundleProductList());

  const resetData = () => {
    setData({
      items: [],
      hasMore: false,
    });
  };

  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return {
    getBundleProductListData: data,
    getBundleProductListError: error,
    getBundleProductListLoading: isLoading,
    getBundleProductListMutate: mutate,
    getBundleProductListMutateResetData: resetData,
  };
};
