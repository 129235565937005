import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: 910,
    padding: 20,
    border: "none",
    borderRadius: 8,
    height: "90vh",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "24px",
  },
  title: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "16px",
    color: "#333333",
  },
  iconButton: {
    padding: "4px",
  },
  hr: {
    border: "5px solid #F3F5FA",
    marginBottom: "32px",
  },
}));
