import { useIsFirstRender } from "hooks";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useFilter } from "utils/filter";

export const useGetPromotionProductList = (promotion_id?: number) => {
  const isFirstRender = useIsFirstRender();
  const [filter] = useFilter("getPromotionProducts");
  const [data, setData] = useState<any>({
    items: [],
    hasMore: true,
  });
  const api = useApi();
  const getPromotionProductList = async () => {
    const res = await api.path("promotion_product/list", {}, { promotion_id, ...filter }).get();
    if (res?.data?.status === 0) {
      if (filter.offset === 0) {
        setData({
          items: res?.data?.data.data,
          hasMore: res?.data?.data?.data?.length < res?.data?.data?.total,
        });
      } else {
        setData((prev: any) => ({
          items: [...prev.items, ...res?.data?.data?.data],
          hasMore: res?.data?.data?.data.length + prev?.items.length < res?.data?.data?.total,
        }));
      }
    }
  };

  const { isLoading, error, mutate } = useMutation(() => getPromotionProductList());

  const resetData = () => {
    setData({
      items: [],
      hasMore: false,
    });
  };

  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return {
    getPromotionProductListData: data,
    getPromotionProductListError: error,
    getPromotionProductListLoading: isLoading,
    getPromotionProductListMutate: mutate,
    getPromotionProductListResetData: resetData,
  };
};
