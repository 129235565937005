import React, { useCallback, useState } from "react";
import {
  Modal,
  Fade,
  makeStyles,
  Box,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { useAsyncTask } from "utils/tools";
import { useSnackbar } from "notistack";
import useApi from "utils/api/useApi";
import { useDispatch } from "react-redux";
import { Actions } from "store";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4),
    minWidth: 600,
  },
  updateBtn: {
    color: "white",
    backgroundColor: "#0d5ae5",
    minWidth: 100,
    marginRight: 16,
    "&:hover": {
      backgroundColor: "#0d5ae5",
    },
  },
  cancelBtn: {
    color: "white",
    backgroundColor: "#f27474",
    minWidth: 100,
    marginLeft: 16,
    "&:hover": {
      backgroundColor: "#f27474",
    },
  },
}));

const EditReplyModal = (props) => {
  const classes = useStyles();
  const { open, handleClose, title, initValue, review_id } = props;
  const [value, setValue] = useState(initValue || "");
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(
    initValue ? false : "Reply Comment is required!"
  );
  const [runUpdate] = useAsyncTask();
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleUpdate = useCallback(() => {
    if (error) {
      setDirty(error);
    } else {
      runUpdate(async () => {
        const body = {
          review_id,
          reply: value,
        };
        try {
          const response = await api.path("review/update").post({ body });
          if (response.data?.status === 0) {
            enqueueSnackbar("Review has been updated!", {
              variant: "success",
            });
            dispatch(Actions.Filter.reload({ key: "allReviews" }));
            handleClose();
          } else {
            enqueueSnackbar(response.data?.message, {
              variant: "error",
            });
          }
        } catch (error) {
          enqueueSnackbar(error?.message, {
            variant: "error",
          });
        }
      });
    }
  }, [
    api,
    dispatch,
    enqueueSnackbar,
    error,
    handleClose,
    review_id,
    runUpdate,
    value,
  ]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.paper}>
          <Typography variant="h2">{title}</Typography>
          <TextField
            value={value}
            style={{ width: "100%", margin: "20px 0px" }}
            placeholder="Reply Comment"
            variant="outlined"
            type="text"
            multiline
            onChange={(event) => {
              if (event.target.value === "") {
                setError("Reply Comment is required!");
              }
              setValue(event.target.value);
            }}
            onBlur={(event) => {
              if (event.target.value) {
                setError(false);
              }
              setDirty(true);
            }}
            helperText={dirty && error}
            error={dirty && !!error}
          />
          <Box>
            <Button className={classes.updateBtn} onClick={handleUpdate}>
              Update
            </Button>
            <Button className={classes.cancelBtn} onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EditReplyModal;
