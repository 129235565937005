import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {},
  headerButton: {
    backgroundColor: '#0D5AE5',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '14px',
    textTransform: 'none',
    color: '#fff',
    height: 'fit-content',
    padding: '13px 30px',
    '&:hover': {
      backgroundColor: '#0D5AE5',
      color: '#fff',
    },
  },
  syncSection: {
    display: 'flex',
    backgroundColor: '#FFFFFF',
    justifyContent: 'space-between',
    gap: '24px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    padding: '0 0 24px',
  },
  syncButtons: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '16px',
    },
  },
  searchWrapper: {
    flex: '1',
    backgroundColor: '#FFFFFF',
  },
  wrapContent: {
    marginTop: '30px',
    padding: '24px 30px',
    backgroundColor: '#FFFFFF',
    '& .MuiFormControl-root': {
      marginBottom: 'initial',
    },
  },
  viewUnpublished: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#24243A',
    padding: '0 24px 0 36px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px 0 0',
    },
  },
  wrapViewUnpublishedGrp: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: '1px solid #D0D7DB',
    [theme.breakpoints.down('sm')]: {
      borderLeft: 'initial',
    },
  },
}));
