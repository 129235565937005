import React from "react";
import { TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { DataDraftKeys } from "store/draftdata/actions";
import { RootState } from "store/reducers";
import { Actions } from "store";

interface IEditableProps {
  // draft key to update draft data in redux store
  draftKey: DataDraftKeys;
  // id of record / data from response
  id: any;
  // key for render UI and update prop in redux store
  propKey: any;
  // value of record / data
  text?: any;
  // allow to edit on multiline
  multiline?: boolean;
  // rows
  rows?: number;
}

const Editable = ({
  draftKey,
  id,
  propKey,
  text,
  multiline,
  rows,
}: IEditableProps) => {
  const drafts = useSelector(
    (state: RootState) => state?.dataDraftReducer?.[draftKey]
  );
  const draftCurrent = drafts.filter((item: any) =>item?.id === id)?.[0];
  const dispatch = useDispatch();

  const handleOnChange = (value: any) => {
    const newDrafts = drafts.map((item: any) => {
      if (item?.id === id) {
        return {
          ...item,
          [propKey]: value,
        };
      }
      return item;
    });
    dispatch(Actions.DataDraftList.update({ key: draftKey, value: newDrafts }));
  };
  
  return (
    <TextField
      multiline={multiline}
      rows={rows ?? 1}
      style={{
        border:
          draftCurrent?.[propKey] === text ? "none" : "1px solid #176a1d",
      }}
      onChange={(e) => handleOnChange(e.target.value)}
      value={draftCurrent?.[propKey]}
    />
  );
};

export default Editable;
