/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  makeStyles,
  Box,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {useRouter} from 'utils/tools';
import SlickSlider from './SlickSlider/SlickSlider';
import clsx from "clsx";
import EditForm from './EditForm';
import {formStructure} from './formConfig';
import useFormHandler from 'utils/forms/useFormHandler';
import LayoutSelect from './LayoutSelect/LayoutSelect';
import { useCreateCarouselBanner, useGetBanner, usePatchBanner, useGetGroupInfo } from 'hooks';
import { useSnackbar } from "notistack";
import { useFilter } from "utils/filter";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 30,
    marginTop: 42,
    boxShadow: 'none',
  },
  routeMenu: {
    padding: "0 30px",
    paddingTop: "36px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "18px",
    },
  },
  routeChild: {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "48px",
    color: "#333333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      lineHeight: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      lineHeight: "28px",
    },
  },
  routeMenuChild: {
    cursor: "pointer"
  },
  buttonGroup: {
    display: 'flex',
    gap: '12px',
    justifyContent: 'flex-end',
    marginTop: 42,
  },
  btnSave: {
    background: '#0D5AE5',
    borderRadius: '5px',
    padding: '6px 47px',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    color: '#FFFFFF',
    textTransform: 'none',
  },
  btnCancel: {
    border: '2px solid #333333',
    borderRadius: '5px',
    padding: '6px 41.5px',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    color: '#333333',
    textTransform: 'none',
  },
  carousel: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    marginLeft: "30px",
    marginRight: "30px",
  },
  banner: {
    backgroundColor: "#fff",
    marginLeft: "30px",
    marginRight: "30px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  }
}));

const LandingPageCarousel = (props) => {
  const { title, getBanner } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [filter, updateFilter, listenFilters, parsedFilter] = useFilter("banner");
  useEffect(() => {
    updateFilter({...filter, bannerType: getBanner});
    return function cleanup() {
      updateFilter({...filter, bannerType: ""})
    };
  }, [])
  const router = useRouter();
  const [selectedLayout, setSelectedLayout] = useState(1);
  const [image, setImage] = useState(null);
  const [havePreview, setHavePreview] = useState(false);
  const [files, setFiles] = useState(null);
  const [viewSize, setViewSize] = useState("desktop");
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState();
  const [groupSelect, setGroupSelect] = useState({})
  const {
    getGroupInfoData,
    getGroupInfoMutate,
  } = useGetGroupInfo();
  useEffect(() => {
    getGroupInfoMutate();
  }, []);
  useEffect(() => {
    if (image) {
      setFiles(null);
      setHavePreview(false)
    }
  }, [image]);
  useEffect(() => {
    if (getGroupInfoData) {
      // eslint-disable-next-line array-callback-return
      getGroupInfoData.map((item) => {
        setGroupSelect(prev => {
          return {
            ...prev,
            [item?.id]: item?.name
          }
        })
      })
    }
  }, [getGroupInfoData])
  const [fields, inputs, errors, setInputs, setErrors, setDirtys, dirtys] = useFormHandler(
    {
      ...getBanner === "home_banner_1" && 
        {group_id: {
          initialValue: '',
          label: 'Product Group',
          type: 'select',
          validate: {
            presence: { allowEmpty: false, message: "is required" },
            inclusion: Object.values(groupSelect || {}),
          },
          options: groupSelect || {},
        }},
      ...formStructure(getBanner)
    });
  const {createNewBannerData, createNewBannerError, createNewBannerLoading, createNewBannerMutate} = useCreateCarouselBanner();
  const {getBannerData, getBannerError, getBannerLoading, getBannerMutate} = useGetBanner();
  const {patchBannerData, patchBannerError, patchBannerLoading, patchBannerMutate} = usePatchBanner();
  const [disabledBanner1, setDisabledBanner1] = useState({
    selectDesktop: false,
    selectMobile: false,
  })

  useEffect(() => {
    if (getBanner === "home_banner_1") {
      if (getBannerData?.data?.home_banner_1_desktop?.length > 0) {
        setDisabledBanner1(prev => {
          return {
            ...prev,
            selectDesktop: true
          }
        })
        setViewSize("")
      } else {
        setDisabledBanner1(prev => {
          return {
            ...prev,
            selectDesktop: false
          }
        })
        setViewSize("desktop")
      }
      if (getBannerData?.data?.home_banner_1_mobile?.length > 0) {
        setDisabledBanner1(prev => {
          return {
            ...prev,
            selectMobile: true
          }
        })
        setViewSize("");
      } else {
        setDisabledBanner1(prev => {
          return {
            ...prev,
            selectMobile: false
          }
        })
        if (viewSize === "") {
          setViewSize("mobile")
        }
      }
    }
  }, [getBannerData])

  useEffect(() => {
    if (Boolean(errors) || Boolean(disabledBanner1.selectDesktop && disabledBanner1.selectMobile && !image)) {
      setIsDisabled(true);
      return;
    }
    if (Boolean(havePreview && files) || Boolean(image && !havePreview) || Boolean(image && havePreview && files)) {
      setIsDisabled(false);
      return;
    }
    setIsDisabled(true);
  }, [image, files, havePreview, errors, disabledBanner1])

  useEffect(() => {
    if (filter?.bannerType !== "") {
      getBannerMutate();
    }
  }, [filter])

  useEffect(() => {
    if (image) {
      setSelectedLayout(image?.layout_type ?? 1);
      if (getBanner === "home_banner_1") {
        setInputs({
          title: image?.title ?? "",
          description: image?.description ?? "",
          cta_text: image?.cta_text ?? "",
          cta_link: image?.cta_link ?? "",
          group_id: getGroupInfoData?.filter(item => item?.id === getBannerData.data?.[getBanner+"_desktop"][0].group_id)[0]?.name ?? '',
        });
      } else {
        setInputs({
          title: image?.title ?? "",
          description: image?.description ?? "",
          cta_text: image?.cta_text ?? "",
          cta_link: image?.cta_link ?? "",
        });
      }
      setViewSize(image?.assoc_type?.split("_").at(-1) ?? viewSize)
    }
  }, [image])

  useEffect(() => {
    if (createNewBannerData || createNewBannerError) {
      if (createNewBannerData?.status === 0) {
        enqueueSnackbar("Create new banner successfully!", {
          variant: "success",
        });
        getBannerMutate();
      } else {
        enqueueSnackbar("Create new banner failed!", {
          variant: "error",
        });
      }
    }
  }, [createNewBannerData, createNewBannerError])

  useEffect(() => {
    if (patchBannerData || patchBannerError) {
      if (patchBannerData?.status === 0) {
        enqueueSnackbar("Update new banner successfully!", {
          variant: "success",
        });
        getBannerMutate();
      } else {
        enqueueSnackbar("Update new banner failed!", {
          variant: "error",
        });
      }
    }
  }, [patchBannerData, patchBannerError])

  const handleSelectLayout = (layout) => {
    setSelectedLayout(layout);
  };
  const handleUpdate = (file, isClear=false) => {
    if (isClear) {
      setFiles(null);
    } else {
      setFiles(file)
    }
  };
  const handleCopyLink = () => {
    if (inputs?.cta_link) {
      navigator.clipboard.writeText(inputs?.cta_link ?? "");
      enqueueSnackbar("Copied to clipboard!", {
        variant: "success",
      });
    }
  };
  useEffect(() => {
    if (!inputs.group_id || !getGroupInfoData) return;
    setInputs({
      ...inputs,
      cta_link: `${process.env.REACT_APP_HOST}/promo_group/${getGroupInfoData?.filter(item => item?.name === inputs?.group_id)[0]?.handle}`,
    })
  }, [inputs.group_id, getGroupInfoData])
  const handleSave = () => {
    let formData = new FormData();
    if (getBanner !== "age_group") {
      formData.append("layout_type", selectedLayout);
    }
    if (getBanner === "home_banner_1") {
      formData.append('group_id', getGroupInfoData?.filter(item => item?.name === inputs?.group_id)[0]?.id ?? null);
    }
    formData.append("viewSize", viewSize);
    formData.append("bannerType", getBanner);
    formData.append("cta_link", inputs?.cta_link ?? "");
    formData.append("title", inputs?.title ?? "");
    formData.append("cta_text", inputs?.cta_text ?? "");
    formData.append("description", inputs?.description ?? "");
    if ((!errors && image) || (disabledBanner1.selectDesktop && disabledBanner1.selectedMobile)) {
      if (files) {
        formData.append("banner", files);
      }
      formData.append("asset_id", image?.id);
      patchBannerMutate(formData);
    } else if (!errors && files) {
      formData.append("banner", files);
      createNewBannerMutate(formData);
    } else {
      setDirtys(errors)
    }
  };
  const handleDelete = (id) => {
    let formData = new FormData();
    formData.append("asset_id", id);
    formData.append("deletedAt", 1);
    patchBannerMutate(formData);
  }
  const handleCancelPreview = () => {
    setFiles(null);
    setHavePreview(false);
  }
  const clearData = () => {
    setInputs({
      title: "",
      description: "",
      cta_text: "",
      cta_link: ""
    });
    setSelectedLayout(1);
    setImage(null);
    setFiles(null);
  }
  const handleCancel = () => {
    clearData();
  };

  return (
    <Box>
      <Box className={classes.routeMenu}>
        <Typography onClick={() => router.history.push("/frontend/homepage")} className={clsx(classes.routeChild, classes.routeMenuChild)}>
          Home Page
        </Typography>
        <Typography className={classes.routeChild}>&nbsp;{'>'}&nbsp;</Typography>
        <Typography className={classes.routeChild}>
          { title }
        </Typography>
      </Box>
      {getBannerLoading ? <Box style={{width: "100%", textAlign: "center"}}><CircularProgress /></Box> : 
        <Box className={["home_carousel", "age_group"].includes(getBanner) ? classes.carousel : classes.banner}>
          {getBanner !== "age_group" &&
            <SlickSlider title="Mobile Image" handleDelete={handleDelete} setImage={setImage} products={getBannerData?.data?.[getBanner+"_mobile"] || []} show1Slide={["home_carousel", "age_group"].includes(getBanner)  ? false : true} />
          }
          <SlickSlider title={getBanner === "age_group" ? "" : "Desktop Image"} handleDelete={handleDelete} setImage={setImage} products={getBannerData?.data?.[getBanner+"_desktop"] || []} show1Slide={["home_carousel", "age_group"].includes(getBanner) ? false : true} />
        </Box>
      }
      <Box style={{padding: "30px", backgroundColor: "#fff", margin: "30px 30px"}}>
        {getBanner !== "age_group" &&
          <LayoutSelect selectedLayout={selectedLayout} handleSelectLayout={handleSelectLayout} />
        }
        <EditForm
          files={files}
          error={error}
          fields={fields}
          handleCopyLink={handleCopyLink}
          handleUpdateImage={handleUpdate}
          setError={setError}
          image={image}
          viewSize={viewSize}
          setViewSize={setViewSize}
          setHavePreview={setHavePreview}
          handleCancelPreview={handleCancelPreview}
          getBanner={getBanner}
          disabledBanner1={disabledBanner1}
        />
        <Box className={classes.buttonGroup}>
          <Button onClick={handleCancel} className={classes.btnCancel}>
            Cancel
          </Button>
          <Button disabled={isDisabled} onClick={() => handleSave()} className={clsx(classes.btnSave)}>
            {createNewBannerLoading ? <CircularProgress size={12} /> : image || (disabledBanner1.selectDesktop && disabledBanner1.selectMobile) ? "Update" : "Save"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPageCarousel;
