import {
  ModelsAddMoreTierProps,
  ModelsUpdateOneTierProps,
  TYPES,
  Tier,
  ModelsResetTierProps,
  CheckoutCondition,
  ModelsAddCheckoutConditionProps,
} from "./actions";
import { PropAction } from "../props";

export type PromotionInfoState = {
  tiers: Tier[];
  checkout_condition: CheckoutCondition;
};

const initial_state: PromotionInfoState = {
  tiers: [],
  checkout_condition: {},
};

export default (state: PromotionInfoState = initial_state, actions: PropAction) => {
  switch (actions.type) {
    case TYPES.ADD_MORE_TIER:
      const props = actions.props as ModelsAddMoreTierProps;
      return {
        ...state,
        [props.key]: [...state[props.key], props.value],
      };
    case TYPES.REMOVE_TIER:
      const removeProps = actions.props as ModelsAddMoreTierProps;
      const newTiers = state[removeProps.key].filter(tier => tier.id !== removeProps.value.id);
      return {
        ...state,
        [removeProps.key]: [...newTiers],
      };
    case TYPES.UPDATE_ONE_TIER:
      const updateProps = actions.props as ModelsUpdateOneTierProps;
      const updatedTiers = state[updateProps.key].map(tier => {
        if (tier.id === updateProps.value.id) {
          return {
            ...tier,
            [updateProps.value.key]: updateProps.value.value,
          };
        }
        return tier;
      });
      return {
        ...state,
        [updateProps.key]: updatedTiers,
      };
    case TYPES.RESET_TIER:
      const resetTierProps = actions.props as ModelsResetTierProps;
      return {
        ...state,
        [resetTierProps.key]: [],
      };
    case TYPES.UPDATE_CHECKOUT_CONDITION:
      const checkoutConditionProps = actions.props as ModelsAddCheckoutConditionProps;
      return {
        ...state,
        [checkoutConditionProps.key]: checkoutConditionProps?.value,
      };
    default:
      return state;
  }
};
