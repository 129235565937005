export const StorageKeys = {
  Theme: "preference:theme",
  Session: "kidztime:session",
};

export const ThemeModes = {
  Dark: "dark",
  Light: "light",
};

export const Paths = {
  Landing: "/",

  Auth: {
    Root: "/auth",
    Login: "/auth/login",
    Password: {
      Request: "/auth/password",
      Reset: "/auth/password/reset",
    },
  },

  Error: {
    Root: "/errors",
    401: "/errors/error-401",
    404: "/errors/error-404",
    500: "/errors/error-500",
    Unauthorized: "/errors/unauthorized",
  },

  Api: {
    Root: "/api",
    Invoice: "/api/orders/:order_id/invoice",
    NoInvoiceFound: "/api/orders/no-invoice-found",
  },

  Accounts: {
    List: "/customers",
    New: "/customers/new",
    Detail: "/customers/:account_id/detail",
  },

  Products: {
    Inventory: "/inventory/list",
    CatalogueList: "/products/catalogue/list",
    Detail: "/products/:product_id/detail",
    BulkUpdate: "/products/bulkupdate",
    PlatformGraceImport: "/products/platformgrace-import",
  },

  Promotions: {
    PromotionCreationPage: "/promotion/create",
    PromotionListPage: "/promotion/list",
    NewPromotionDetailPage: "/promotion/:id",
  },

  PromotionSettings: "/promotion-settings/list",
  PromotionSettingsDetail: "/promotion-settings/:id",
  PromotionDetail: "/promotion-settings/:promo_id/detail",
  VoucherSettings: "/voucher-settings/list",
  VoucherDetail: "/voucher-settings/:voucher_id/detail",

  Frontend: {
    ProductGroupsList: "/frontend/product-groups/list",
    Homepage: "/frontend/homepage",
    Navigation: "/frontend/navigation",
    Classifications: "/frontend/classifications",
    PagesList: "/frontend/pages/list",
    PageDetail: "/frontend/pages/:webpage_id/detail",
    Element: "/frontend/Element/:name/:webpage_item_id/detail",
    BottlesList: "/frontend/bottles/list",
    Settings: "/frontend/settings",
    Banner1: "/frontend/homepage/banner-1",
    AgeGroup: "/frontend/homepage/age-group",
    LandingPageCarousel: "/frontend/homepage/landing-page",
    ItemCrousel1: "/frontend/homepage/item-carousel-1",
    ItemCrousel2: "/frontend/homepage/item-carousel-2",
    ListingDashboard: "/frontend/listing",
    ListingDetail: "/frontend/listing/:listing_id",
    LandingPage: "/frontend/homepage/landing-page/:landing_page_content",
    LandingPageDetail: "/frontend/homepage/landing-page/:landing_page_content/:id",
    Dashboard: "/frontend/dashboard",
    Webpages: "/frontend/webpages",
    WebpagesDetail: "/frontend/webpages/:id",
    NewLandingPage: "/frontend/landing-page",
    NewLandingPageDetail: "/frontend/landing-page/:id",
    ModifyBanner: "/frontend/modify-banner/:id",
    CollectionPage: "/frontend/collection",
    CollectionPageDetail: "/frontend/collection/:id",
    ItemBrandDetail: "/frontend/classifications/item_brand/:id",
    ItemClassDetail: "/frontend/classifications/item_class/:id",
    ItemTypeDetail: "/frontend/classifications/item_type/:id",
    ItemCategoryDetail: "/frontend/classifications/item_category/:id",
    ItemSubClassDetail: "/frontend/classifications/item_sub_class/:id",
    ItemGroupDetail: "/frontend/classifications/item_group/:id",
  },

  Sales: {
    OrderOverview: "/orders",
    OrderDetail: "/orders/:order_id/detail",
    StrawReplacement: "/sales/straw-replacement",
    ReviewManagement: "/sales/review-management",
    QAManagement: "/sales/qa-management",
  },
};

export const Roles = {
  GenericAdmin: "kidztime.genericadmin",
  SuperAdmin: "kidztime.superadmin",
};

export const RolesDisplay = {
  "kidztime.genericadmin": "Generic Admin",
  "kidztime.superadmin": "Super Admin",
};

export const DateFormat = {
  MAIN: "D MMM YYYY, HH:mm",
};

export const ProductStatus = {
  published: "published",
  unpublished: "unpublished",
};

export const LandingPageContents = {
  landing_page_carousel: "landing_page_carousel",
  banner_1: "banner_1",
  best_seller: "best_seller",
  shop_by_age: "shop_by_age",
  item_carousel_1: "item_carousel_1",
  item_carousel_2: "item_carousel_2",
};

export const PageElementType = {
  banner: "banner",
  product_feature: "product-feature",
  category_feature: "category-feature",
  product_listing: "product-listing",
  page_divider: "page-divider",
  character_slider: "character-slider",
  review_slider: "review-slider",
  banner_carousel: "banner-carousel",
  feature_carousel: "feature-carousel",
  media_attributes: "media_attributes",
  product_content: "product_content",
  three_tile_carousel: "three-tile-carousel",
  four_tile_carousel: "four-tile-carousel",
};

export const PageElementHideBottomDivider = {
  true: "true",
  false: "false",
};

export const PageElementMaxWidth = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
};

export const PageElementDisableGutters = {
  true: "true",
  false: "false",
};

export const PageElementPosition = {
  before: "before",
  after: "after",
};

export const PromotionType = {
  accessory: "accessory",
  gift: "gift",
  discount_group: "discount",
};

export const CampaignType = {
  gwp: "GWP",
  pwp: "PWP",
  bundle: "BUNDLE",
};

export const DiscountType = {
  percent: "percent",
  value: "value",
};

export const OrderStatus = {
  // open: "open",
  // in_delivery: "in-delivery",
  unprocessed: "unprocessed",
  processed: "processed",
  shipped: "shipped",
  completed: "completed",
  // cancelled: "cancelled",
  pending_exchange: "pending-exchange",
  void: "void",
  partial_refunded: "partial-refunded",
  refunded: "refunded",
};

export const RequestStatus = {
  pending: "pending",
  resolved: "resolved",
};

export const GenderTypes = {
  female: "Female",
  male: "Male",
  unisex: "Male & Female",
};

export const VoucherTypes = {
  percentage: "percentage",
  price: "price",
};

export const PromotionSummaryTypes = {
  simple: "simple",
  bundle: "bundle",
  buy_x_get_y: "buy_x_get_y",
  free_gift: "free_gift",
};

export const DiscountSummaryTypes = {
  percentage: "percentage",
  fixed: "fixed",
  price: "price",
};

export const HomepageType = {
  0: "None",
  1: 1,
  2: 2,
  3: 3,
};

export const Score = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
};

export const CustomerSegment = {
  Nursery: "Nursery",
  Kindergarden: "Kindergarden",
  Toddler: "Toddler",
};

export const ConditionTypes = {
  cart_count: "Cart Count",
  cart_value: "Cart Value",
  contain_item: "Contain Item",
  contain_any_item: "Contain Any Item",
};

export const Visibility = {
  secrecy: "Secrecy",
  public: "Public",
};

export const Status = {
  published: "Published",
  unpublished: "Unpublished",
};

export const CatalogueStatus = {
  Unlisted: "Unlisted",
  Listed_Published: "Listed_Published",
  Listed_Unpublished: "Listed_Unpublished",
};

export const Published = {
  published: "Published",
  unpublished: "Unpublished",
};

export const AutomaticTypes = {
  yes: "yes",
  no: "no",
};

export const ProductInfoTypes = {
  material_ingredient_care: "material_ingredient_care",
  features: "features",
  certificate: "certificate",
};

export const ProductOptionTypes = {
  color: "color",
  size: "size",
};

export const VoucherStatus = {
  true: "true",
  false: "false",
};

export enum VoucherConditionTypes {
  min_purchase_amount = "min_purchase_amount",
  min_purchase_quantity = "min_purchase_quantity",
}

export enum VoucherPublicVisibility {
  Secrecy = 0,
  Public = 1,
}
export const alignment = {
  left: "left",
  center: "center",
  right: "right",
};
