import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiPaper-root": {
      minWidth: "424px",
      maxWidth: "800px",
      margin: "0 auto",
    },
  },
  content: {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: 424,
    padding: 20,
    border: "none",
    borderRadius: 8,
  },
  headerSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "5px solid #F3F5FA",
    paddingBottom: "24px",
    marginBottom: "24px",
  },
  title: {
    color: "#333",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "16px",
  },
  inputGroup: {
    paddingBottom: "20px",
  },
}));
