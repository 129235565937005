import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { FilterKeys } from "store/filter/actions";
import { useFilter } from "utils/filter";
import IconComponent from "components/IconComponent/IconComponent";
interface IProps {
  filterKey?: FilterKeys;
  sortKey: string;
  resetTable?: () => void;
  // check type of filter
  splitSort?: boolean;
}

const Sort = ({ filterKey, sortKey, resetTable, splitSort }: IProps) => {
  const [filter, setFilter] = useFilter(filterKey);
  const sort = filter.sort?.split(":");
  const sortUp =
    (filter?.sort === "ASC" && filter.sortBy === sortKey) || (sort?.includes(sortKey) && sort?.includes("ASC"));
  const sortDown =
    (filter.sort === "DESC" && filter.sortBy === sortKey) || (sort?.includes(sortKey) && sort?.includes("DESC"));

  const classes = useStyles({ sortUp, sortDown });

  const handleChangeFilter = (orderBy: "ASC" | "DESC") => {
    resetTable && resetTable();
    // When passing splitSort, the filter will search in the form type:name, sortBy:name
    // When not transmitting splitSort the filter will search in the form sortBy:type
    if (splitSort) {
      setFilter({
        ...filter,
        offset: 0,
        sort: orderBy === filter.sort && sortKey === filter.sortBy ? "ASC" : orderBy,
        sortBy: orderBy === filter.sort && sortKey === filter.sortBy ? "id" : sortKey,
      });
    } else {
      setFilter({
        ...filter,
        offset: 0,
        sort: orderBy === sort[1] && sortKey === sort[0] ? `id:ASC` : `${sortKey}:${orderBy}`,
      });
    }
  };

  useEffect(() => {
    return () =>
      setFilter({
        ...filter,
        offset: 0,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box className={classes.root}>
      <IconComponent
        className={classes.sortIconUp}
        name="arrowUp"
        onClick={() => handleChangeFilter("ASC")}
        sortUp={sortUp}
      />
      <IconComponent
        className={classes.sortIconDown}
        name="arrowDown"
        onClick={() => handleChangeFilter("DESC")}
        sortDown={sortDown}
      />
    </Box>
  );
};

export default Sort;
