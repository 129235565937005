import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {},
  actionLabel: {
    display: "block",
    fontSize: "12px",
    fontWeight: 700,
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginTop: theme.spacing(1),
  },
}));

const ReadMore = (props) => {
  const { text, className } = props;
  const [isReadMore, setIsReadMore] = useState(false);
  const classes = useStyles();
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <Typography className={clsx(classes.root, className)}>
      {isReadMore && text ? text.slice(0, 150) + "..." : text}
      {text && text.length > 150 &&
        (
          <span onClick={toggleReadMore} className={classes.actionLabel}>
            {isReadMore ? "Read more" : "Show less"}
          </span>
        )
      }
    </Typography>
  );
};

export default ReadMore;
