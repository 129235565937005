import React, { useState, useRef, useEffect } from "react";
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    marginRight: theme.spacing(2),
  },
  dropDown: {
    backgroundColor: "#fff",
    zIndex: 9,
  },
  titleButton: {
    textTransform: "none",
    color: "#0D5AE5",
    textAlign: "center",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    display: "flex",
    padding: "4px 8px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    border: "2px solid #0D5AE5",
    "& .MuiButton-label": {
      gap: "17px",
    },
    margin: "0 auto",
  },
}));

interface IProps {
  items: {
    title: string;
    handle: () => void;
  }[];
}

const DropDownMenu = ({ items }: IProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        className={classes.titleButton}
        onClick={handleToggle}>
        Actions
        <img alt="arrow_down_blue" src="/images/arrow_down_blue.svg" />
      </Button>
      <Popper
        className={classes.dropDown}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {items.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={e => {
                        handleClose(e);
                        item.handle();
                      }}>
                      {item.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default DropDownMenu;
