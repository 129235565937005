import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
    },
  },
  textField: {
    '& legend': {
      display: 'none',
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      transform: 'translate(14px, 5px) scale(0.75)',
      fontWeight: 500,
      lineHeight: '14px',
      color: '#A5A9B9',
    },

    '& textarea.MuiInputBase-input ': {
      height: 'auto !important',
    },
  },
  main: {
    backgroundColor: '#fff',
    maxWidth: '720px',
    width: '100%',
    padding: '24px',
    borderRadius: '5px',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',
  },
  cancel: {
    width: 'calc(50% - 6px)',
    padding: '15px 0',
    borderRadius: '5px',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.615385px',
    border: '2px solid #333333',
    textTransform: 'none',
  },
  confirm: {
    width: 'calc(50% - 6px)',
    padding: '15px 0',
    borderRadius: '5px',
    color: '#fff',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.615385px',
    backgroundColor: '#0D5AE5',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#325ca8',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '24px',
  },
  title: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '16px',
    color: '#333333',
  },
  iconButton: {
    padding: '4px',
  },
  hr: {
    border: '5px solid #F3F5FA',
    marginBottom: '32px',
  },
}));
