import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { ImageDropzone } from "components";

interface IProps {
  title: "Desktop" | "Mobile";
  viewSize: "desktop" | "mobile";
  data: any;
  selectedLayout: any;
  setSelectedLayout: (val: any) => void;
  setFiles: (val: any) => void;
  disableLayout: boolean;
}

const SubContent = ({
  title,
  viewSize,
  data,
  selectedLayout,
  setSelectedLayout,
  setFiles,
  disableLayout,
}: IProps) => {
  const classes = useStyles();
  const handleUpdate = (file: any, isClear = false) => {
    if (isClear) {
      setFiles((prev: any) => ({
        ...prev,
        [viewSize]: "",
      }));
    } else {
      setFiles((prev: any) => ({
        ...prev,
        [viewSize]: file,
      }));
    }
  };
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      <Box>
        <ImageDropzone
          handleUpdate={handleUpdate}
          error={null}
          setError={() => {}}
          image={data?.uri ? data : null}
          updateLabel="Confirm"
          shouldClear={false}
        />
      </Box>
      {!disableLayout && (
        <Box className={classes.layoutSection}>
          <Box
            className={classes.layoutElement}
            style={{
              border:
                selectedLayout[viewSize] === 1 ? "1px solid #0D5AE5" : "none",
            }}
            onClick={() => {
              setSelectedLayout((prev: any) => ({
                ...prev,
                [viewSize]: 1,
              }));
            }}
          >
            <Typography className={classes.layoutTitle}>Layout 1</Typography>
            <img
              className={classes.layoutImage}
              src={`/images/bannerLayout/${viewSize}_layout_1.png`}
              alt="layout"
            />
          </Box>
          <Box
            className={classes.layoutElement}
            style={{
              border:
                selectedLayout[viewSize] === 2 ? "1px solid #0D5AE5" : "none",
            }}
            onClick={() => {
              setSelectedLayout((prev: any) => ({
                ...prev,
                [viewSize]: 2,
              }));
            }}
          >
            <Typography className={classes.layoutTitle}>Layout 2</Typography>
            <img
              className={classes.layoutImage}
              src={`/images/bannerLayout/${viewSize}_layout_2.png`}
              alt="layout"
            />
          </Box>
          <Box
            className={classes.layoutElement}
            style={{
              border:
                selectedLayout[viewSize] === 3 ? "1px solid #0D5AE5" : "none",
            }}
            onClick={() => {
              setSelectedLayout((prev: any) => ({
                ...prev,
                [viewSize]: 3,
              }));
            }}
          >
            <Typography className={classes.layoutTitle}>Layout 3</Typography>
            <img
              className={classes.layoutImage}
              src={`/images/bannerLayout/${viewSize}_layout_3.png`}
              alt="layout"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SubContent;
