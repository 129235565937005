import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";

enum BooleanNumber {
  one = 1,
  zero = 0
}

interface PatchBody {
  name?: string;
  alt_name?: string;
  stock?: number | string;
  price?: number | string;
  promo_id?: number;
  has_gift?: boolean;
  is_gift?: BooleanNumber;
  is_accessory?: BooleanNumber;
  has_accessory?: BooleanNumber;
  listing_id ? : number
}

interface Body {
  body: PatchBody,
  product_id: number | string;
}

export const usePatchProduct = () => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const patchProduct = async (body: Body) => {
    const res = await api
      .path("product/update", {product_id: body.product_id})
      .patch({ body: body.body });
    if (res?.data?.status === 0) {
      enqueueSnackbar(`${body?.body?.promo_id === null ? "UnFlag item successfully." : "Flag product created!"}`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(`${body?.body?.promo_id === null ? `UnFlag item failed.` : `Flag product faiiled! ${res?.data?.message}`}`, {
        variant: "error",
      });
    }
    return res?.data?.data
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => patchProduct(body));

  return {
    patchProductData: data,
    patchProductError: error,
    patchProductLoading: isLoading,
    patchProductMutate: mutate,
  };
};
