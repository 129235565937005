import { useMutation } from "react-query";
import useApi from "utils/api/useApi";

enum ViewSizeEnum {
  desktop = 'desktop',
  mobile = 'mobile'
}

enum BannerTypeEnum {
  home_carousel = 'home_carousel',
  home_banner_1 = 'home_banner_1',
  home_banner_2 = 'home_banner_2',
  age_group_toddler = 'age_group_toddler',
  age_group_nursery = 'age_group_nursery',
  age_group_kindergarden  = 'age_group_kindergarden'
}

export interface Body {
  viewSize?: ViewSizeEnum;
  bannerType?: BannerTypeEnum;
  layoutType?: number;
  banner?: File,
  cta_link?: string;
  title?: string;
  description?: string;
  cta_text?: string;
  group_id?: number;
}

export const useCreateCarouselBanner = () => {
  const api = useApi();
  const createNewBanner = async (body: Body) => {
    const res = await api
      .path("asset/banner_carousel")
      .multipost({body: body});
    return res?.data
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => createNewBanner(body));

  return {
    createNewBannerData: data,
    createNewBannerError: error,
    createNewBannerLoading: isLoading,
    createNewBannerMutate: mutate,
  };
};
