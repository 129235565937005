import { useInfiniteQuery, useQueryClient } from "react-query";
import useApi from "utils/api/useApi";
import { useFilter } from "utils/filter";
import { Dispatch, SetStateAction, useEffect } from "react";
import { componentFilterMapping, LandingPageItem, Component } from "types";

interface Props {
  inputs?: {
    component: Component;
  };
  dirties?: {
    component?: boolean;
  };
  landing_page_id?: number;
  setInputs?: Dispatch<SetStateAction<LandingPageItem>>;
}

interface LandingPageV2Response {
  data?: {
    data?: {
      landingPage: LandingPageItem[]; // Ensure landingPage is always defined
    };
  };
}

export const useGetLandingPageV2 = ({ dirties, inputs, landing_page_id, setInputs }: Props) => {
  const api = useApi();
  const [filter, setFilter] = useFilter("LandingPageV2");
  const { offset } = filter;

  const fetchItems = async (): Promise<LandingPageItem[]> => {
    try {
      const res: LandingPageV2Response = await api
        .path("get/landing-page-v2", {}, { ...filter, ...(landing_page_id ? { landing_page_id, offset: 0 } : null) })
        .get();
      setInputs &&
        setInputs({
          ...res?.data?.data?.landingPage?.[0],
        });
      return res?.data?.data?.landingPage || [];
    } catch (error) {
      return [];
    }
  };
  const queryClient = useQueryClient();

  const {
    data = { pages: [] },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: "LandingPageV2",
    queryFn: fetchItems,
    getNextPageParam: lastPage => {
      if (lastPage && lastPage.length > 0) {
        return true;
      }
      return false;
    },
    staleTime: Infinity,
    onError: () => {},
  });

  useEffect(() => {
    if (offset !== 0) {
      // move to the next page
      fetchNextPage();
    } else if (offset === 0 && !isLoading) {
      queryClient.removeQueries("LandingPageV2");
      // refresh page
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    // delete data from memory
    return () => {
      queryClient.removeQueries("LandingPageV2");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dirties?.component && inputs?.component) {
      const { content, content_type, content_section, content_sub_section, ...restFilter } = filter;
      setFilter({ ...restFilter, offset: 0, ...componentFilterMapping[inputs.component] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  return {
    getLandingPageV2Data: {
      items: data?.pages?.flat(),
      hasMore: hasNextPage,
    },
    // isRefetching: status when refetch
    // isLoading: status when the first call api
    // isFetchingNextPage: status when fetchNextPage
    getLandingPageV2Loading: isRefetching || isLoading || isFetchingNextPage,
    getLandingPageV2Refetch: refetch,
  };
};
