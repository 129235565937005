export const formStructure: any = {
  title: {
    initialValue: '',
    label: 'Name',
    placeholder: 'Name',
    type: 'text',
    validate: {
      presence: { allowEmpty: false, message: 'is required' },
    },
    width: 12,
  },
};
