import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeStyles,
  TableCell,
  Typography,
  Box,
  TableRow,
  MenuItem,
  Select,
  FormControl,
  IconButton,
} from "@material-ui/core";
import StarRateIcon from "@material-ui/icons/StarRate";
import { EditReplyModal, ImageGrid } from "./components";
import { ReadMore } from "components";
import ListingCheckbox from "components/Listing/components/ListingBody/ListingCheckbox";
import clsx from "clsx";
import dateFormat from "utils/formatting/dateFormat";
import { Actions } from "store";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSnackbar } from "notistack";
import useApi from "utils/api/useApi";
import { useAsyncTask } from "utils/tools";
import CreateIcon from "@material-ui/icons/Create";

const ItemList = (props) => {
  const { model, name } = props;
  console.log('props ItemList', props);
  const classes = useStyles();
  const checkedList = useSelector(
    (state) => state.Models[name]?.checkedList || []
  );
  const checked = checkedList.includes(model.id);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();
  const [runCreate, load, error, setError] = useAsyncTask("review-edit"); // eslint-disable-line
  const [openEditModal, setOpenEditModal] = useState(false);

  const showRatingStar = useCallback((e) => {
    let ratingCount = [];
    for (let i = 0; i < 5; i++) {
      ratingCount.push(
        <Box key={i} className={i < e ? classes.starBox : classes.starBoxEmpty}>
          <StarRateIcon className={classes.star} />
        </Box>
      );
    }
    return ratingCount;
  }, []); // eslint-disable-line
  const setNameCustomer = useCallback(() => {
    let firstName = model?.account?.firstname ? model?.account?.firstname : "-";
    let lastName = model?.account?.lastname ? model?.account?.lastname : "";
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
    return firstName + " " + lastName;
  }, [model?.account?.firstname, model?.account?.lastname]); // eslint-disable-line

  const handleClick = () => {
    let list = [...checkedList];
    if (checked) {
      list = list.filter((value) => value !== model.id);
    } else {
      list.push(model.id);
    }
    dispatch(
      Actions.Models.update({ key: name, value: { checkedList: list } })
    );
  };

  const handleUpdateStatus = useCallback(
    (model, status) => {
      runCreate(async () => {
        const body = {
          review_id: model.id,
          status: status ? "published" : "unpublished",
        };
        try {
          const response = await api.path("review/update").post({ body });
          if (response.data?.status === 0) {
            enqueueSnackbar("Review has been updated!", {
              variant: "success",
            });
            dispatch(Actions.Filter.reload({ key: name }));
          } else {
            enqueueSnackbar(response.data?.message, {
              variant: "error",
            });
          }
        } catch (error) {
          enqueueSnackbar(error?.message, {
            variant: "error",
          });
        }
      });
    },
    [model] //eslint-disable-line
  );

  const getAsssets = (model) => {
    if (model.assets?.length) {
      return model.assets.map((asset) => {
        return { img: asset.uri };
      });
    }
    return [];
  };
  return (
    <React.Fragment>
      <TableRow>
        <ListingCheckbox checked={checked} onClick={handleClick} />
        <TableCell className={classes.productInfoBox}>
          <Box>
            <Typography className={classes.productCode}>
              {model?.product?.reference}
            </Typography>
            <Typography className={classes.productName}>
              {model?.product?.name}
            </Typography>
            <Typography className={classes.productName}>
              {model?.product?.material}
            </Typography>
          </Box>
        </TableCell>
        <TableCell className={classes.reviewerBox}>
          <Typography className={classes.reviewer}>
            {setNameCustomer() || "-"}
          </Typography>
          <Typography className={classes.dateBox}>
            {dateFormat(model?.created_at)}
          </Typography>
        </TableCell>
        <TableCell className={classes.reviewBox}>
          <Box className={classes.ratingBox}>
            {showRatingStar(model?.score)}
          </Box>

          <Typography className={classes.commentTitle}>
            {model?.comment || "-"}
          </Typography>
          {model?.reply ? (
            <Box display="flex" alignItems="center">
              <ReadMore className={classes.comment} text={model?.reply} />
              <IconButton
                aria-label="edit reply"
                component="span"
                onClick={() => setOpenEditModal(true)}
              >
                <CreateIcon style={{ cursor: "pointer" }} fontSize="small" />
              </IconButton>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              No reply &nbsp;
              <IconButton
                aria-label="create reply"
                component="span"
                onClick={() => setOpenEditModal(true)}
              >
                <CreateIcon style={{ cursor: "pointer" }} fontSize="small" />
              </IconButton>
            </Box>
          )}
          <EditReplyModal
            open={openEditModal}
            handleClose={() => {
              setOpenEditModal((prev) => !prev);
            }}
            initValue={model?.reply}
            title={model?.reply ? "Edit Reply" : "Reply Comment"}
            review_id={model.id}
          />
        </TableCell>
        <TableCell>
          <ImageGrid images={getAsssets(model)} />
        </TableCell>
        <TableCell>
          <FormControl
            className={
              model?.status === "published"
                ? clsx(classes.formControl, classes.publishedColor)
                : clsx(classes.formControl, classes.unpublishedColor)
            }
          >
            <Select
              value={model?.status === "published" ? 1 : 0}
              className={classes.select}
              IconComponent={(e) => <ExpandMoreIcon className={e.className} />}
              onChange={(e) => handleUpdateStatus(model, e.target.value)}
            >
              <MenuItem className={classes.menuItem} value={1}>
                Published
              </MenuItem>
              <MenuItem className={classes.menuItem} value={0}>
                Unpublished
              </MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  starBox: {
    display: "flex",
    alignItems: "center",
    color: "#010002",
  },
  starBoxEmpty: {
    display: "flex",
    alignItems: "center",
    color: "#D1D1D1",
  },
  star: {
    fontSize: "27px",
  },
  ratingBox: {
    display: "flex",
    color: "#ffc800",
  },
  productCode: {
    fontSize: "14px",
    fontWeight: 700,
  },
  productName: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#6F727D",
  },
  image: {
    height: 80,
    width: 80,
  },
  reviewBox: {
    width: "25%",
  },
  reviewerBox: {
    width: "12%",
  },
  productInfoBox: {
    width: "18%",
  },
  formControl: {
    borderRadius: 5,
  },
  publishedColor: {
    backgroundColor: "#4B6C48",
  },
  unpublishedColor: {
    backgroundColor: "#FBC75A",
  },
  select: {
    "&::before": {
      content: "none",
    },
    "&::after": {
      content: "none",
    },
    "& div": {
      padding: "8px 32px 8px 16px!important",
      color: "#FFFFFF",
    },
    "& .MuiSelect-icon": {
      color: "#FFFFFF",
    },
  },
  reviewer: {
    fontSize: "14px",
    fontWeight: 700,
  },
  dateBox: {
    color: "#0081FF",
    fontSize: "12px",
    fontWeight: 500,
    background: "#F0F2F4",
    borderRadius: "2px",
    textAlign: "center",
  },
  commentTitle: {
    marginTop: theme.spacing(1),
    paddingLeft: "3px",
    fontWeight: "700",
  },
  comment: {
    marginTop: theme.spacing(1),
    paddingLeft: "3px",
    fontWeight: "500",
    fontSize: "12px",
  },
}));
export default ItemList;
