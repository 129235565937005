import { ButtonStyles } from "utils/constants";

export const fields = {
  Item: {
    label: "Item",
    sort: false,
  },
  Particulars: {
    label: "Particulars",
    sort: false,
  },
  question: {
    label: "Subject",
    sortable: true,
  },
  Status: {
    label: "Status",
    sort: true,
  },
};

export const buttonConfig = {
  create: {
    style: ButtonStyles.ADD.style,
    title: "Create Q&A",
    icon: ButtonStyles.ADD.icon,
  },
};