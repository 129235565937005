import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataDraftKeys } from 'store/draftdata/actions';
import { RootState } from 'store/reducers';
import { Actions } from 'store';
import { KeyboardDatePicker } from '@material-ui/pickers';

interface IEditableProps {
  /** draft key to update draft data in redux store */
  draftKey: DataDraftKeys;
  /** id of record / data from response */
  id: any;
  /** key for render UI and update prop in redux store */
  propKey: any;
  /** value of record / data */
  text?: any;
  /** format for KeyboardDatePicker component */
  format?: string;
  /** label for KeyboardDatePicker component */
  label?: string;
}

const EditableDatePicker = ({ draftKey, id, propKey, text, format, label }: IEditableProps) => {
  const drafts = useSelector((state: RootState) => state?.dataDraftReducer?.[draftKey]);
  const draftCurrent = drafts.filter((item: any) => item?.id === id)?.[0];
  const dispatch = useDispatch();

  const handleOnChange = (value: any) => {
    const newDrafts = drafts.map((item: any) => {
      if (item?.id === id) {
        return {
          ...item,
          [propKey]: value,
        };
      }
      return item;
    });
    dispatch(Actions.DataDraftList.update({ key: draftKey, value: newDrafts }));
  };

  return (
    <KeyboardDatePicker
      disableToolbar
      style={{
        border: draftCurrent?.[propKey] === text ? 'none' : '1px solid #176a1d',
      }}
      variant="inline"
      format={format || 'YYYY-MM-DD'}
      label={label || ''}
      margin="normal"
      id="date-picker-inline"
      value={draftCurrent?.[propKey]}
      onChange={value => handleOnChange(value)}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  );
};

export default EditableDatePicker;
