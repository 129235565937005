export const formStructure = {
  name: {
    initialValue: "",
    label: "Name",
    placeholder: "Name",
    type: "text",
    validate: {
      presence: { allowEmpty: false, message: "is required" },
    },
    width: 12,
  },
  description: {
    initialValue: "",
    label: "Description",
    placeholder: "Description",
    type: "text",
    validate: {
      presence: { allowEmpty: false, message: "is required" },
    },
    width: 12,
  },
}