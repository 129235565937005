import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import clsx from "clsx";
import { CropperImage } from "components";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  imgBox: {
    margin: "10px 16px",
    width: "100%",
    height: "100%",
    "& :hover #editBox": {
      display: "flex",
    },
  },
  imgExistBox: {
    width: "100%",
  },
  imgExist: {
    width: "100%",
    border: "1px solid #7a777782",
    borderRadius: "4px",
  },
  button: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    position: "relative",
  },
  editBox: {
    position: "absolute",
    top: "50%",
    transform: "translate(0,-50%)",
    left: "0",
    display: "none",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    boxShadow: "inset -2px -1px 20px 20px #4d82b1a3",
    textTransform: "none",
    textShadow: "3px 3px 3px #212121",
    borderRadius: "4px",
    width: "100%",
    height: "100%",
  },
}));

const MobileImage = (props) => {
  const { name, imgFile, handleSaveImage } = props;
  const classes = useStyles();
  const [open, setOpen] = useState({ isOpen: false, name: null });

  return (
    <Grid className={classes.root} item xs={4}>
      <Button
        variant="contained"
        component="label"
        onClick={() => {
          setOpen({ isOpen: true, name: `mobileImage ${name}` });
        }}
        className={clsx(classes.imgBox)}
      >
        {imgFile["mobileImage"]?.imagePreviewUrl ? (
          <Box className={clsx(classes.imgExistBox)}>
            <img
              className={classes.imgExist}
              src={imgFile["mobileImage"]?.imagePreviewUrl}
              alt={""}
            />
          </Box>
        ) : (
          <Box className={classes.button}>
            <AddIcon />
            <Typography>Add Image</Typography>
          </Box>
        )}
        <Box>
          {imgFile["mobileImage"]?.imagePreviewUrl && (
            <Box id="editBox" className={clsx(classes.editBox)}>
              <EditIcon />
              <Typography style={{ color: "white" }} variant="h5">
                Edit
              </Typography>
            </Box>
          )}
        </Box>
      </Button>
      <CropperImage
        open={open}
        setOpen={setOpen}
        src={imgFile["mobileImage"]?.imagePreviewUrl || {}}
        aspectRatio={1}
        handleSaveImage={handleSaveImage}
      />
    </Grid>
  );
};

export default MobileImage;
