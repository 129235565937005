import { PromotionTierTypes } from "views/Products/Promotions/types";

export const TYPES = {
  ADD_MORE_TIER: "ADD_MORE_TIER",
  UPDATE_ONE_TIER: "UPDATE_ONE_TIER",
  RESET_TIER: "RESET_TIER",
  UPDATE_CHECKOUT_CONDITION: "UPDATE_CHECKOUT_CONDITION",
  REMOVE_TIER: "REMOVE_TIER",
} as const;

export interface Tier {
  id: string;
  min_quantity?: number | null;
  discount_value?: number | null;
  discount_type?: PromotionTierTypes;
}
export interface CheckoutCondition {
  min_purchase_quantity?: number | null;
  min_purchase_amount?: number | null;
}

export type ModelsAddMoreTierProps = {
  key: "tiers";
  value: Tier;
};
export type ModelsAddCheckoutConditionProps = {
  key: "checkout_condition";
  value: CheckoutCondition;
};

export type ModelsUpdateOneTierProps = {
  key: "tiers";
  value: {
    id: string;
    key: "min_quantity" | "discount_value";
    value: number | null;
  };
};
export type ModelsResetTierProps = {
  key: "tiers";
};

export function addMoreTier(props: ModelsAddMoreTierProps) {
  return {
    type: TYPES.ADD_MORE_TIER,
    props,
  };
}

export function updateOneTier(props: ModelsUpdateOneTierProps) {
  return {
    type: TYPES.UPDATE_ONE_TIER,
    props,
  };
}
export function resetTier(props: ModelsResetTierProps) {
  return {
    type: TYPES.RESET_TIER,
    props,
  };
}

export function updateCheckoutCondition(props: ModelsAddCheckoutConditionProps) {
  return {
    type: TYPES.UPDATE_CHECKOUT_CONDITION,
    props,
  };
}

export function removeOneTier(props: ModelsAddMoreTierProps) {
  return {
    type: TYPES.REMOVE_TIER,
    props,
  };
}
