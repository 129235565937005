import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useFilter } from "utils/filter";

export const useGetBanner = () => {
  const [filter] = useFilter("banner");
  const api = useApi();
  const getBanner = async () => {
    const res = await api
      .path("asset/banner", {},{...filter})
      .get();
    return res?.data
  };

  const { data, isLoading, error, mutate } = useMutation(() => getBanner());

  return {
    getBannerData: data,
    getBannerError: error,
    getBannerLoading: isLoading,
    getBannerMutate: mutate,
  };
};
