import { ReactComponent as CalendarIcon } from "assets/images/icons/Calendar.svg";
import { ReactComponent as OrderStatusIcon } from "assets/images/icons/Order_status_icon.svg";
import { ReactComponent as FufillmentSummaryIcon } from "assets/images/icons/Fufillment_summary_icon.svg";
import { ReactComponent as OrderItemsIcon } from "assets/images/icons/Order_items_icon.svg";
import { ReactComponent as ExchangeIcon } from "assets/images/icons/exchange_icon.svg";
import { ReactComponent as DownloadIcon } from "assets/images/icons/Download_icon.svg";
import { ReactComponent as RefundIcon } from "assets/images/icons/Refund_icon.svg";
import { ReactComponent as TotalIncomeIcon } from "assets/images/icons/Total_income_icon.svg";
import { ReactComponent as ReturnRequestedIcon } from "assets/images/icons/Return_requested_icon.svg";
import { ReactComponent as RefundedValueIcon } from "assets/images/icons/refunded_value_icon.svg";
import { ReactComponent as FileDownloadIcon } from "assets/images/icons/file_download_icon.svg";
import { ReactComponent as FileUploadIcon } from "assets/images/icons/file_upload_icon.svg";
import { ReactComponent as FileCompleteIcon } from "assets/images/icons/file_complete_icon.svg";
import { ReactComponent as SearchIcon } from "assets/images/icons/search_icon.svg";
import { ReactComponent as SuccesfullyRefundedIcon } from "assets/images/icons/Succesfully_refunded_icon.svg";
import { ReactComponent as AdditionalShippingFeeIcon } from "assets/images/icons/Additional_shipping_fee_icon.svg";
import { ReactComponent as ExchangeIconPopUp } from "assets/images/icons/Exchange_icon_pop_up.svg";
import { ReactComponent as OrderItemsIconBlue } from "assets/images/icons/Order_items_icon_blue.svg";
import { ReactComponent as OldTag } from "assets/images/icons/Old_tag.svg";
import { ReactComponent as NewTag } from "assets/images/icons/New_tag.svg";
import { ReactComponent as ExchangeItemIcon } from "assets/images/icons/Exchange_item_icon.svg";
import { ReactComponent as SuccessfullyExchangedIllustration } from "assets/images/icons/Successfully_exchanged_illustration.svg";
import { ReactComponent as NoteIcon } from "assets/images/icons/Note_icon.svg";
import { ReactComponent as AddIcon } from "assets/images/icons/Add_icon.svg";
import { ReactComponent as EditIcon } from "assets/images/icons/Edit_icon.svg";
import { ReactComponent as CharacterIcon } from "assets/images/icons/Character_icon.svg";
import { ReactComponent as CategoryIcon } from "assets/images/icons/Category_icon.svg";
import { ReactComponent as ProductTypeIcon } from "assets/images/icons/Product_type_icon.svg";
import { ReactComponent as CreateIcon } from "assets/images/icons/create_icon.svg";
import { ReactComponent as ClassifiactionTabIcon } from "assets/images/icons/Classifiaction_tab_icon.svg";
import { ReactComponent as CopyLinkIcon } from "assets/images/icons/Copy_link_icon.svg";
import { ReactComponent as QAPendingIcon } from "assets/images/icons/Q&A_pending_icon.svg";
import { ReactComponent as QATotalIcon } from "assets/images/icons/Q&A_total_icon.svg";
import { ReactComponent as ReviewPending } from "assets/images/icons/Review_pending_icon.svg";
import { ReactComponent as ReviewPublishedIcon } from "assets/images/icons/Review_published_icon.svg";
import { ReactComponent as QAPublishedIcon } from "assets/images/icons/Q&A_published_icon.svg";
import { ReactComponent as ReviewTotalIcon } from "assets/images/icons/Review_total_icon.svg";
import { ReactComponent as PlusIcon } from "assets/images/icons/Plus_icon.svg";
import { ReactComponent as PlusIconBtn } from "assets/images/icons/Plus_icon_btn.svg";
import { ReactComponent as EmptyState } from "assets/images/not-found/Tags_empty_state_illustration.svg";
import { ReactComponent as CancelICon } from "assets/images/icons/cancel.svg";
import { ReactComponent as DeleteIcon } from "assets/images/icons/Delete_icon.svg";
import { ReactComponent as BinIcon } from "assets/images/icons/Bin.svg";
import { ReactComponent as FilterIcon } from "assets/images/icons/filter_icon.svg";
import { ReactComponent as ArrowDownIcon } from "assets/images/icons/down-arrow-svgrepo-com.svg";
import { ReactComponent as ArrowUpIcon } from "assets/images/icons/up-arrow-svgrepo-com.svg";

import React from "react";
export const srcIcon = {
  calendar: <CalendarIcon />,
  orderstatus: <OrderStatusIcon />,
  fufillmentsummary: <FufillmentSummaryIcon />,
  orderitems: <OrderItemsIcon />,
  exchange: <ExchangeIcon />,
  download: <DownloadIcon />,
  refund: <RefundIcon />,
  totalincome: <TotalIncomeIcon />,
  returnrequested: <ReturnRequestedIcon />,
  refundedvalue: <RefundedValueIcon />,
  filedownload: <FileDownloadIcon />,
  fileupload: <FileUploadIcon />,
  filecomplete: <FileCompleteIcon />,
  search: <SearchIcon />,
  succesfullyrefunded: <SuccesfullyRefundedIcon />,
  additionalshippingfee: <AdditionalShippingFeeIcon />,
  exchangeiconpopup: <ExchangeIconPopUp />,
  orderitemsiconblue: <OrderItemsIconBlue />,
  oldtag: <OldTag />,
  newtag: <NewTag />,
  exchangeitem: <ExchangeItemIcon />,
  successfullyexchanged: <SuccessfullyExchangedIllustration />,
  noteicon: <NoteIcon />,
  addicon: <AddIcon />,
  editicon: <EditIcon />,
  character: <CharacterIcon />,
  category: <CategoryIcon />,
  product_type: <ProductTypeIcon />,
  create: <CreateIcon />,
  classifiactiontab: <ClassifiactionTabIcon />,
  copylink: <CopyLinkIcon />,
  qapending: <QAPendingIcon />,
  qapublished: <QAPublishedIcon />,
  qatotal: <QATotalIcon />,
  reviewpending: <ReviewPending />,
  reviewpublished: <ReviewPublishedIcon />,
  reviewtotal: <ReviewTotalIcon />,
  plus: <PlusIcon />,
  plusBtn: <PlusIconBtn />,
  emptystate: <EmptyState />,
  cancel: <CancelICon />,
  delete: <DeleteIcon />,
  bin: <BinIcon />,
  filter: <FilterIcon />,
  arrowDown: <ArrowDownIcon />,
  arrowUp: <ArrowUpIcon />,
};
