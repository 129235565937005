import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeStyles,
  TableCell,
  Typography,
  Box,
  TableRow,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { ReadMore } from "components";
import ListingCheckbox from "components/Listing/components/ListingBody/ListingCheckbox";
import clsx from "clsx";
import dateFormat from "utils/formatting/dateFormat";
import { Actions } from "store";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSnackbar } from "notistack";
import useApi from "utils/api/useApi";
import { useAsyncTask } from "utils/tools";

const ItemList = (props) => {
  const { model, onClick } = props;
  const checkedList = useSelector((state) => state.Models.qa.checkedList);
  const checked = checkedList.includes(model.id);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();
  const [runCreate, load, error, setError] = useAsyncTask(// eslint-disable-line
    "classification-edit"
  );
  const classes = useStyles();

  const setNameCustomer = useCallback(() => {
    if (model?.account) {
      let firstName = model?.account?.firstname
        ? model?.account?.firstname
        : "-";
      let lastName = model?.account?.lastname ? model?.account?.lastname : "";
      firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
      return firstName + " " + lastName;
    } else {
      return model?.first_name || "-"
    }
  }, [model?.account?.firstname, model?.account?.lastname]); // eslint-disable-line

  const handleClick = () => {
    let list = [...checkedList];
    if (checked) {
      list = list.filter((value) => value !== model.id);
    } else {
      list.push(model.id);
    }
    dispatch(
      Actions.Models.update({ key: "qa", value: { checkedList: list } })
    );
  };

  const handleUpdateStatus = useCallback(
    (model, status) => {
      runCreate(async () => {
        const body = {
          id: model.id,
          status: status ? "published" : "unpublished",
        };
        try {
          const res = await api.path("question_answer/update").post({ body });
          if (res?.data?.data) {
            enqueueSnackbar(`Q&A Successfully Updated`, { variant: "success" });
            dispatch(Actions.Filter.reload({ key: "qa" }));
          } else {
            throw new Error(res?.data?.message);
          }
        } catch (error) {
          enqueueSnackbar(error.message || error, {
            variant: "error",
          });
        }
      });
    },
    [model] //eslint-disable-line
  );

  return (
    <React.Fragment>
      <TableRow className={classes.row}>
        <ListingCheckbox checked={checked} onClick={handleClick} />
        <TableCell className={classes.productInfoBox} onClick={onClick}>
          <Box>
            <Typography className={classes.productCode}>
              {model?.product?.reference}
            </Typography>
            <Typography className={classes.productName}>
              {model?.product?.name}
            </Typography>
            <Typography className={classes.productName}>
              {model?.product?.material}
            </Typography>
          </Box>
        </TableCell>
        <TableCell className={classes.reviewerBox} onClick={onClick}>
          <Typography className={classes.reviewer}>
            {setNameCustomer() || "-"}
          </Typography>
          <Typography className={classes.reviewer}>
            {model?.account?.email_address || model?.email}
          </Typography>
          <Typography className={classes.dateBox}>
            {dateFormat(model?.created_at)}
          </Typography>
        </TableCell>
        <TableCell className={classes.reviewBox}>
          <ReadMore className={classes.comment} text={model?.question || ""} />
        </TableCell>
        <TableCell>
          <FormControl
            className={
              model?.status === "published"
                ? clsx(classes.formControl, classes.publishedColor)
                : clsx(classes.formControl, classes.unpublishedColor)
            }
          >
            <Select
              value={model?.status === "published" ? 1 : 0}
              className={classes.select}
              IconComponent={(e) => <ExpandMoreIcon className={e.className} />}
              onChange={(e) => handleUpdateStatus(model, e.target.value)}
            >
              <MenuItem className={classes.menuItem} value={1}>
                Published
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                value={0}
                disabled={!model?.answer}
              >
                {model?.answer ? "Unpublished" : "Unanswered"}
              </MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  row: {},
  productCode: {
    fontSize: "14px",
    fontWeight: 700,
  },
  productName: {
    textTransform: "capitalize",
    fontSize: "12px",
    fontWeight: 400,
    color: "#6F727D",
  },
  reviewBox: {
    width: "25%",
  },
  reviewerBox: {
    cursor: "pointer",
    width: "20%",
  },
  contentReviewBox: {},
  productInfoBox: {
    cursor: "pointer",
    width: "18%",
  },
  statusBox: {
    padding: "5px 14px",
    borderRadius: "5px",
    width: 150,
    textAlign: "left",
    color: "white",
    fontWeight: 700,
  },
  reviewer: {
    fontSize: "16px",
    fontWeight: 500,
  },
  dateBox: {
    color: "#0081FF",
    fontSize: "12px",
    fontWeight: 500,
    background: "#F0F2F4",
    borderRadius: "2px",
    textAlign: "center",
  },
  comment: {
    marginTop: theme.spacing(1),
    paddingLeft: "3px",
    fontWeight: "500",
    fontSize: "12px",
  },
  formControl: {
    borderRadius: 5,
  },
  publishedColor: {
    backgroundColor: "#4B6C48",
  },
  unpublishedColor: {
    backgroundColor: "#FBC75A",
  },
  select: {
    "&::before": {
      content: "none",
    },
    "&::after": {
      content: "none",
    },
    "& div": {
      padding: "8px 32px 8px 16px!important",
      color: "#FFFFFF",
    },
    "& .MuiSelect-icon": {
      color: "#FFFFFF",
    },
  },
}));
export default ItemList;
