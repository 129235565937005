import {
  Box,
  Button,
  Fade,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import { IconComponent } from "components";
import React from "react";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    boxShadow: " 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(5, 8),
  },
  IconComponent: {
    margin: theme.spacing(2, 0),
  },
  button: {
    textTransform: "none",
    minWidth: 192,
    backgroundColor: "#0D5AE5",
    color: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "#0D5AE5",

      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
}));

const SuccessResponse = (props) => {
  const { open, titleBox, content, handleClose, iconName } = props;
  const classes = useStyles();
  return (
    <Modal
      open={open}
      className={classes.modal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box className={classes.root}>
          <Typography variant="h4">{titleBox}</Typography>
          <Typography variant="h6">{content}</Typography>
          <IconComponent className={classes.IconComponent} name={iconName} />
          <Button className={classes.button} onClick={handleClose}>
            Done
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SuccessResponse;
