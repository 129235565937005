import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "white",
    padding: 30,
    "& .slick-next, & .slick-prev": {
      width: "max-content",
      "&::before": {
        display: "none",
      },
    },
    "& .slick-prev": {
      left: "-30px",
    },
    "& .slick-next": {
      right: "-30px",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#333333",
  },
  inputsSection: {
    marginTop: 24,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteButton: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "14px",
    textAlign: "center",
    textDecorationLine: "underline",
    color: "#333333",
    textTransform: "none",
    alignSelf: "end",
  },
  imageWrapper: {
    position: "relative",
    height: 227,
    display: "flex !important",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0",
    },
  },
  slider: {
    maxWidth: 370,
    width: "100%",
    margin: "0 auto",
    padding: "24px 0",
  },
  noItem: {
    height: 227,
    padding: "24px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#C7C7C7",
  },
  image: {
    height: "100%",
    width: "auto",
    maxWidth: "370px",
    objectFit: "contain",
  },
  addSVGOverlay: {
    padding: "11px 12.5px",
    color: "#000",
    backgroundColor: "#fff",
    textTransform: "none",
    border: "2px solid #333333",
    borderRadius: "4px",
    "& .MuiButton-label": {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "14px",
      textAlign: "center",
      color: "#333333",
    },
  },
  wrapOverlay: {
    width: "100%",
    textAlign: "center",
  },
  reactPlayer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& video": {
      maxHeight: "227px !important",
    },
  },
}));
