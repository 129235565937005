/* eslint-disable react-hooks/exhaustive-deps */
import { CardMedia, colors, Typography, CardActions, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import LoadingIndicator from "components/LoadingIndicator";
import PropTypes from "prop-types";
import React, { Fragment, useCallback, useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { CropperImage, RenderGuard } from "components";
import palette from "theme/palette";
import { ReactComponent as Shape } from "assets/images/icons/Image_icon.svg";

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    padding: theme.spacing(6),
    outline: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  error: {
    flex: 1,
  },
  media: {
    height: 240,
    backgroundSize: "contain",
  },
  loaderBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionRes: {
    padding: "16px 12px",
  },
  note: {
    color: palette.text.secondary,
    marginTop: 12,
  },
  contentBox: {
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  divider: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
  },
  hr: {
    height: 1,
    width: 76,
    backgroundColor: "#757575",
  },
  button: {
    backgroundColor: "#0D5AE5",
    minWidth: 100,
    color: "white",
    boxShadow: "0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%)",
    padding: "10px 16px",
    marginTop: theme.spacing(2),
    textTransform: "none",
    border: "none",
    "&:hover": {
      backgroundColor: "#0D5AE5",
      color: "white",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
}));

const ImageDropzone = props => {
  const {
    className,
    types = [],
    error,
    setError,
    fileLoading,
    handleUpdate,
    image,
    updateLabel,
    mediaClass,
    loaderClass,
    purpose,
    cropperImage,
    aspectRatio,
    shouldClear = true,
    setHavePreview,
    handleCancelPreview,
    canUploadImage = true,
    canUploadVideo = false,
    ...rest
  } = props;
  const classes = useStyles();
  const [preview, setPreview] = useState(null);
  const [showChangeImage, setShowChangeImage] = useState(false);
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (preview && setHavePreview) {
      setHavePreview(true);
      return;
    }
  }, [preview]);
  // Common MIME types for images and videos
  const imageMimes = ["image/jpeg", "image/png", "image/webp"];
  const videoMimes = ["video/mp4", "video/webm"];

  // Function to handle accepted MIME types based on upload capabilities
  const handleAcceptExtension = (canUploadImage, canUploadVideo) => {
    // Use ternary operators to determine the appropriate MIME types
    return canUploadImage
      ? canUploadVideo
        ? [...imageMimes, ...videoMimes] // If both canUploadImage and canUploadVideo are true
        : imageMimes // If only canUploadImage is true
      : canUploadVideo
        ? videoMimes // If only canUploadVideo is true
        : []; // If neither canUploadImage nor canUploadVideo is true
  };

  // Example usage of the function with input values canUploadImage and canUploadVideo
  const mimes = handleAcceptExtension(canUploadImage, canUploadVideo);
  const [file, setFile] = useState(null);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (image) {
      setPreview(null);
      setFile(null);
    }
  }, [image]);
  const [openCropper, setOpenCropper] = useState({ isOpen: false, name: null });
  const imageUri = JSON.stringify(image?.uri)?.replace(/\\\\/g, "/").replace(/"/g, "");
  const handleDrop = useCallback(acceptedFile => {
    setFile(acceptedFile[0]);
    let reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
      setShowChangeImage(false);
    };
    if (acceptedFile[0]) {
      reader.readAsDataURL(acceptedFile[0]);
    }
    setError(null);
    setOpenCropper({ isOpen: true, name: "icon" });
  }, []);
  const handleSaveImage = useCallback((e, name, file) => {
    setFile(file);
    let reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // eslint-disable-line
    onDrop: handleDrop,
    accept: mimes.join(","),
  });
  const clear = () => {
    if (purpose !== "form") {
      setPreview(null);
    }
    setFile(null);
    if (handleCancelPreview) {
      handleCancelPreview();
    }
  };

  const addPreview = (file, isClear = false) => {
    handleUpdate(file, isClear);
    setShowChangeImage(true);
    //for EditForm logic, default should be clear
    if (shouldClear) {
      clear();
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)} {...getRootProps()}>
      {!image && !file && !fileLoading && (
        <div
          className={clsx({
            [classes.dropZone]: true,
            [classes.dragActive]: isDragActive,
          })}
        >
          {/* Render your drop zone content here as before */}
          <div>
            <Shape />
          </div>
          <div className={classes.contentBox}>
            <Typography gutterBottom variant="h3">
              {`Drag & Drop ${canUploadVideo ? "file" : "image"} here`}
            </Typography>
            <div className={classes.divider}>
              <div className={classes.hr} />
              &nbsp;&nbsp;or&nbsp;&nbsp;
              <div className={classes.hr} />
            </div>
            <Button className={classes.button}>
              <input {...getInputProps()} accept={mimes} />
              Browse
            </Button>
            <div className={classes.note}>Maximum size : 5MB</div>
            <div className={classes.note}>
              Please submit only <u>&nbsp;1&nbsp;</u>
              {` ${canUploadVideo ? "file" : "image"} only.`}
            </div>
            {types && types.length > 0 && (
              <Typography className={classes.info} color="textSecondary" variant="body1">
                Only {types.reduce((acc, val) => acc + val.format + ", ", "").slice(0, -2)} filetypes accepted
              </Typography>
            )}
          </div>
        </div>
      )}
      {/* Render loading indicator */}
      <RenderGuard renderIf={fileLoading}>
        <div className={clsx(loaderClass, classes.loaderBox)}>
          <LoadingIndicator active={fileLoading} />
        </div>
      </RenderGuard>
      {/* Render preview or media */}
      {(preview || imageUri) &&
        !fileLoading &&
        (videoMimes.includes(file?.type) ? (
          <div style={{ margin: "0 auto", width: "fit-content" }}>
            <video className={classes.media} src={preview ? preview : imageUri} controls />
          </div>
        ) : (
          <CardMedia className={mediaClass || classes.media} image={preview ? preview : imageUri} />
        ))}
      {/* Render card actions */}
      <CardActions className={classes.actionRes}>
        {/* Render buttons using a single RenderGuard */}
        <RenderGuard renderIf={file && !showChangeImage}>
          <Fragment>
            <Button fullWidth className={classes.closeButton} onClick={clear}>
              Cancel
            </Button>
            <Button fullWidth className={classes.closeButton} onClick={() => addPreview(file, false)}>
              {updateLabel}
            </Button>
          </Fragment>
        </RenderGuard>
        <RenderGuard renderIf={(image && !file) || showChangeImage}>
          <Button onClick={() => addPreview(file, true)} fullWidth className={classes.closeButton}>
            <input {...getInputProps()} accept={mimes} />
            Change Image
          </Button>
        </RenderGuard>
      </CardActions>
      {/* Render CropperImage component */}
      {cropperImage && (
        <CropperImage
          open={openCropper}
          setOpen={setOpenCropper}
          src={preview || imageUri}
          aspectRatio={aspectRatio || 1}
          handleSaveImage={handleSaveImage}
          clear={() => {
            setPreview(null);
            setFile(null);
            //for EditForm logic, default should not do the logic
            if (shouldClear === false) {
              handleUpdate(null);
            }
          }}
        />
      )}
    </div>
  );
};

ImageDropzone.propTypes = {
  className: PropTypes.string,
  error: PropTypes.any,
  setError: PropTypes.any,
  handleUpdate: PropTypes.any,
  updateLabel: PropTypes.string,
  shouldClear: PropTypes.bool,
  image: PropTypes.any,
  /** Controls whether the component allows the uploading of images */
  canUploadImage: PropTypes.bool,
  /** Controls whether the component allows the upload of videos */
  canUploadVideo: PropTypes.bool,
};

ImageDropzone.defaultProps = {
  loaderClass: "",
  mediaClass: null,
  purpose: "upload",
  canUploadImage: true,
  canUploadVideo: false,
};

export default ImageDropzone;
