import React, { useState, useMemo, useCallback, useEffect } from "react";
import { SearchBar, IconComponent, ActionDialog, LoadButton } from "components";
import { DateRangePicker } from "materialui-daterange-picker";
import { useFilter } from "utils/filter";
import moment from "moment";
import {
  Button,
  makeStyles,
  Typography,
  Box,
  TextField,
} from "@material-ui/core";
import useApi from "utils/api/useApi";
import StarRateIcon from "@material-ui/icons/StarRate";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useAsyncTask } from "utils/tools";
import { useSnackbar } from "notistack";
import { Actions } from "store";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  orderCreateDate: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    padding: theme.spacing(2),
    "& > div": {
      fontSize: "medium",
    },
  },
  textField: {
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    marginLeft: theme.spacing(1),
    minWidth: 207,
  },
  dateRangePicker: {
    position: "absolute",
    right: "16px",
    top: "70px",
    "& div > ul": {
      display: "none",
    },
  },
  button: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    padding: "8px 8px 8px 8px",
    width: 152,
    height: 46,
    marginRight: theme.spacing(1),
    textTransform: "none",
    border: "1px solid " + theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#aeaafb4d",
      color: "#ffa500",
    },
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  whiteText: {
    color: "white",
  },
  ratingStarBox: {
    padding: 16,
  },
  starBox: {
    display: "flex",
    alignItems: "center",
  },
  star: {
    color: theme.palette.primary.main,
  },
  ratingBox: {
    display: "flex",
  },
  searchBar: {
    width: "450px",
  },
  searchLabel: {
    fontSize: "14px",
    fontWeight: "500",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  removeButton: {
    float: "right",
    display: "flex",
    border: "2px solid #333333",
    borderRadius: "5px",
    padding: "5px 16px",
    fontSize: "12px",
    fontWeight: "700",
    textTransform: "none",
  },
  removeButtonIcon: {
    marginRight: "10px",
  },
  noteWarning: {
    color: "red",
    fontSize: "16px",
    fontWeight: "500",
  },
  actionDialogIcon: {
    color: "#E50D0D",
  },
}));

const TopElements = (props) => {
  const classes = useStyles();
  const { models, name } = props;
  const [openDateRange, setOpenDateRange] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [filter, updateFilter] = useFilter(name);
  const dispatch = useDispatch();
  const checkedList = useSelector(
    (state) => state.Models[name]?.checkedList || []
  );
  const { enqueueSnackbar } = useSnackbar();
  const [runApi, load, error, setError] = useAsyncTask("review-batch-remove"); // eslint-disable-line
  const api = useApi(); // eslint-disable-line
  const [dateRange, setDateRange] = useState({
    startDate: moment(moment().subtract(30, "days")),
    endDate: moment().endOf("day"),
  });
  const toggleDateRange = useCallback(
    () => setOpenDateRange((val) => !val),
    [setOpenDateRange]
  );
  const dateDisplay = useMemo(() => {
    let startDate = moment(dateRange.startDate).format("DD/MM/YYYY");
    let endDate = moment(dateRange.endDate).format("DD/MM/YYYY");
    return `${startDate} - ${endDate}`;
  }, [dateRange.startDate, dateRange.endDate]);

  useEffect(() => {
    updateFilter({
      ...filter,
      score: "all",
      offset: 0,
      created_at: `${moment(dateRange.startDate).format("X")},${moment(
        dateRange.endDate
      ).format("X")}`,
    });
    setActiveTab(0)
  }, [dateRange.startDate, dateRange.endDate]); // eslint-disable-line

  const clickOpenDateRange = useCallback(
    () => setOpenDateRange(true),
    [setOpenDateRange]
  ); // eslint-disable-line

  const handleFiterRating = useCallback(
    (e) => {
      updateFilter({
        ...filter,
        offset: 0,
        score: e === 0 ? "all" : e,
      });
      setActiveTab(e);
    },
    [filter]// eslint-disable-line
  ); 

  const showRatingStar = useCallback((e, active) => {
    let ratingCount = [];
    for (let i = 0; i < e; i++) {
      ratingCount.push(
        <Box key={i} className={classes.starBox}>
          <StarRateIcon
            className={clsx(active ? classes.whiteText : classes.star)}
          />
        </Box>
      );
    }
    return ratingCount;
  }, []); // eslint-disable-line
  const showRatingButton = useCallback(
    (activeTab) => {
      let content = [0, 5, 4, 3, 2, 1].map((e, index) => {
        return (
          <Button
            key={index}
            onClick={() => handleFiterRating(e)}
            className={clsx(
              classes.button,
              activeTab === e && classes.activeButton
            )}
          >
            <Box className={classes.ratingBox}>
              {e !== 0 && showRatingStar(e, activeTab === e)}
            </Box>
            <Typography className={clsx(activeTab === e && classes.whiteText)}>
              {e === 0
                ? "All"
                : `(${
                    models?.filter((review) => review?.score === e)?.length || 0
                  })`}
            </Typography>
          </Button>
        );
      });
      return content;
    },
    [showRatingStar, models, filter] // eslint-disable-line
  );

  const handleDelete = useCallback(
    (model) => {
      runApi(async () => {
        const body = {
          ids: checkedList,
        };
        try {
          const res = await api.path("review/delete").del({ body });
          if (res.data.status === 0) {
            updateFilter({ ...filter, reload: !filter.reload });
            dispatch(
              Actions.Models.update({ key: name, value: { checkedList: [] } })
            );
            setOpenDelete(false);
            enqueueSnackbar(`Removed successfully.`, {
              variant: "success",
            });
          } else {
            throw new Error(res?.data?.message);
          }
        } catch (error) {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        }
      });
    },
    [checkedList] //eslint-disable-line
  );

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <SearchBar
          name={props.name}
          placeholder="Search reviews by name or keywords..."
        />
        <Box className={classes.orderCreateDate}>
          <Box className={classes.searchLabel}>Search Date</Box>
          <TextField
            className={classes.textField}
            variant="outlined"
            type="text"
            placeholder="DD/MM/YYYY - DD/MM/YYYY"
            onFocus={clickOpenDateRange}
            value={dateDisplay}
          />
          <Box className={classes.dateRangePicker}>
            <DateRangePicker
              open={openDateRange}
              toggle={toggleDateRange}
              initialDateRange={dateRange}
              onChange={(range) => {
                setDateRange({
                  startDate: range.startDate,
                  endDate: moment(range.endDate).endOf("day"),
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.ratingStarBox}>{showRatingButton(activeTab)}</Box>
      <Box className={classes.actionContainer}>
        <LoadButton
          disabled={checkedList?.length <= 0 ? true : false}
          load={load}
          className={classes.removeButton}
          onClick={() => setOpenDelete(true)}
        >
          <IconComponent name="bin" className={classes.removeButtonIcon} />
          Remove from list
        </LoadButton>
      </Box>
      <ActionDialog
        overwriteIcon={
          <IconComponent name="delete" className={classes.actionDialogIcon} />
        }
        overwriteMessage={"Are you sure you want to delete the reviews?"}
        overwriteNameButton={"Delete"}
        cancelBtnText={"Cancel"}
        handleUpdate={handleDelete}
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
      />
    </React.Fragment>
  );
};

export default TopElements;
