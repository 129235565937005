import React, { useCallback, useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { validate } from "validate.js";
import { validates } from "./replyPanelConfig";
import { useAsyncTask } from "utils/tools";
import { useSnackbar } from "notistack";
import useApi from "utils/api/useApi";
import { useFilter } from "utils/filter";
import { LoadButton } from "components";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    padding: 8,
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "8px",
    width: "70vh",
    padding: "16px 8px 24px",
  },
  textField: {
    margin: 8,
    display: "flex",
  },
  maxLengthBox: {
    position: "absolute",
    bottom: 16,
    right: 16,
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    color: "black",
    boxShadow:
      "0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%)",
    padding: "8px 8px 8px 8px",
    width: 100,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: "none",
    border: "none",
    "&:hover": {
      color: "#ffa500",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  redButton: {
    backgroundColor: "#e53935cc",
    "&:hover": {
      backgroundColor: "#e53935",
    },
  },
  greenButton: {
    backgroundColor: "#00ff2687",
    "&:hover": {
      backgroundColor: "#00ff26",
    },
  },
}));
const ReplyPanel = (props) => {
  const classes = useStyles();
  const { open, setOpen, panelName, model } = props;
  const [validateText, setValidateText] = useState({});
  const [inputValue, setInputValue] = useState(null);
  const api = useApi();
  const [filter, updateFilter] = useFilter("productTypeReviews");
  const [runApi, load, error, setError] = useAsyncTask("classification-edit"); // eslint-disable-line
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    return () => {
      setInputValue(model?.reply);
      setValidateText({});
    };
  }, [open, model]);

  useEffect(() => {
    const listener = (event) => {
      if ((event.code === "Enter" || event.code === "NumpadEnter") && !event.shiftKey) {
        handleSubmit(event);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [validateText, inputValue]); // eslint-disable-line

  const handleSubmit = useCallback(
    () => {
      runApi(async () => {
        const body = {
          reply: inputValue,
        };
        const error = validate(
          { title: inputValue },
          { title: validates.title }
        );
        if (error) {
          setValidateText(error);
          enqueueSnackbar(`You need to fill in all required fields!`, {
            variant: "error",
          });
        } else {
          await api
            .path("review/update", { review_id: model?.id })
            .put({ body });
          updateFilter({ ...filter, reload: !filter.reload });
          enqueueSnackbar(`The review is updated successfully!`, {
            variant: "success",
          });
          setOpen(false);
        }
      });
    },
    [validateText, model, filter] // eslint-disable-line
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.paper}>
            <Box className={classes.titleBox}>
              <Typography className={classes.title} variant="h4">
                {panelName}
              </Typography>
              <Button onClick={handleClose}>
                <CloseIcon />
              </Button>
            </Box>
            <TextField
              className={classes.textField}
              InputProps={{
                maxLength: 10,
                endAdornment: (
                  <Box className={classes.maxLengthBox}>
                    <Typography>
                      {inputValue?.length ? inputValue?.length : 0}
                      /500 characters
                    </Typography>
                  </Box>
                ),
              }}
              value={inputValue || ""}
              multiline
              onChange={(e) => {
                const value = inputValue?.length ? inputValue.length : 0;
                const error = validate(
                  { title: e.currentTarget.value },
                  { title: validates.title }
                );
                setValidateText({
                  title: error?.title[0] || null,
                });
                value <= 500 && setInputValue(e.currentTarget.value);
              }}
              onKeyDown={(e) => {
                if ((e.key === "Enter" || e.key === "NumpadEnter" ) && !e.shiftKey) {
                  // prevent default behavior
                  e.preventDefault();
                }
              }}
              onBlur={(e) => {
                const error = validate(
                  { title: e.currentTarget.value },
                  { title: validates.title }
                );
                setValidateText({
                  title: error?.title[0] || null,
                });
              }}
              error={validateText?.title ? true : false}
              helperText={validateText?.title}
              variant="outlined"
              type={"textarea"}
              InputLabelProps={{ shrink: false }}
            />
            <Box className={classes.buttonBox}>
              <Button
                className={clsx(classes.button, classes.redButton)}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <LoadButton
                variant="contained"
                padding={4}
                load={load}
                size="large"
                disabled={load}
                className={clsx(classes.button, classes.greenButton)}
                onClick={handleSubmit}
                color="secondary"
              >
                Submit
              </LoadButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};
export default ReplyPanel;
