import { ProductStatus } from "utils/constants";

export const formStructure = {
  
  question: {
    initialValue: "",
    placeholder: "Question",
    type: "text",
    validate: {
      presence: { allowEmpty: false, message: "is required" },
    },
  },
  answer: {
    initialValue: "",
    placeholder: "Answer",
    type: "text",
    validate: {
      presence: { allowEmpty: true },
    },
  },
  status: {
    initialValue: "",
    placeholder: "Status",
    label: "Status",
    type: "select",
    validate: {
      presence: { allowEmpty: false, message: "is required" },
      inclusion: Object.values(ProductStatus),
    },
    options: ProductStatus,
  },
};

export const messages = {
  "unauthorised:authorisation required":
    "You need a valid token to carry out this action. Please sign out and sign in again.",
  "unauthorised:token expired":
    "Your token has expired. Please sign out and sign in again.",
  "file not image":
    "Oops, it seems your file is not an image. Please submit an image.",
};

export const successMsg = {
  "form success": "Product details submitted!",
};
