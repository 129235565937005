import {Box, makeStyles, MenuItem, Select} from '@material-ui/core';
import clsx from 'clsx';
import {ImageDropzone, InputGroup} from 'components';
import React from 'react';
import {DropzoneStyles} from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  editContent: {
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    alignItems: 'flex-start',
  },
  ...DropzoneStyles,
  dropzonebox: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  back: {
    backgroundColor: '#FFF',
  },
  line: {
    width: 1,
    height: 325,
    background: '#C9D1D9',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  textField: {
    '& legend': {
      display: 'none',
    },
    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      transform: 'translate(14px, 5px) scale(0.75)',
      fontWeight: 500,
      lineHeight: '14px',
      color: '#A5A9B9',
    },

    '& textarea.MuiInputBase-input ': {
      height: '40px !important',
    },
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 8,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& > div:last-child': {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 16,
      alignItems: 'flex-end',
      gap: '12px',
      whiteSpace: 'nowrap',
      '& .text': {
        color: '#0D5AE5',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        gap: '12px',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '14px',
        cursor: 'pointer',
      },
    },
  },
  selectViewBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  selectView: {
    display: 'flex',
    justifyContent: 'flex-end',
    border: '2px solid #0D5AE5',
    borderRadius: '5px',
    padding: '0 7px',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#0D5AE5',
    width: 140,
    // [theme.breakpoints.down('md')]: {
    //   width: '100%',
    // },
    '& .MuiSelect-select.MuiSelect-select': {
      width: '100%',
    },
    '& > .MuiSelect-root': {
      paddingTop: 6,
      paddingBottom: 6,
    },
    marginBottom: 12,
  },
  box: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const EditForm = ({
  files,
  handleUpdateImage,
  error,
  setError,
  handleCopyLink,
  fields,
  image,
  viewSize,
  setViewSize,
  setFiles,
  setHavePreview,
  handleCancelPreview,
  getBanner,
  disabledBanner1
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.editContent}>
      <Box
        width='44%'
        display='flex'
        flexDirection='column'
        className={classes.box}>
        {getBanner !== "age_group" &&
          <Box className={classes.selectViewBox}>
            <Select
              disableUnderline
              value={viewSize || 'Desktop'}
              IconComponent={() => (
                <img alt='' src='/images/arrow_down_blue.svg' />
              )}
              autoWidth={false}
              onChange={(e) => {
                setViewSize(e.target.value);
              }}
              className={classes.selectView}>
              <MenuItem disabled={disabledBanner1?.selectDesktop ?? false} value='desktop'>Desktop</MenuItem>
              <MenuItem disabled={disabledBanner1?.selectMobile ?? false} value='mobile'>Mobile</MenuItem>
            </Select>
          </Box>
        }
        <ImageDropzone
          className={clsx(classes.dropzone, classes.back, classes.dropzonebox)}
          files={files}
          handleUpdate={handleUpdateImage}
          error={error}
          setError={setError}
          updateLabel='Confirm'
          shouldClear={false}
          image={image}
          setHavePreview={setHavePreview}
          handleCancelPreview={handleCancelPreview}
        />
      </Box>

      <Box className={classes.line}></Box>
      <Box className={classes.inputGroup}>
        <InputGroup className={classes.textField} fields={getBanner === "home_banner_1" ? fields.slice(0, 4) : fields.slice(0, 3)} />
        <Box>
          <InputGroup className={classes.textField} fields={getBanner === "home_banner_1" ? fields.slice(4) : fields.slice(3)} />
          <Box className='text' onClick={() => handleCopyLink()}>
            <img alt='' src='/images/copy_link_icon.svg' />
            Copy Link
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default EditForm;
