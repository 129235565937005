/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
// import { OrderList } from "components";
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import { ApiLayout, DashboardLayout, ErrorLayout, LoginLayout } from "layouts";
import { Paths } from "utils/constants";
import LandingPageCarousel from "views/Frontend/HomePage/components/LandingPageCarousel";
import ItemCarousel from "views/Frontend/HomePage/components/ItemCarousel";
import { ListingPage } from "views/Frontend/ListingPage";
import { Banner1 } from "views/Frontend/HomePage/components";

const routes = [
  {
    path: Paths.Landing,
    exact: true,
    component: () => <Redirect to={Paths.Accounts.List} />,
  },
  {
    path: Paths.Auth.Root,
    component: LoginLayout,
    routes: [
      {
        path: Paths.Auth.Login,
        exact: true,
        component: lazy(() => import("views/Authentication/Login")),
      },
      {
        path: Paths.Auth.Password.Request,
        exact: true,
        component: lazy(() => import("views/Authentication/PasswordRequest")),
      },
      {
        path: Paths.Auth.Password.Reset,
        exact: true,
        component: lazy(() => import("views/Authentication/PasswordReset")),
      },
      {
        component: () => <Redirect to={Paths.Auth.Login} />,
      },
    ],
  },
  {
    path: Paths.Error.Root,
    component: ErrorLayout,
    routes: [
      {
        path: Paths.Error["401"],
        exact: true,
        component: lazy(() => import("views/Errors/Error401")),
      },
      {
        path: Paths.Error["404"],
        exact: true,
        component: lazy(() => import("views/Errors/Error404")),
      },
      {
        path: Paths.Error["500"],
        exact: true,
        component: lazy(() => import("views/Errors/Error500")),
      },
      {
        path: Paths.Error.Unauthorized,
        exact: true,
        component: lazy(() => import("views/Errors/Unauthorized")),
      },
      {
        component: () => <Redirect to={Paths.Error["404"]} />,
      },
    ],
  },
  {
    path: Paths.Api.Root,
    component: ApiLayout,
    routes: [
      {
        path: Paths.Api.Invoice,
        exact: true,
        component: lazy(() => import("views/Sales/OrderInvoice")),
      },
      {
        path: Paths.Api.NoInvoiceFound,
        exact: true,
        component: lazy(() => import("views/Errors/NoInvoiceFound")),
      },
      {
        component: () => <Redirect to={Paths.Error["404"]} />,
      },
    ],
  },
  {
    route: "*",
    component: DashboardLayout,
    routes: [
      {
        path: Paths.Accounts.List,
        exact: true,
        component: lazy(() => import("views/Accounts/CustomerManagement")),
      },
      {
        path: Paths.Accounts.Detail,
        exact: true,
        component: lazy(() => import("views/Accounts/CustomerDetail")),
      },
      {
        path: Paths.Products.CatalogueList,
        exact: true,
        component: lazy(() => import("views/Products/Catalogue")),
      },
      {
        path: Paths.Products.Detail,
        exact: true,
        component: lazy(() => import("views/Products/ProductDetail")),
      },
      {
        path: Paths.Products.BulkUpdate,
        exact: true,
        component: lazy(() => import("views/Products/BulkUpdate")),
      },
      {
        path: Paths.Products.PlatformGraceImport,
        exact: true,
        component: lazy(() => import("views/Products/B2BPlatformImport")),
      },
      {
        path: Paths.Products.Inventory,
        exact: true,
        component: lazy(() => import("views/Products/Inventory")),
      },
      {
        path: Paths.Promotions.PromotionCreationPage,
        exact: true,
        component: lazy(() => import("views/Products/Promotions/PromotionCreationPage")),
      },
      {
        path: Paths.Promotions.PromotionListPage,
        exact: true,
        component: lazy(() => import("views/Products/Promotions/PromotionListPage")),
      },
      {
        path: Paths.Promotions.NewPromotionDetailPage,
        exact: true,
        component: lazy(() => import("views/Products/Promotions/NewPromotionDetailPage")),
      },
      {
        path: Paths.VoucherSettings,
        exact: true,
        component: lazy(() => import("views/Products/VoucherSettings")),
      },
      {
        path: Paths.VoucherDetail,
        exact: true,
        component: lazy(() => import("views/Products/VoucherDetail")),
      },
      {
        path: Paths.Frontend.ProductGroupsList,
        exact: true,
        component: lazy(() => import("views/Frontend/ProductGroups")),
      },
      {
        path: Paths.Frontend.Homepage,
        exact: true,
        component: lazy(() => import("views/Frontend/HomePage")),
      },
      {
        path: Paths.Frontend.LandingPage,
        exact: true,
        component: lazy(() => import("views/Frontend/HomePage/components/LandingPage")),
      },
      {
        path: Paths.Frontend.LandingPageDetail,
        exact: true,
        component: () => <Banner1 />,
      },
      {
        path: Paths.Frontend.AgeGroup,
        exact: true,
        component: () => <LandingPageCarousel title="Age Group" getBanner="age_group" />,
      },
      {
        path: Paths.Frontend.ItemCrousel1,
        exact: true,
        component: () => <ItemCarousel title="Carousel 1" getBanner="item_carousel_1" />,
      },
      {
        path: Paths.Frontend.ItemCrousel2,
        exact: true,
        component: () => <ItemCarousel title="Carousel 2" getBanner="item_carousel_2" />,
      },
      {
        path: Paths.Frontend.Navigation,
        exact: true,
        component: lazy(() => import("views/Frontend/Navigation")),
      },
      {
        path: Paths.Frontend.Classifications,
        exact: true,
        component: lazy(() => import("views/Frontend/Classifications")),
      },
      {
        path: Paths.Frontend.PagesList,
        exact: true,
        component: lazy(() => import("views/Frontend/CustomPages")),
      },
      {
        path: Paths.Frontend.PageDetail,
        exact: true,
        component: lazy(() => import("views/Frontend/CustomPageDetail")),
      },
      {
        path: Paths.Frontend.Element,
        exact: true,
        component: lazy(() => import("views/Frontend/CustomPageDetail/components/WebpageElementDetail")),
      },
      {
        path: Paths.Frontend.BottlesList,
        exact: true,
        component: lazy(() => import("views/Frontend/BottlesPage")),
      },
      {
        path: Paths.Sales.OrderDetail,
        exact: true,
        component: lazy(() => import("views/Sales/OrderDetail")),
      },
      {
        path: Paths.Sales.OrderOverview,
        exact: true,
        component: lazy(() => import("views/Sales/OrderOverview")),
      },
      {
        path: Paths.Sales.StrawReplacement,
        exact: true,
        component: lazy(() => import("views/Sales/StrawReplacement")),
      },
      {
        path: Paths.Sales.ReviewManagement,
        exact: true,
        component: lazy(() => import("views/Sales/ReviewManagement")),
      },
      {
        path: Paths.Sales.QAManagement,
        exact: true,
        component: lazy(() => import("views/Sales/QAManagement")),
      },
      {
        path: Paths.Frontend.Settings,
        exact: true,
        component: lazy(() => import("views/Frontend/Settings")),
      },
      {
        path: Paths.Frontend.LandingPageCarousel,
        exact: true,
        component: () => <LandingPageCarousel title="Landing page carousel" getBanner="home_carousel" />,
      },
      {
        path: Paths.Frontend.ListingDashboard,
        exact: true,
        component: () => <ListingPage />,
      },
      {
        path: Paths.Frontend.ListingDetail,
        exact: true,
        component: lazy(() => import("views/Frontend/ListingDetail")),
      },
      {
        path: Paths.Frontend.Dashboard,
        exact: true,
        component: lazy(() => import("views/Frontend/Dashboard")),
      },
      {
        path: Paths.Frontend.Webpages,
        exact: true,
        component: lazy(() => import("views/Frontend/Webpages")),
      },
      {
        path: Paths.Frontend.WebpagesDetail,
        exact: true,
        component: lazy(() => import("views/Frontend/WebpagesDetail")),
      },
      {
        path: Paths.Frontend.NewLandingPage,
        exact: true,
        component: lazy(() => import("views/Frontend/LandingPage")),
      },
      {
        path: Paths.Frontend.NewLandingPageDetail,
        exact: true,
        component: lazy(() => import("views/Frontend/LandingPageDetail")),
      },
      {
        path: Paths.Frontend.ModifyBanner,
        exact: true,
        component: lazy(() => import("views/Frontend/ModifyBanner")),
      },
      {
        path: Paths.Frontend.CollectionPage,
        exact: true,
        component: lazy(() => import("views/Frontend/CollectionPage")),
      },
      {
        path: Paths.Frontend.CollectionPageDetail,
        exact: true,
        component: lazy(() => import("views/Frontend/CollectionPageDetail")),
      },
      {
        path: Paths.Frontend.ItemBrandDetail,
        exact: true,
        component: lazy(() => import("views/Frontend/Classifications/components/ItemBrandDetail")),
      },
      {
        path: Paths.Frontend.ItemClassDetail,
        exact: true,
        component: lazy(() => import("views/Frontend/Classifications/components/ItemClassDetail")),
      },
      {
        path: Paths.Frontend.ItemTypeDetail,
        exact: true,
        component: lazy(() => import("views/Frontend/Classifications/components/ItemTypeDetail")),
      },
      {
        path: Paths.Frontend.ItemCategoryDetail,
        exact: true,
        component: lazy(() => import("views/Frontend/Classifications/components/ItemCategoryDetail")),
      },
      {
        path: Paths.Frontend.ItemSubClassDetail,
        exact: true,
        component: lazy(() => import("views/Frontend/Classifications/components/ItemSubClassDetail")),
      },
      {
        path: Paths.Frontend.ItemGroupDetail,
        exact: true,
        component: lazy(() => import("views/Frontend/Classifications/components/ItemGroupDetail")),
      },
    ],
  },
  {
    path: Paths.Sales.OrderInvoice,
    exact: true,
    component: lazy(() => import("views/Sales/OrderInvoice")),
  },
];

export default routes;
