import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "store";
import { RootState } from "store/reducers";

interface Props {
  reload?: () => void;
  handleClose?: () => void;
  isUpdateOverlay?: boolean;
}

export const usePatchAsset = ({ reload, handleClose, isUpdateOverlay = false }: Props) => {
  const api = useApi();
  const dispatch = useDispatch();
  const assetData = useSelector((state: RootState) => state.dataDraftReducer.Asset);
  const { enqueueSnackbar } = useSnackbar();
  const patchAsset = async (formData: FormData) => {
    try {
      const res = await api.path("patch/asset").patchformdata({ body: formData });
      if (res?.data?.status === 0) {
        enqueueSnackbar("Update asset successfully!", {
          variant: "success",
        });
        if (isUpdateOverlay) {
          reload && reload();
          handleClose && handleClose();
          return;
        }
        const newArray = [...assetData]; // make a copy of the old array to avoid mutating it directly
        const index = newArray.findIndex(element => element.id === res?.data?.data?.id); // find the index of the element match with id in data response
        if (index !== -1) {
          // if the element exists in the array
          newArray[index] = res?.data?.data; // replace the element with the new element
        }

        dispatch(Actions.DataDraftList.update({ key: "Asset", value: newArray }));
      } else {
        enqueueSnackbar(`Update asset failed! ${res?.data?.message}`, {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(`Update asset failed!`, {
        variant: "error",
      });
    }
  };

  const { data, isLoading, error, mutate } = useMutation((formData: FormData) => patchAsset(formData));

  return {
    patchAssetData: data,
    patchAssetError: error,
    patchAssetLoading: isLoading,
    patchAssetMutate: mutate,
  };
};
