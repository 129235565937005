export const TYPES = {
  UPDATE: 'BANNER_LIST_UPDATE',
};

export type BannerListKeys = "home_banner_1" | "landing_page_detail"

export type BannerListUpdateProps = {
  key: BannerListKeys;
  value: any;
};

export function update(props: BannerListUpdateProps) {
  return {
    type: TYPES.UPDATE,
    props,
  };
}
