export enum Content {
  "landing_page_carousel" = "landing_page_carousel",
  "banner_1" = "banner_1",
  shop_by_age = "shop_by_age",
  shop_by_topic = "shop_by_topic",
  shop_by_item_type = "shop_by_item_type",
  item_carousel_1 = "item_carousel_1",
  item_carousel_2 = "item_carousel_2",
  best_seller = "best_seller",
  promotion_items = "promotion_items",
  new_items = "new_items",
  vouchers = "vouchers",
}

export interface LandingPageItem {
  id?: number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  title?: string;
  description?: string;
  content?: string;
  content_type?: string;
  content_section?: string;
  content_sub_section?: string;
  listing_id?: number;
  cta_text?: string;
  cta_link?: string;
  listing?: {
    id: number;
    title?: string;
  };
}

export enum ContentType {
  "banner_carousel" = "banner_carousel",
  "icon_carousel" = "icon_carousel",
  "section_title" = "section_title",
  "4_cards" = "4_cards",
  "product_carousel_1" = "product_carousel_1",
  "voucher_carousel" = "voucher_carousel",
  "icon_carousel_2" = "icon_carousel_2",
  "product_carousel_3" = "product_carousel_3",
  "3_cards" = "3_cards",
  "product_carousel_2" = "product_carousel_2",
  "section_header" = "section_header",
}

export enum ContentSection {
  "child_topic" = "child_topic",
  "parent_topic" = "parent_topic",
  "item_type_toys" = "item_type_toys",
  "item_type_bottle" = "item_type_bottle",
  "item_type_houseware" = "item_type_houseware",
}

export enum ContentSubSection {
  "1_hero_banner" = "1_hero_banner",
  "2_explore_item_type" = "2_explore_item_type",
  "3_item_type_highlights" = "3_item_type_highlights",
  "4_browse_product" = "4_browse_product",
  "5_browse_product_supplement" = "5_browse_product_supplement",
  "6_browse_product" = "6_browse_product",
  "2_item_type_highlights" = "2_item_type_highlights",
  "3_browse_products" = "3_browse_products",
}

export enum Component {
  "hero banner" = "hero banner",
  "categories" = "categories",
  "something for everyone section" = "something for everyone section",
  "something for everyone carousel" = "something for everyone carousel",
  "popular categories for parent section" = "popular categories for parent section",
  "popular categories for parent" = "popular categories for parent",
  "great saving section" = "great saving section",
  "latest addition section" = "latest addition section",
  "save more kidztime section" = "save more kidztime section",
  "toys banner" = "toys banner",
  "toys categories" = "toys categories",
  "high light carousel" = "high light carousel",
  "browse popular sensory section" = "browse popular sensory section",
  "browse popular figurine section" = "browse popular figurine section",
  "bottles banner" = "bottles banner",
  "bottles product" = "bottles product",
  "free straw banner" = "free straw banner",
  "free straw sections" = "free straw sections",
  "dinnerware banner" = "dinnerware banner",
  "browse top section" = "browse top section",
  "browse top categories" = "browse top categories",
}

export interface ComponentValue {
  content?: Content;
  content_type?: ContentType;
  content_section?: ContentSection;
  content_sub_section?: ContentSubSection;
}

export type ComponentFilterMapping = {
  [key in Component]?: ComponentValue;
};

export const componentFilterMapping: ComponentFilterMapping = {
  "hero banner": { content: Content.landing_page_carousel, content_type: ContentType.banner_carousel },
  categories: {
    content: Content.shop_by_age,
    content_type: ContentType.icon_carousel,
  },
  "something for everyone section": {
    content: Content.shop_by_topic,
    content_type: ContentType.section_title,
    content_section: ContentSection.child_topic,
  },
  "something for everyone carousel": {
    content: Content.shop_by_topic,
    content_type: ContentType.banner_carousel,
    content_section: ContentSection.child_topic,
  },
  "popular categories for parent section": {
    content: Content.shop_by_topic,
    content_type: ContentType.section_title,
    content_section: ContentSection.parent_topic,
  },
  "popular categories for parent": {
    content: Content.shop_by_topic,
    content_type: ContentType["4_cards"],
    content_section: ContentSection.parent_topic,
  },
  "great saving section": {
    content: Content.promotion_items,
    content_type: ContentType.product_carousel_1,
  },
  "latest addition section": {
    content: Content.new_items,
    content_type: ContentType.product_carousel_1,
  },
  "save more kidztime section": {
    content: Content.vouchers,
    content_type: ContentType.voucher_carousel,
  },
  "toys banner": {
    content: Content.shop_by_item_type,
    content_type: ContentType.banner_carousel,
    content_section: ContentSection.item_type_toys,
    content_sub_section: ContentSubSection["1_hero_banner"],
  },
  "toys categories": {
    content: Content.shop_by_item_type,
    content_type: ContentType.icon_carousel_2,
    content_section: ContentSection.item_type_toys,
    content_sub_section: ContentSubSection["2_explore_item_type"],
  },
  "high light carousel": {
    content: Content.shop_by_item_type,
    content_type: ContentType.banner_carousel,
    content_section: ContentSection.item_type_toys,
    content_sub_section: ContentSubSection["3_item_type_highlights"],
  },
  "browse popular sensory section": {
    content: Content.shop_by_item_type,
    content_type: ContentType.product_carousel_1,
    content_section: ContentSection.item_type_toys,
    content_sub_section: ContentSubSection["4_browse_product"],
  },
  "browse popular figurine section": {
    content: Content.shop_by_item_type,
    content_type: ContentType.product_carousel_1,
    content_section: ContentSection.item_type_toys,
    content_sub_section: ContentSubSection["6_browse_product"],
  },
  "bottles banner": {
    content: Content.shop_by_item_type,
    content_type: ContentType.banner_carousel,
    content_section: ContentSection.item_type_bottle,
    content_sub_section: ContentSubSection["1_hero_banner"],
  },
  "bottles product": {
    content: Content.shop_by_item_type,
    content_type: ContentType["3_cards"],
    content_section: ContentSection.item_type_bottle,
    content_sub_section: ContentSubSection["2_item_type_highlights"],
  },
  "free straw banner": {
    content: Content.shop_by_item_type,
    content_type: ContentType.banner_carousel,
    content_section: ContentSection.item_type_bottle,
    content_sub_section: ContentSubSection["2_item_type_highlights"],
  },
  "free straw sections": {
    content: Content.shop_by_item_type,
    content_type: ContentType.product_carousel_2,
    content_section: ContentSection.item_type_bottle,
    content_sub_section: ContentSubSection["3_browse_products"],
  },
  "dinnerware banner": {
    content: Content.shop_by_item_type,
    content_type: ContentType.banner_carousel,
    content_section: ContentSection.item_type_houseware,
    content_sub_section: ContentSubSection["1_hero_banner"],
  },
  "browse top section": {
    content: Content.shop_by_item_type,
    content_type: ContentType.section_header,
    content_section: ContentSection.item_type_houseware,
    content_sub_section: ContentSubSection["2_item_type_highlights"],
  },
  "browse top categories": {
    content: Content.shop_by_item_type,
    content_type: ContentType["3_cards"],
    content_section: ContentSection.item_type_houseware,
    content_sub_section: ContentSubSection["2_item_type_highlights"],
  },
};
