import { useMutation } from "react-query";
import useApi from "utils/api/useApi";
import { useSnackbar } from "notistack";

enum type {
  gwp = "GWP",
  pwp = "PWP",
  bundle = "BUNDLE"
}

enum Published {
  published = "published",
  unpublished = "unpublished"
}

interface Body {
  name?: string;
  description?: string;
  campaign_type?: type;
  start_at?: string;
  end_at?: string;
  published?: Published;
}

export const useCreateCampaign = () => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const createCampaign = async (body: Body) => {
    const res = await api
      .path("campaign/create")
      .post({ body });
      if (res?.data?.status === 0) {
        enqueueSnackbar("New campaign created!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`Create new campaign faiiled! ${res?.data?.message}`, {
          variant: "error",
        });
      }
    return res?.data
  };

  const { data, isLoading, error, mutate } = useMutation((body: Body) => createCampaign(body));

  return {
    createCampaignData: data,
    createCampaignError: error,
    createCampaignLoading: isLoading,
    createCampaignMutate: mutate,
  };
};
